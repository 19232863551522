import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-public-view-delete',
  templateUrl: './order-public-view-delete.component.html',
  styleUrls: ['./order-public-view-delete.component.scss']
})
export class OrderPublicViewDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { OrderService } from '../services/order/order.service';
@Component({
  selector: 'app-cashfree-page',
  templateUrl: './cashfree-page.component.html',
  styleUrls: ['./cashfree-page.component.scss']
})
export class CashfreePageComponent implements OnInit {
  transaction_id: string;

  constructor(
    private OrderService: OrderService,
    private router: Router,
    private route: ActivatedRoute,
   
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.transaction_id = params.get('id');
  });
  if (this.transaction_id) {
      this.OrderService.getCashfreeUrl(this.transaction_id).subscribe((data) => {
          window.location.href = data.data;


      });


  }
  }

}

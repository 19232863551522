import { Component, OnInit, Input, Output, EventEmitter,ElementRef,QueryList, ViewChildren  } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from 'src/app/services/invoice/invoice.service';
import { OrderService } from 'src/app/services/order/order.service';
import { CustomerdosService } from 'src/app/services/customerdos/customerdos.service';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { cityService } from 'src/app/services/city/city.service';
import { AttendenceService } from 'src/app/services/attendence/attendence.service';
import { PlanService } from 'src/app/services/plan/plan.service';

@Component({
    selector: "app-TableModal",
    templateUrl: "./TableModal.component.html",
    styleUrls: ["./TableModal.component.scss"]
})
export class TableModalComponent implements OnInit {
    @Input() props: any;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
    public fileName: any;
    public file: any;
    public fileerrmsg: boolean;

    public title: string;
    public subTitle: string;
    public type: string;
    public data: any;
    public page: any = 1;
    public isLoadedTable: boolean = true;
    visit_number: any;
    exportFileList: any;
    notimportedorderList: any;
    bankDepositList: any;
    public checkdata: any = [];
    total_amount_collected: any;
    public bankdeposit: FormGroup;
    public speciman: FormGroup;

    public isBtnLoaded: boolean = false;
    citylistForDropdown: any;
    today=new Date();
    myData: any;
    phleboData: any;
  
    public cc_data: any = {10:"SST",20:"EDTA",30:"FLUORIDE",40:"URINE CONTAINERS",50:"NEEDLE",60:"SWAB",70:"SAMPLIFY KIT",80:"PPE",90:"OTHER"};
    datavalue: any;
    specimenlistForDropdown: any;
    planList: any;
    allDocument: any;
    allBarcode: any;
    viList: any;
    showRemakData: any;
    showDocs: any;


    constructor(
        private ngbModal: NgbActiveModal,
        private toastr: ToastrService,
        private OrderService: OrderService,        
        private invoiceService : InvoiceService,
        private DosService:CustomerdosService,
        private formBuilder: FormBuilder,
        private cityService: cityService,
        private attendenceService:AttendenceService,
        private planService:PlanService,


    ) { }

    ngOnInit(): void {
        
        this.bankdeposit = this.formBuilder.group({
            diposit_date: ['', Validators.required],
            amount: ['', [Validators.required]],
            city_id : ['', [Validators.required]],
            upload: ['No', Validators.required,],
        });
        this.speciman = this.formBuilder.group({
            name: ['', Validators.required],
            number: [''],
            visit_id: [''],
            visit_patient_id: [''],
            test_id: [''],

            
            
        });

        this.cityService.getallSpecimenandId().subscribe((data) => {
            this.specimenlistForDropdown = data.data;
        });




      
        this.DosService.getallcity().subscribe((data) => {
        this.citylistForDropdown = data.data;
        });
        this.setDialogProps(this.props);
        if(this.type == 'showSpecimen')
        {
        if(this.data.displaytype=="dosview" )
        {
            //this.data =this.data
         
       this.OrderService.getSpeciman(this.data.id,this.data.visit_id,this.data.visit_patient_id).subscribe(
        (res: any) => {
            this.data = res.data;
            this.data.displaytype="dosview";
        },
        (err: any) => {
            this.onClose();
            this.toastr.error(`${err.statusText}`);
            console.log(err);
        },
        ()=>{
            this.isLoadedTable = false;
        }
        );
      
        }
        if(this.data.displaytype=="orderview" )
        {
            this.data.displaytype="orderview"
            this.data =this.data
         
        }

      


        
    }
    


        switch (this.type) {
            case 'showRemak':
                this.showRemakData=this.data.remark;
                break;
                case 'showPhleboDocList':
                this.showDocs=this.data;
                break;
            case 'showAssignLabCC':
                this.OrderService.getAssignLab(this.data).subscribe(
                    (res: any) => {
                        this.viList = res.data;
                     
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
            case 'openVisitPhlebo':
                console.log(this.data);
                this.planService.getVisitPhleboData(this.data.phlebo_id,this.data.date).subscribe(
                    (res: any) => {
                        this.viList = res.data;
                     
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'planList':
                    this.planService.getPlanList(this.data).subscribe(
                        (res: any) => {
                            this.planList = res.data;
                         
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        },
                        ()=>{
                            this.isLoadedTable = false;
                        }
                    );
                    break;
            case 'consumubles_cunsumption_count':
                this.OrderService.getCamp(this.data.id).subscribe(
                    (res: any) => {
                        this.myData = res.data.cc_count;
                     
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'Phlebotomist':
                    this.OrderService.getCamp(this.data.id).subscribe(
                        (res: any) => {
                            this.phleboData = res.data.phlebolist;
                         
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        },
                        ()=>{
                            this.isLoadedTable = false;
                        }
                    );
             

                break;
            case 'viewBankToDeposit':
                this.OrderService.getBankDepositDataList(this.data).subscribe(
                    (res: any) => {
                        this.bankDepositList = res.data.data;
                        this.total_amount_collected = res.data.amount;
                        this.bankdeposit.get('amount').setValue( res.data.amount);

                        var i=0;
                        var checkdatanew = [];
                        res.data.data.forEach(function (element) {
                            checkdatanew.push(element.id);

                            i++;
                          });
                          this.checkdata =checkdatanew;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
            case 'downloadexportedDosfile':
                this.DosService.getexportFileList().subscribe(
                    (res: any) => {
                        this.exportFileList = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                
                 case 'notUploadedDos':
                this.DosService.getNotImportedOrderList().subscribe(
                    (res: any) => {
                        this.notimportedorderList = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'notUploadedDosPrice':
                this.DosService.getNotImportedDosPriceList().subscribe(
                    (res: any) => {
                        this.notimportedorderList = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
            case 'downloadexportedfile':

            console.log("padam-------------");
                this.OrderService.getexportFileList().subscribe(
                    (res: any) => {
                        this.exportFileList = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'downloadexportedLabfile':

                this.OrderService.getexportFileLabList().subscribe(
                    (res: any) => {
                        this.exportFileList = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'downloadexportedfileAttendance':

                    console.log("padam-------------");
                        this.attendenceService.getexportFileList().subscribe(
                            (res: any) => {
                                this.exportFileList = res.data;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            },
                            ()=>{
                                this.isLoadedTable = false;
                            }
                        );
                        break;
                case 'downloadexportedfile_paymentreconcilation':
                this.OrderService.getexportFileListReconcilation().subscribe(
                    (res: any) => {
                        this.exportFileList = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'notUploadedOrder':
                this.OrderService.getNotImportedOrderList().subscribe(
                    (res: any) => {
                        this.notimportedorderList = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'ViewNotes':
                this.OrderService.getViewNotes(this.data.id).subscribe(
                    (res: any) => {
                        this.datavalue = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
			case 'ViewOrderDoc':
                this.OrderService.getBarcodeAndDocument(this.data.id).subscribe(
                    (res: any) => {
                        this.data = res.data;
                        console.log(`res : ${JSON.stringify(res)}`)
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'upSellLog':
                    this.OrderService.getUpSellLog(this.data.id).subscribe(
                        (res: any) => {
                            this.data = res.data;
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        },
                        ()=>{
                            this.isLoadedTable = false;
                        }
                    );
                    break;
                case 'ViewOrderDocPhleboApp':
                    console.log(`Fetching DOCS`)
                this.OrderService.getBarcodeAndDocumentPhleboApp(this.data.id).subscribe(
                    (res: any) => {
                        console.log(`res : ${JSON.stringify(res)}`)
                        this.allDocument = res.data.all_doc;
                        this.allBarcode = res.data.visit_barcode;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'visitBarcodeStatus':
                this.OrderService.getVisitBarcodeStatus(this.data.id).subscribe(
                    (res: any) => {
                        this.data = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;
                case 'ViewOrderTimeline':
                this.OrderService.getOrderTimeline(this.data.id).subscribe(
                    (res: any) => {
                        this.data = res.data;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;

                // viewTemperature
                case 'viewTemperature':
                    console.log(this.data)
                this.OrderService.viewTemperature(this.data.id).subscribe(
                    (res: any) => {
                        this.data = res.data;
                        console.log(this.data)
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    },
                    ()=>{
                        this.isLoadedTable = false;
                    }
                );
                break;

                case 'rejectedID':
                    console.log(this.data)
                  
        }


    }

    setDialogProps(dialogdata: any) {
        this.title = dialogdata.title;
        this.subTitle = dialogdata.subTitle;
        this.type = dialogdata.type;
        this.data = dialogdata.data;
        this.page = dialogdata.page;
        this.visit_number = dialogdata.visit_number;
    }

    removeLab(data: any) {
        this.OrderService.removeLabFromDos(data).subscribe((res: any) => {
            if (res.success) {
                this.OrderService.getAssignLab(this.data).subscribe((data) => {
                    this.viList = data.data;
                    });
               
                this.toastr.success(`${res.message}`);
                this.onClose();
            } else {
                
                if (res.message) {
                    this.toastr.error(`${res.message}`);
                    this.onClose();
                } else {
                    this.toastr.error(`Added Successfully`);
                    this.onClose();
                }
            }
        },
        (err: any) => {
            this.toastr.error(`${err}`);
        },
        () => {
            
            this.isBtnLoaded = false;
        })
    }
    onClose() {
        this.passEntry.next(this.page);
        this.ngbModal.close();
    } 
    get bankdepositInfo() {
        return this.bankdeposit.controls;
    }
    get specimanInfo() {
        return this.speciman.controls;
    }
    onCheckChange(event) {
        var checkItem = this.checkdata;
        if (event.target.checked) {
            checkItem.push(event.target.value);
        } else {
          let i: number = 0;
          checkItem.forEach(function (element) {
            if (element == event.target.value) {
              checkItem.splice(i, 1);

              return;
            }
            i++;
          });
        }
        this.checkdata = checkItem;

        this.OrderService.getTotalAmountBankDeposit(this.checkdata).subscribe((data) => {
            this.bankdeposit.get('amount').setValue( data.data);

            console.log("amit",data);
           
        });
      }
      onCloseNext() {
    
         this.passEntry.next(this.page);
         this.ngbModal.close();
     }
     onFileSelect(event) {
        if (event.target.files.length > 0) {
            const image = event.target.files[0];
            this.fileName = image.name;
            this.file = image;
            this.fileerrmsg = false;
        }
    }
   
    create() {
        
        if (this.bankdeposit.valid) {
            this.isBtnLoaded = true;
            this.OrderService.addBankDeposit(this.bankdeposit.value, this.file,this.checkdata).subscribe((res: any) => {
                if (res.success) {
                    this.toastr.success(`${res.message}`);
                } else {
                    if (res.message) {
                        this.toastr.error(`${res.message}`);
                    } else {
                        this.toastr.error(`Added Successfully`);
                    }
                }
            },
            (err: any) => {
                this.toastr.error(`${err}`);
            },
            () => {
                this.passEntry.next(this.page);
                this.ngbModal.close();
                this.isBtnLoaded = false;
            })
        }
        
        else {
            this.bankdeposit.markAllAsTouched();
        }
    
   
}
addNotes() {
    this.isBtnLoaded=true;
    console.log();
    let id=this.data.id
    let value = (<HTMLInputElement>document.getElementById('viewNotes')).value;
    this.OrderService.addNotes(id, value).subscribe(
        (res: any) => {
          if(res.success)
          {
            this.datavalue = res.data;
            let ami = (<HTMLInputElement>document.getElementById('viewNotes')).value="";

            this.toastr.success(`${res.message}`);
            this.isBtnLoaded=false;
          }
        },
        (err: any) => {
            this.toastr.error(`${err}`);
           
            this.isLoadedTable = false;
        }
    );
}

addSpeciman(id) {
    
    
    if (this.speciman.valid) {
        this.isBtnLoaded = true;
        this.speciman.get('visit_id').setValue(this.data.visit_id);
        this.speciman.get('visit_patient_id').setValue(this.data.visit_patient_id);
        this.speciman.get('test_id').setValue(this.data.id);

        this.OrderService.savespeciman(this.speciman.value).subscribe((res: any) => {
            if (res.success) {
                this.data = res.data;
                this.data.displaytype="dosview"
                this.speciman.get('number').setValue('');

                this.toastr.success(`${res.message}`);
            } else {
                if (res.message) {
                    this.toastr.error(`${res.message}`);
                } else {
                    this.toastr.error(`Added Successfully`);
                }
            }
        },
        (err: any) => {
            this.toastr.error(`${err}`);
        },
        () => {
            //this.passEntry.next(this.page);
            //this.ngbModal.close();
            this.isBtnLoaded = false;
        })
    }
    
    else {
        this.speciman.markAllAsTouched();
    }

}
getBarcodeValue(id) {
    
    let value = (<HTMLInputElement>document.getElementById('BarcodeData' +id)).value;
    this.OrderService.addBarcodes(id, value).subscribe(
        (res: any) => {
          if(res.success)
          {
            this.toastr.success(`${res.message}`);

          }
        },
        (err: any) => {
            this.toastr.error(`${err}`);
           
            this.isLoadedTable = false;
        }
    );
}
updateSpeciman(data:any) {
    
    let visit_id=this.data.visit_id;
    let patient_id=this.data.visit_patient_id;
    let test_id=this.data.id;

    let value = (<HTMLInputElement>document.getElementById(data)).value;
    this.OrderService.updatespeciman(data, value,visit_id,patient_id,test_id).subscribe(
        (res: any) => {
          if(res.success)
          {
            this.toastr.success(`${res.message}`);

          }
        },
        (err: any) => {
            this.toastr.error(`${err}`);
           
            this.isLoadedTable = false;
        }
    );
}
}

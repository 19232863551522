import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import Selectr from "mobius1-selectr";
import { TableModalComponent } from "src/app/components/TableModal/TableModal.component";

import { cityService } from "src/app/services/city/city.service";
import { CustomerdosService } from "src/app/services/customerdos/customerdos.service";
import { OrderService } from "src/app/services/order/order.service";
import { deleteModalComponent } from "src/app/components/deleteModal/deleteModal.component";
import { AddEditDiscountComponent } from "../pages/orders/order-create/add-edit-discount/add-edit-discount.component";
import { DOCUMENT } from '@angular/common';
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
    selector: 'app-create-upsell-order',
    templateUrl: './create-upsell-order.component.html',
    styleUrls: ['./create-upsell-order.component.scss']
})
export class CreateUpsellOrderComponent implements OnInit {

    form!: FormGroup
    patientform!: FormGroup
    testform!: FormGroup
    pricingform!: FormGroup
    public isupsell = true;

    public orderId: any;
    public thisDiscountType: any;
    public thisDiscountValue: any;
    public visit_id: any;
    public minDate: Date;
    public PatientCount = 0;

    //All Dropdown
    public customerlistForDropdown = [];
    public statelistForDropdown = [];

    public citylistForDropdownByState = [];
    public labLocationlistForDropdownByCity = [];
    public slotlistForDropdown = [];
    public TestlistForDropdown = [];
    
    public testid=[];
    selectorMultiple: any;
    public oldpatient: any = '';

    optionsMultiple = {
        multiple: true,
        placeholder: 'Select Patients',
        data: []
    };

    optionsSingle = {
        multiple: true,
        placeholder: 'Select test',
        data: []
    };
    //All Loader
    public isBtnLoaded = false
    public isLoadedTable: boolean;

    //Table List
    public allPatientList = []
    public Visit1Test = []
    public Visit2Test = []
    isPatientEdit: boolean;
    PatientEditId: any;
    displayNotEditedDateTime: any;
    firstTimeOrderCreate: boolean;
    isTestBtnLoaded: boolean;
    totalTestOrderAmount: any;
    thisFinalPrice = 0;
    thisTestID: number;
    isPriceBtnLoaded: boolean;
    isEditOrder: boolean = false;
    visit_number: any;
    sha_visit_id: any;
    oldpatientDropdown: any;
    labid: any = 1;
    selectrSingle: Selectr;

    test_dropdownList = [];
    test_dropdownSettings :IDropdownSettings;
    selectedItems=[];

    constructor(
        private formBuilder: FormBuilder,
        private customerorderService: CustomerdosService,
        private OrderService: OrderService,
        private toastr: ToastrService,
        private router: Router,
        public datepipe: DatePipe,
        private cityService: cityService,
        private route: ActivatedRoute,
        private modalService: NgbModal,

    ) {
        this.minDate = new Date();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('visit_id')) {
                this.visit_id = params.get('visit_id');
                this.isEditOrder = true;
            }
            this.orderId = params.get('id');
        });
        this.test_dropdownSettings = {
         //   singleSelection: false,
            idField: 'id',
            textField: 'test_name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            allowSearchFilter: true
          };


        this.patientform = this.formBuilder.group({
            oldpatient: ['0'],
            order_id: [''],
            visit_id: [''],
            remark: [''],

            first_name: ['', Validators.required],
            last_name: [''],
            gender: ['', Validators.required],
            age: ['', [Validators.required, Validators.min(3), Validators.pattern("^[0-9]*$")]],
        });

        this.testform = this.formBuilder.group({
            order_id: [''],
            visit_id: [''],
            patient: ['', Validators.required],
            test: ['', Validators.required]
        });


        this.pricingform = this.formBuilder.group({
            payment_type: ['Postpaid', Validators.required],
            home_collection_charges: [''],
            total_adjusted_amount: ['', Validators.required],
            total_amount: ['', Validators.required],
            advance_paid: [''],
            amount_to_be_collect: ['', Validators.required],
            total_order_value: ['', Validators.required],
        });

        this.customerorderService.getallCustomer().subscribe((data) => {
            this.customerlistForDropdown = data.data;
        });
        this.patientform.get('oldpatient').setValue(0);
        var selectrmultiple: any = document.getElementById("selectr-multiple-citys");
        this.selectorMultiple = new Selectr(selectrmultiple, this.optionsMultiple);
       
        if (this.orderId) {
            this.citylistForDropdownByState = [];
            this.statelistForDropdown = [];
            this.OrderService.getPatientbyVisitId(this.visit_id).subscribe((data) => {
                this.oldpatientDropdown = data.data;
            });

            this.OrderService.getSingleOrderData_by_visit_id(this.orderId, this.visit_id).subscribe((data) => {
                setTimeout(() => {
                    this.refreshPatients();
                }, 500);

                this.displayNotEditedDateTime = data.data.date;
                this.visit_number = data.data.visit_number;
                this.sha_visit_id = data.data.sha_visit_id;
                this.getTest(data.data.customer_id, data.data.provider_id, data.data.patient_city, data.data.lab_location_id);

                if (data.data.date) {
                    data.data.date = this.datepipe.transform(data.data.date, 'd-MM-YYYY')
                } else {
                    data.data.date = ''
                }


                console.log('edit', data.data);


                this.pricingform.patchValue(data.data);
                this.totalTestOrderAmount = data.data.total_amount;

                if (this.visit_number == 'second') {
                    this.pricingform.controls['advance_paid'].disable();
                    this.pricingform.controls['home_collection_charges'].disable();
                    this.pricingform.controls['total_adjusted_amount'].disable();
                    this.pricingform.controls['payment_type'].disable();
                    this.pricingform.controls['total_amount'].disable();
                    this.pricingform.get('advance_paid').setValue(0);
                    this.pricingform.get('home_collection_charges').setValue(0);
                    this.pricingform.get('total_adjusted_amount').setValue('0');
                    this.pricingform.get('payment_type').setValue('Postpaid');
                    this.pricingform.get('total_amount').setValue('0');
                    this.pricingform.get('amount_to_be_collect').setValue('0');
                    this.pricingform.get('total_order_value').setValue('0');
                }

                if (data.data.payment_type == null) {
                    this.pricingform.get('payment_type').setValue('Postpaid');
                } else {
                    if (this.visit_number != 'second') {
                        this.changePaymentType('Postpaid')
                    }
                }
            });
        } else {
            this.refreshPatients();
        }
    }
    onItemSelect(item: any) {
        console.log(item);
      }
      onSelectAll(items: any) {
        console.log(items);
      }
    selectTest(items: any) {
        this.testid.push(items.id);

       
        this.testform.get('test').setValue(this.testid);
      }
    get patientInfo() { return this.patientform.controls; }
    get testInfo() { return this.testform.controls; }
    get priceInfo() { return this.pricingform.controls; }

    getPatientValue(id) {

        this.OrderService.getPatientbyId(id).subscribe((data) => {

            if (data.data != null) {

                this.patientform.get('first_name').setValue(data.data.first_name);
                this.patientform.get('last_name').setValue(data.data.last_name);
                this.patientform.get('gender').setValue(data.data.gender);
                this.patientform.get('age').setValue(data.data.age);
                this.patientform.get('remark').setValue(data.data.remark);
            } else {
                this.patientform.get('first_name').setValue("");
                this.patientform.get('last_name').setValue("");
                this.patientform.get('gender').setValue("");
                this.patientform.get('age').setValue("");
                this.patientform.get('remark').setValue("");
            }

        });
    }







    refreshPatients() {
        if (this.orderId) {
            this.isLoadedTable = true;
            if (this.visit_id) {
                this.OrderService.getPatientForTableByVisit(this.orderId, this.visit_id).subscribe(
                    (data) => {
                        this.allPatientList = data.data.patient_data;
                        this.Visit1Test = data.data.patient_data;
                        this.PatientCount = data.data.patient_data.length;

                        let thisData = [];
                        this.selectorMultiple.removeAll();
                        thisData.push(
                            { value: 0, text: `All` }
                        )
                        data.data.patient_data.forEach(item => {
                            thisData.push(
                                { value: item.id, text: `${item.first_name}  ${item.last_name ? item.last_name : ''}` }
                            )
                        });
                        this.selectorMultiple.add(thisData);
                        this.GetTotalAmount();
                        this.priceInfo.payment_type.markAsUntouched();
                    }, (err) => {
                        console.log(err);
                    },
                    () => {
                        this.isLoadedTable = false;
                    }
                );
                this.patientform.get('oldpatient').setValue(0);
            } else {
                this.OrderService.getPatientForTableByOrder(this.orderId).subscribe(
                    (res) => {
                        if (res.data.visit2) {
                            this.allPatientList = [...res.data.visit1, ...res.data.visit2];
                            this.Visit1Test = res.data.visit1;
                            this.Visit2Test = res.data.visit2;
                            this.PatientCount = res.data.visit1.length + res.data.visit2.length;
                        } else {
                            this.Visit1Test = res.data.visit1;
                            this.allPatientList = res.data.visit1;
                            this.PatientCount = res.data.visit1.length;
                        }
                        this.selectorMultiple.removeAll();
                        let thisData = [];
                        thisData.push(
                            { value: 0, text: `All` }
                        )
                        this.allPatientList.forEach(item => {
                            thisData.push(
                                { value: item.id, text: `${item.first_name}  ${item.last_name ? item.last_name : ''}` }
                            )
                        });
                        this.selectorMultiple.add(thisData);
                        this.GetTotalAmount();
                    },
                    (err) => {
                        console.log(err);
                    },
                    () => {
                        this.isLoadedTable = false;
                    }
                );
                this.patientform.get('oldpatient').setValue(0);
            }
        }
    }

    openSpec(data) {
        const modalRef = this.modalService.open(TableModalComponent, {
            centered: true,
            size: 'md',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Test List`,
            subTitle: ``,
            type: 'showSpecimen',
            page: 1,
            data: data,
        };
    }

    omit_special_char(event) {
        var k;
        k = event.charCode;  //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }

    onAddPatient(): void {
        this.patientform.markAllAsTouched();
        if (this.PatientCount < 7) {
            if (this.patientform.valid) {
                if (this.isPatientEdit) {
                    this.isBtnLoaded = true
                    this.patientform.value.id = this.PatientEditId;
                    this.OrderService.updateOrder(this.patientform.value, this.PatientEditId, 'update_order_patient').subscribe(
                        (res: any) => {
                            this.resetPatientForm();
                            this.refreshPatients();
                        },
                        (err: any) => {
                            this.toastr.error(`${err}`);
                            console.log(err);
                        },
                        () => {
                            this.isBtnLoaded = false;
                            this.isPatientEdit = false;
                            this.PatientEditId = undefined;
                        }
                    );
                } else {
                    if (this.visit_id) {
                        this.isBtnLoaded = true
                        this.patientform.get('visit_id').setValue(this.visit_id);
                        this.patientform.get('order_id').setValue(this.orderId);
                        this.OrderService.postOrder(this.patientform.value, 'save_order_patient_by_visit').subscribe(
                            (res: any) => {
                                this.resetPatientForm();
                                this.refreshPatients();
                            },
                            (err: any) => {
                                this.toastr.error(`${err}`);
                                console.log(err);
                            },
                            () => {
                                this.isBtnLoaded = false;
                            }
                        );
                    } else {
                        if (this.orderId && this.visit_id) {
                            this.OrderService.postOrder(this.patientform.value, 'save_order_patient').subscribe(
                                (res: any) => {
                                    this.resetPatientForm();
                                    this.refreshPatients();
                                },
                                (err: any) => {
                                    this.toastr.error(`${err}`);
                                    console.log(err);
                                }
                            );
                        } else {
                            this.createOrder();
                        }
                    }
                }
            }
        } else {
            this.resetPatientForm();
            this.toastr.error(`You can add maximum 7 patients`);
        }
    }

    resetPatientForm() {
        this.patientform.reset();
        this.patientform.get('gender').setValue('');
        this.patientform.get('order_id').setValue(this.orderId);
    }

    resetTestForm() {
        this.testform.get('order_id').setValue('');
        this.testform.get('patient').setValue('');
        this.testform.get('test').setValue('');

        this.testform.markAsUntouched();
    }

    editPatient(data: any) {
        this.isPatientEdit = true;
        this.PatientEditId = data.id;
        this.patientform.patchValue(data);
    }

    deletePatient(data: any) {
        const modalRef = this.modalService.open(deleteModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Delete this Patient?`,
            subTitle: ``,
            type: 'orderDelete',
            page: 1,
            data: data,
            screenName: 'order_patient_delete'
        };

        modalRef.componentInstance.passEntry.subscribe((res: any) => {
            this.refreshPatients();
        });
    }

    createOrder(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        if (this.form.valid) {
            let fromData = this.form.getRawValue();
            if (typeof (fromData.date) == 'object') {
                fromData.date = this.datepipe.transform(fromData.date, 'YYYY-MM-d HH:MM:ss')
            } else {
                fromData.date = this.displayNotEditedDateTime;
            }
            if (this.orderId) {
                fromData.visit_id = this.visit_id;
                this.OrderService.updateOrder(fromData, this.orderId, 'update_order_master').subscribe(
                    (res: any) => {
                        this.orderId = res.data.id;
                        this.patientform.get('order_id').setValue(res.data.id);
                    },
                    (err: any) => {
                        this.toastr.error(`${err}`);
                        console.log(err);
                    }
                );
            } else {
                this.isBtnLoaded = true
                this.OrderService.postOrder(fromData, 'save_order_master').subscribe(
                    (res: any) => {
                        this.patientform.get('order_id').setValue(res.data.id);
                        this.orderId = res.data.id

                        this.OrderService.postOrder(this.patientform.value, 'save_order_patient').subscribe(
                            (res: any) => {
                                this.visit_id = res.data.visit_id;
                                this.resetPatientForm();
                                this.refreshPatients();
                            },
                            (err: any) => {
                                this.toastr.error(`${err}`);
                                console.log(err);
                            },
                            () => {
                                this.isBtnLoaded = false
                            }
                        );

                    },
                    (err: any) => {
                        this.toastr.error(`${err}`);
                        console.log(err);
                    },
                    () => {
                        this.firstTimeOrderCreate = true;
                    }
                );
            }
        }
    }


    getTest(customer_id, provider_id, patient_city, labid) {

        this.OrderService.getallTest_codeByProvider(customer_id, provider_id, patient_city, labid).subscribe((data) => {
            
            console.log('final data',data);
            this.test_dropdownList=data.data;

            this.TestlistForDropdown = data.data;

            this.GetTotalAmount();
            this.priceInfo.payment_type.markAsUntouched();
        });
    }

    onAddTest(): void {
        this.testform.markAllAsTouched();
        if (this.testform.invalid) {
            return;
        }
        if (this.testform.valid) {
            this.isTestBtnLoaded = true
            this.testform.get('order_id').setValue(this.orderId);
            if (this.isEditOrder) {
                this.testform.get('visit_id').setValue(this.visit_id);
                this.OrderService.postOrder(this.testform.value, 'save_order_patient_test_visit_new').subscribe(
                    (res: any) => {
                        this.resetTestForm();
                        this.refreshPatients();
                    },
                    (err: any) => {
                        this.toastr.error(`${err}`);
                        console.log(err);
                    },
                    () => {
                        this.isTestBtnLoaded = false;
                    }
                );
            }
            else {
                this.OrderService.postOrder(this.testform.value, 'save_order_patient_test_new').subscribe(
                    (res: any) => {
                        this.resetTestForm();
                        this.refreshPatients();
                    },
                    (err: any) => {
                        this.toastr.error(`${err}`);
                        console.log(err);
                    },
                    () => {
                        this.isTestBtnLoaded = false;
                    }
                );
            }
        }

    }

    deleteTest(data: any) {
        const modalRef = this.modalService.open(deleteModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Delete this Test?`,
            subTitle: ``,
            type: 'orderDelete',
            page: 1,
            data: data,
            screenName: 'order_test_delete'
        };

        modalRef.componentInstance.passEntry.subscribe((res: any) => {
            this.refreshPatients();
        });
    }


    onAddPrice(isDiscount = 'null'): void {


        this.pricingform.markAllAsTouched();

        if (this.pricingform.valid) {
            let fromData = this.pricingform.getRawValue();
            if (isDiscount == 'null') {
                this.isPriceBtnLoaded = true
            }

            if (this.orderId) {
                // this.createOrder();
                console.log("padam111");
                this.OrderService.updateOrder(fromData, this.orderId, 'update_order_amount').subscribe(
                    (res: any) => {
                        if (res.success) {
                            // this.router.navigate(['orders']);

                            if (isDiscount == 'null') {
                                this.router.navigateByUrl(`order-public-view/${this.sha_visit_id}`, {
                                    state: {
                                        form: 'view'
                                    }
                                });
                            }
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`not Updated`);
                            }
                        }
                    },
                    (err: any) => {
                        this.toastr.error(`${err}`);
                        console.log(err);
                    },
                    () => {
                        this.isPriceBtnLoaded = false;
                    }
                );
            }
        }
    }

    removeDiscount(id) {
        console.log(id);

        let sendData = {
            "discount_type": null,
            "discount_value": null,
            "final_price": id.test_price,
        };

        this.OrderService.edit_patient_test(id.id, sendData).subscribe(
            (res: any) => {
                this.refreshPatients();
                this.toastr.success(`Discount Removed`);
            },
            (err: any) => {
                this.toastr.error(`${err}`);
                console.log(err);
            }
        );
    }

    setDiscount(data: any) {
        const modalRef = this.modalService.open(AddEditDiscountComponent, {
            centered: true,
            size: 'sm',
        });
        modalRef.componentInstance.props = {
            title: 'Set Discount',
            type: 'addedit',
            page: `1`,
            data: data
        }
        modalRef.componentInstance.passEntry.subscribe((res: any) => {
            this.refreshPatients()
        })
    }

    GetTotalAmount() {
        this.pricingform.controls['total_amount'].disable();
        if (this.visit_id) {
            if (this.visit_number != 'second') {
                this.OrderService.getTotalAmountNew(this.visit_id).subscribe(
                    (data) => {
                        this.pricingform.get('total_amount').setValue(data.data.total_amount);
                        this.totalTestOrderAmount = data.data.total_amount;
                        this.pricingform.get('total_adjusted_amount').setValue(data.data.total_amount);
                        this.calculatePayment(data.data.total_amount, 'total')
                    }
                );
            }
        }
    }

    calculatePayment(data, type) {
        let totalAdjustedAmount: number
        let advancePaid: number
        let homeCharge: number
        let grandTotal: number

        if (type == 'home') {

            homeCharge = data

            if (this.pricingform.value.advance_paid == undefined) {
                advancePaid = 0;
            } else {
                advancePaid = this.pricingform.value.advance_paid;
            }
            if (this.pricingform.value.total_adjusted_amount == undefined) {
                totalAdjustedAmount = 0;
            } else {
                totalAdjustedAmount = this.pricingform.value.total_adjusted_amount;
            }
        } else if (type == 'advance') {
            if (this.pricingform.value.home_collection_charges == undefined) {
                homeCharge = 0;
            } else {
                homeCharge = this.pricingform.value.home_collection_charges;
            }

            advancePaid = data;

            if (this.pricingform.value.total_adjusted_amount == undefined) {
                totalAdjustedAmount = 0;
            } else {
                totalAdjustedAmount = this.pricingform.value.total_adjusted_amount;
            }
        } else if (type == 'total') {

            if (this.pricingform.value.home_collection_charges == undefined) {
                homeCharge = 0;
            } else {
                homeCharge = this.pricingform.value.home_collection_charges;
            }

            if (this.pricingform.value.advance_paid == undefined) {
                advancePaid = 0;
            } else {
                advancePaid = this.pricingform.value.advance_paid;
            }

            totalAdjustedAmount = data;
        }

        grandTotal = Number(totalAdjustedAmount) + Number(homeCharge);

        if (grandTotal >= 0) {
            //if (this.Visit1Test.length > 0) {
            this.pricingform.get('total_order_value').setValue(grandTotal);

            var amount_to_be_collect = Number(grandTotal) - Number(advancePaid);

            if (this.pricingform.value.payment_type != 'Prepaid') {
                if (amount_to_be_collect >= 0) {
                    this.pricingform.get('amount_to_be_collect').setValue(amount_to_be_collect);
                } else {
                    this.toastr.error(`Enter Valid Number`);
                }
            }
            //} else {
            // this.pricingform.get('amount_to_be_collect').setValue('');
            // this.pricingform.get('total_order_value').setValue('');
            // this.pricingform.get('home_collection_charges').setValue('');
            // this.pricingform.get('advance_paid').setValue('');
            // this.pricingform.get('total_adjusted_amount').setValue('');
            // this.pricingform.get('payment_type').setValue('');
            //}
        } else {
            this.toastr.error(`Enter Valid Number`);
        }
    }

    changePaymentType(paymentType) {
        this.GetTotalAmount();

        this.pricingform.controls['advance_paid'].enable();
        this.pricingform.get('amount_to_be_collect').setValue(this.totalTestOrderAmount);
        this.pricingform.get('total_adjusted_amount').setValue(this.totalTestOrderAmount);

    }

    calculate(total: number, advance: number, discountType: any = null, discountAmount: number = 0) {
        if (discountType == 'Flat') {
            return total - discountAmount - advance;
        } else if (discountType == 'Percentage') {
            return (total - ((discountAmount / 100) * total)) - advance;
        } else {
            return total - advance;
        }

    }

    CalculateDiscountpayment(test, total) {
        let type = (<HTMLInputElement>document.getElementById('GetDiscountType' + test.visit_patient_id + '-' + test.id)).value;
        let value = (<HTMLInputElement>document.getElementById('GetDiscountValue' + test.visit_patient_id + '-' + test.id)).value;
        console.log(test);

        if (total) {
            if (type != 'null' && type != undefined) {
                if (value != '' && value != undefined) {
                    var finalAmount = this.calculate(Number(total), 0, type, Number(value))

                    if (finalAmount >= 0) {
                        this.isLoadedTable = true;
                        this.thisTestID = test.id;
                        let sendData = {
                            "discount_type": type,
                            "discount_value": value,
                            "final_price": finalAmount,
                        };
                        this.OrderService.edit_patient_test(this.thisTestID, sendData).subscribe(
                            (res: any) => {
                                this.onAddPrice('notNull')
                                this.refreshPatients();
                            },
                            (err: any) => {
                                this.toastr.error(`${err}`);
                                console.log(err);
                                this.isLoadedTable = false;
                            }
                        );

                    } else {
                        this.toastr.error(`Enter Valid Number`);
                    }
                }

            } else {
                this.toastr.error(`Select Discount Type`);
            }
        } else {
            this.toastr.error(`Enter Valid Number`);
        }
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpService } from '../http/http.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
    public phlebodata: any;

    constructor(private httpService: HttpService) { }

    getInvoiceList(data:any) {
      const url = `${environment.OrderApiendpoint}/invoice-list?${data}`;
      return this.httpService.get(url, this.httpService.headers)
          .pipe(
              catchError(this.Errorhandling)
          );
  }

  createInvoice(data:any){
    const url = `${environment.OrderApiendpoint}/genrate-invoice`;
    return this.httpService.post(url, data, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }



viewInvoice(id:any){
    const url = `${environment.OrderApiendpoint}/view-invoice/${id}`;
    return this.httpService.get(url, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }


publishInvoice(id:any){
    const url = `${environment.OrderApiendpoint}/publish-invoice/${id}`;
    return this.httpService.get(url, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }

  deleteInvoice(id:any){
    const url = `${environment.OrderApiendpoint}/delete-invoice/${id}`;
    return this.httpService.get(url, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }



exportInvoice(id:any){
    const url = `${environment.OrderApiendpoint}/export-invoice/${id}`;
    return this.httpService.get(url, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }

//  add pdf api


downloadInvoicePDF(id:any){
    const url = `${environment.OrderApiendpoint}/invoice-pdf/${id}`;
    return this.httpService.get(url, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
}

// https://mtqn0qowxc.execute-api.us-east-1.amazonaws.com/save-invoice-discount

addDiscount(data:any, id:any){
    let formdata = data;
    formdata.id = id;
    const url = `${environment.OrderApiendpoint}/save-invoice-discount`;
    return this.httpService.post(url, formdata, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
}

  


    Errorhandling(err: HttpErrorResponse) {
        if (err.error instanceof ErrorEvent) {
            console.error(err.error.message);
        } else {
            console.error(`Backend returned code ${err.status}`);
        }
        return throwError('Please try again later.');
    }
}

import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyaccountService } from 'src/app/services/myaccount/myaccount.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms"
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../services/order/order.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  public adminDetails: any;
  public feedbackForm: FormGroup;
  public isLoginBtnLoaded: Boolean = false;
  public showPass: boolean = false;
  patientform!: FormGroup
  public isBtnLoaded = false
  orderId: string;
  condition: number = 0;
list: any[] = new Array(5);
ratingData:any;

  constructor(
    private formBuilder: FormBuilder,

    private router: Router,
    private myaccountService: MyaccountService,
    private toastr: ToastrService,
    private OrderService: OrderService,
    private route: ActivatedRoute,

  ) {
    this.route.paramMap.subscribe(params => {
      this.orderId = params.get('id');
  });
  }

  ngOnInit() {
    this.patientform = this.formBuilder.group({
   
      remark: [''],
      rating: [0],
      shaid: [''],
        
  });
  }
  
  get patientInfo() { return this.patientform.controls; }
  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
}

  onAddPatient(): void {

        this.patientform.markAllAsTouched();
            if (this.patientform.valid) {
              
              this.patientform.get('rating').setValue(this.ratingData);
              this.patientform.get('shaid').setValue(this.orderId);

              this.OrderService.savePatientFeedback(this.patientform.value).subscribe(
                (res: any) => {
                    this.isBtnLoaded = false;
                    this.toastr.success(`${res.message}`);

                    this.resetPatientForm();
                    this.isBtnLoaded = false;
                },
                (err: any) => {
                    this.toastr.error(`${err}`);
                    console.log(err);
                    this.isBtnLoaded = false;
                },
                () => {
                    this.isBtnLoaded = false;
                }
            );
                }
       
      
    }
    resetPatientForm() {
      this.patientform.reset();
     
  }

  onSubmitClick = function () {
    if (!this.feedbackForm.valid) {
      this.feedbackForm.markAllAsTouched();
     
    } else {
      
      if (this.feedbackForm.value.newPassword == this.feedbackForm.value.confirmNewPassword) {
         this.isLoginBtnLoaded = true;
        //console.log(this.feedbackForm.value); return;
        this.myaccountService
          .updatePassword(this.feedbackForm.value)
          .subscribe(
            (res: any) => {
              this.logout();
              if (res.message) {
                this.toastr.success(`${res.message}`);
              } else {
                this.toastr.success(`Password updated successfully`);
              }
            },
            (err: any) => {
              this.toastr.error(`${err}`);
              console.log(err);
            },
            () => {
              this.isLoginBtnLoaded = false;
            }
          );
      } else {
        this.toastr.error(`New Password and confirm password are not same`);
      }
    }
  };

  logout() {
    this.router.navigate(['/login']);
    return localStorage.clear();
  }
}

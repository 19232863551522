import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HttpService } from '../http/http.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(private httpService: HttpService) { }

  beginStep(data:any) {
    const url = `${environment.OrderApiendpoint}/get-all-visits?${data}`;
    return this.httpService.get(url,this.httpService.headers)
    .pipe(
        catchError(this.Errorhandling)
    );
  }

  createPlan(data:any,date:any,areacode_id:any) {
    const url = `${environment.PlanApiendpoint}/make-plan`;
    return this.httpService.post(url,{visitid:data,date:date,areacode_id:areacode_id}, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
  firstStep(areacode_id:any) {
    const url = `${environment.PlanApiendpoint}/view-plan`;
    return this.httpService.post(url,{areacode_id:areacode_id}, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }


  getVisitPhleboData(phleboId:any,date:any,) {
    const url = `${environment.PlanApiendpoint}/phlebo-occupied-slot-detail`;
    return this.httpService.post(url,{phleboId:phleboId,date:date}, this.httpService.headers)
    .pipe(
        catchError(this.Errorhandling)
    );
  }
  secondStep(id:any) {
    const url = `${environment.PlanApiendpoint}/phlebo-plan-view/${id}`;
    return this.httpService.get(url,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
  getPlanbyAreacode(data:any) {
    const url = `${environment.PlanApiendpoint}/get-planby-areacode`;
    return this.httpService.post(url,{date:data}, this.httpService.headers)
    .pipe(
        catchError(this.Errorhandling)
    );
  }

    thirdStep(id:any) {
    const url = `${environment.PlanApiendpoint}/make-final-plan`;
    return this.httpService.post(url,id,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
    fourthStep (id:any) {
    const url = `${environment.PlanApiendpoint}/view-plan`;
    return this.httpService.post(url,id,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
  fifthStep (id:any) {
    const url = `${environment.PlanApiendpoint}/final-visit-update`;
    return this.httpService.put(url,id,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
  
  updateVisitLatLong (data:any) {
    const url = `${environment.PlanApiendpoint}/update-visit-latlong`;
    return this.httpService.post(url,data,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
  getPlanList(id:any) {
    const url = `${environment.PlanApiendpoint}/view-plan-list/${id}`;
    return this.httpService.get(url,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
  viewMap(id:any) {
    const url = `${environment.PlanApiendpoint}/view-map-data/${id}`;
    return this.httpService.get(url,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
  deletePlanList(id:any) {
    const url = `${environment.PlanApiendpoint}/delete-plan/${id}`;
    return this.httpService.get(url,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }

  
getcityareacode(id:any) {
  console.log("--------------good-----------------");

  const url = `${environment.PlanApiendpoint}/get-city-areacode/${id}`;
  return this.httpService.get(url,this.httpService.headers)
      .pipe(
          catchError(this.Errorhandling)
      );
}
  
  unbookedPlan(id:any) {
    const url = `${environment.PlanApiendpoint}/unbooked-visit/${id}`;
    return this.httpService.get(url,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
  changephlebo(data:any) {
    const url = `${environment.PlanApiendpoint}/final-update`;
    return this.httpService.put(url,data,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }

  updatePhleboPlan(data:any){
    const url = `${environment.PlanApiendpoint}/update-phlebo-plan`;
    return this.httpService.put(url,data,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }

  deletephleboPlan(id:any){
    const url = `${environment.PlanApiendpoint}/phlebo-plan-delete/${id}`;
    return this.httpService.get(url,this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
  }
  Errorhandling(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
        console.error(err.error.message);
    } else {
        console.error(`Backend returned code ${err.status}`);
    }
    return throwError('Please try again later.');
  }
}

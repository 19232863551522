import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from 'src/app/services/order/order.service';

@Component({
    selector: 'app-add-edit-discount',
    templateUrl: './add-edit-discount.component.html',
    styleUrls: ['./add-edit-discount.component.scss']
})
export class AddEditDiscountComponent implements OnInit {
    @Input() props: any;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    //Props
    public title: string;
    public type: string;
    public data: any;
    public pages: any;
    public editId: any

    //Loader
    public isBtnLoaded: boolean = false;

    //From Group
    public discountForm: FormGroup;
    totalAmount: any;

    constructor(
        private ngbModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private OrderService: OrderService,
        private toastr: ToastrService,
    ) { }

    ngOnInit(): void {
        this.discountForm = this.formBuilder.group({
            discount_type: ['', Validators.required],
            discount_value: ['', Validators.required],
            final_price: ['', Validators.required]
        });
        this.setDialogProps(this.props);
    }

    get discountInfo() {
        return this.discountForm.controls;
    }

    setDialogProps(dialogdata: any) {
        this.title = dialogdata.title;
        this.type = dialogdata.type;
        this.data = dialogdata.data;
        this.pages = dialogdata.page;
        this.editId = this.data.id;
        this.totalAmount = this.data.test_price;
        this.discountForm.patchValue(this.data);
        console.log(this.data);

    }

    closeModal() {
        this.passEntry.next(this.pages);
        this.ngbModal.close();
    }

    submit() {
        if (this.discountForm.valid) {
            this.isBtnLoaded = true;
            this.OrderService.edit_patient_test(this.editId, this.discountForm.value).subscribe(
                (res: any) => {
                    if (res.success) {
                        this.toastr.success(`${res.message}`);
                    } else {
                        if (res.message) {
                            this.toastr.error(`${res.message}`);
                        } else {
                            this.toastr.error(`Added Successfully`);
                        }
                    }
                },
                (err: any) => {
                    this.toastr.error(`${err}`);
                },
                () => {
                    this.closeModal();
                    this.isBtnLoaded = false;
                })
        }
        else {
            this.discountForm.markAllAsTouched();
        }
    }

    calculate(total: number, advance: number, discountType: any = null, discountAmount: number = 0) {
        if (discountType == 'Flat') {
            return total - discountAmount - advance;
        } else if (discountType == 'Percentage') {
            return (total - ((discountAmount / 100) * total)) - advance;
        } else {
            return total - advance;
        }

    }

    cangeDiscountType(type) {
        if (type == 'null') {
            this.toastr.error(`Please Select Discount Type`);
        } else {
            if (this.discountForm.value.discount_value != '') {
                var amount_to_be_collect = this.calculate(this.totalAmount, 0, type, this.discountForm.value.discount_value)
                if (amount_to_be_collect >= 0) {
                    this.discountForm.get('final_price').setValue(amount_to_be_collect)
                } else {
                    this.toastr.error(`Enter Valid Number`);
                }
            } else {
                this.toastr.error(`Enter Valid discount Amount`);
                this.discountForm.get('final_price').setValue(this.totalAmount)
            }
        }
    }

    ChangeDiscountAmount(discountAmount) {
        let type =this.discountForm.value.discount_type
        if (type == 'null') {
            this.toastr.error(`Please Select Discount Type`);
        } else {
            if (discountAmount != '') {
                var amount_to_be_collect = this.calculate(this.totalAmount, 0, type, discountAmount)
                if (amount_to_be_collect >= 0) {
                    this.discountForm.get('final_price').setValue(amount_to_be_collect)
                } else {
                    this.toastr.error(`Enter Valid Number`);
                }
            } else {
                this.toastr.error(`Enter Valid discount Amount`);
                this.discountForm.get('final_price').setValue(this.totalAmount)
            }
        }
    }
}


import { DatePipe,Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import Selectr from "mobius1-selectr";
import { TableModalComponent } from "src/app/components/TableModal/TableModal.component";

import { cityService } from "src/app/services/city/city.service";
import { CustomerdosService } from "src/app/services/customerdos/customerdos.service";
import { OrderService } from "src/app/services/order/order.service";
import { deleteModalComponent } from "src/app/components/deleteModal/deleteModal.component";
import { AddEditDiscountComponent } from "../pages/orders/order-create/add-edit-discount/add-edit-discount.component"; 
import { DOCUMENT } from '@angular/common'; 
import { AuthenticationService } from "src/app/services/auth/authentication.service";
import { CustomersService } from "src/app/services/customer/customer.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
    

@Component({
  selector: 'app-create-srl-upsell',
  templateUrl: './create-srl-upsell.component.html',
  styleUrls: ['./create-srl-upsell.component.scss']
})
export class CreateSrlUpsellComponent implements OnInit {

    customer_dropdownList = [];
    customer_dropdownSettings :IDropdownSettings;

    test_dropdownList = [];
    test_dropdownSettings :IDropdownSettings;
    selectedItems=[];
    form!: FormGroup
    patientform!: FormGroup
    testform!: FormGroup
    pricingform!: FormGroup

    public orderId: any;
    public thisDiscountType: any;
    public thisDiscountValue: any;
    public visit_id: any;
    public minDate: Date;
    public PatientCount = 0;

    //All Dropdown
    public customerlistForDropdown = [];
    public statelistForDropdown = [];

    public citylistForDropdownByState = [];
    public labLocationlistForDropdownByCity = [];
    public slotlistForDropdown = [];
    public TestlistForDropdown = [];
    selectorMultiple: any;
    selectrSingle: any;
       searchMobilefilter: string;
    patientDetails: any=[];
    public isRefreshFilter:any=false;
 public checkPatientExist=false;
 public isCheckRedeemBtnLoaded=false;
    optionsMultiple = {
        multiple: true,
        placeholder: 'Select Patients',
        data: []
    };
public confirm_otp="";
public promo_code="";
public isPromoBtnLoaded=false;
public  optionsSingle = {
         multiple: true,
        placeholder: 'Select test',
         data: []
    };

    //All Loader
    public isBtnLoaded = false
    public isLoadedTable: boolean;

    //Table List
    public allPatientList = []
    public Visit1Test = []
    public Visit2Test = []
    isPatientEdit: boolean;
    PatientEditId: any;
    displayNotEditedDateTime: any;
    firstTimeOrderCreate: boolean;
    isTestBtnLoaded: boolean;
    totalTestOrderAmount: any;
    thisFinalPrice = 0;
    thisTestID: number;
    isPriceBtnLoaded: boolean;
    isEditOrder: boolean = false;
    visit_number: any;
    login_customer_id: any;
    userType: any;
    currenturl: string;
    isAggregator: any;
    providerListForDropdown: any;
    providerId: any=0;
    phleboId: string;
    sha_visit_id: any;
    customer_id: any;
    available_point: any=0;
    redeem_amount: any=0;
    isConfirmBtnLoaded: boolean=false;
    isResendBtnLoaded: boolean;
    isConfirmRedeem: boolean;
    discount_per: any=0;
    descount_amount: any=0;
    confirmPromoBtnLoaded: boolean=false;
    check_redeem: string="";
    additional_charges_msg: any="N/A";

    constructor(
        private formBuilder: FormBuilder,
        private customerorderService: CustomerdosService,
        private OrderService: OrderService,
        private toastr: ToastrService,
        private router: Router,
        public datepipe: DatePipe,
        private cityService: cityService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private loginService: AuthenticationService,  
        private location: Location,
        private customerService:CustomersService,


    ) {


        this.minDate = new Date();
        this.currenturl=this.router.url
    }

    ngOnInit() {
       
         
         
          
          this.test_dropdownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'test_name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          };

        this.route.paramMap.subscribe(params => {
            if (params.get('visit_id')) {
                this.visit_id = params.get('visit_id');
                this.isEditOrder = true;
            }
            this.orderId = params.get('id');
            //this.phleboId = params.get('phleboid');
            //this.phleboId = params.get('sha_id');
            var phleboId = params.get('phleboid');
            if(phleboId)
            {
                this.phleboId=phleboId;
            }
            var sha_id = params.get('sha_id');
            if(sha_id)
            {

                //sha1("SRL-8112271155");
                if(sha_id!="01fa0187d0416cc64046229abdadc811e68b1a89")
                {
                    this.router.navigate(['login']);
                }
                this.phleboId=sha_id;
            }

        });
 
        
      //  this.getAllLabLocationForCust( this.customer_id);
     
        this.form = this.formBuilder.group({
            order_ref_id: [''],
            //customer_id: ['', Validators.required],
            customer_id: [''],
            phlebo_id: [''],
            date: ['', Validators.required],
            slot: ['', Validators.required],
            confirm_slot: [''],
            
            patient_address: ['', Validators.required],
            patient_state: ['', Validators.required],
            patient_city: ['', Validators.required],
            patient_pincode: ['', Validators.required],
            landmark: [''],
            is_test: [false],

            patient_email: ['', [Validators.email]],
            patient_phone: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
            lab_location_id: ['0', Validators.required],
            provider_id:[0]
        });

        this.OrderService.getSrlCustomer().subscribe((data) => {
            this.form.get('customer_id').setValue( data.data.id);

          });
        this.patientform = this.formBuilder.group({
            order_id: [''],
            visit_id: [''],
            remark: [''],
            patient_ref_id: [''],
            first_name: ['', Validators.required],
            last_name: [''],
            gender: ['', Validators.required],
            dob: ['', Validators.required],
           // age: ['', [Validators.required, Validators.min(3), Validators.pattern("^[0-9]*$")]],
        });

        this.testform = this.formBuilder.group({
            order_id: [''],
            visit_id: [''],
            patient: ['', Validators.required],
            test: ['', Validators.required]
        });

        this.pricingform = this.formBuilder.group({
            payment_type: ['Postpaid'],
            home_collection_charges: [''],
            total_adjusted_amount: ['', Validators.required],
            total_amount: ['', Validators.required],
            advance_paid: [''],
            amount_to_be_collect: ['', Validators.required],
            total_order_value: ['', Validators.required],
            
        });
       
        var selectrmultiple: any = document.getElementById("selectr-multiple-citys");
        this.selectorMultiple = new Selectr(selectrmultiple, this.optionsMultiple);

        //var selectrSingleElement: any = document.getElementById("selectr-tests");
      //  this.selectrSingle = new Selectr(selectrSingleElement, this.optionsSingle);

        if (this.orderId) {
            this.citylistForDropdownByState = [];
            this.statelistForDropdown = [];
            this.OrderService.getSingleOrderData_by_visit_id(this.orderId, this.visit_id).subscribe((data) => {
                setTimeout(() => {
                    this.refreshPatients();
                }, 500);
             //   this.getAllLabLocationByCust(data.data.customer_id);
                this.displayNotEditedDateTime = data.data.date;
                this.visit_number = data.data.visit_number;
                this.sha_visit_id = data.data.sha_visit_id;

                this.getTestProvider(data.data.customer_id,data.data.provider_id,data.data.patient_city,data.data.lab_location_id);

                this.getCityStateByPincode(data.data.patient_pincode);
                if (data.data.date) {
                    data.data.date = this.datepipe.transform(data.data.date, 'd-MM-YYYY')
                } else {
                    data.data.date = ''
                }
                this.form.patchValue(data.data);
                this.form.get('lab_location_id').setValue(data.data.lab_location_id);
                this.form.get('slot').setValue(data.data.slot);
                this.form.get('confirm_slot').setValue(data.data.confirm_slot);
                if(data.data.is_test==0)
                {
                    this.form.get('is_test').setValue(false);   
                }else{
                    this.form.get('is_test').setValue(true);   
  
                }

                
                console.log('edit', data.data);
                

                this.pricingform.patchValue(data.data);
                this.totalTestOrderAmount = data.data.total_amount;
                this.form.get('provider_id').setValue(data.data.provider_id);

                if (this.visit_number == 'second') {
                    this.pricingform.controls['advance_paid'].disable();
                    this.pricingform.controls['home_collection_charges'].disable();
                    this.pricingform.controls['total_adjusted_amount'].disable();
                    this.pricingform.controls['payment_type'].disable();
                    this.pricingform.controls['total_amount'].disable();
                    this.pricingform.get('advance_paid').setValue(0);
                    this.pricingform.get('home_collection_charges').setValue(0);
                    this.pricingform.get('total_adjusted_amount').setValue('0');
                    this.pricingform.get('payment_type').setValue('');
                    this.pricingform.get('total_amount').setValue('0');
                    this.pricingform.get('amount_to_be_collect').setValue('0');
                    this.pricingform.get('total_order_value').setValue('0');
                }

                if (data.data.payment_type == null) {
                    this.pricingform.get('payment_type').setValue('');
                } else {
                    if (this.visit_number != 'second') {
                        this.changePaymentType(data.data.payment_type)
                    }
                }
            });
        } else {

            this.refreshPatients();
        }
    }
    onItemSelect(item: any) {
        console.log(item);
      }
      onSelectAll(items: any) {
        console.log(items);
      }
      selectTest(items: any) {
        this.testform.get('test').setValue(items.id);
        console.log(items.id);
      }
    get orderInfo() { return this.form.controls; }
    get patientInfo() { return this.patientform.controls; }
    get testInfo() { return this.testform.controls; }
    get priceInfo() { return this.pricingform.controls; }
 searchPatients() {

         if(this.searchMobilefilter==undefined)
            {
                 
                return true;
            }
        this.isRefreshFilter=true;
           
            this.OrderService.searchPatientByPhone(this.searchMobilefilter).subscribe(
                (data) => {
                this.patientDetails=data.data.RSP_MSG;
                this.available_point=data.data.readeem_point;


                 this.isRefreshFilter=false;
                 this.checkPatientExist=true;
                }
            );
            
}


    resetFilter() {
        this.searchMobilefilter="";
        this.checkPatientExist=false;
        this.isRefreshFilter=false;
      this.patientDetails=[];
    }


onAddNewPatients(data:any)
{
    

    let genderdata="";
    if(data.GENDER=="M")
    {
        genderdata="Male"; 
    }else if(data.GENDER=="F")
    {
        genderdata="Female"; 
    }else{
        genderdata="Other"; 
    }

                        this.patientform.get('patient_ref_id').setValue(data.UHID);
                        this.patientform.get('first_name').setValue(data.FNAME);
                        this.patientform.get('last_name').setValue(data.LNAME);
                        this.patientform.get('gender').setValue(genderdata);
                        this.patientform.get('dob').setValue(this.datepipe.transform(
                            data.DOB,
                            'd-MM-YYYY'
                        ));
                        this.form.get('patient_phone').setValue( this.searchMobilefilter);

                        this.toastr.success(`Patient detail added to input box please save it.`);

                      
}


    getCityStateByPincode(pincode) {
        if (pincode != '') {
            this.cityService.getCityStateByPincode(pincode).subscribe(
                (stateRes) => {
                    console.log(stateRes);
                    if (stateRes.data != null) {
                        this.statelistForDropdown = [{ id: stateRes.data.state.id, state_name: stateRes.data.state.state_name }];
                        this.form.get('patient_state').setValue(stateRes.data.state_id);
                        this.citylistForDropdownByState = [{ id: stateRes.data.city.id, city_name: stateRes.data.city.city_name }];
                        this.form.get('patient_city').setValue(stateRes.data.city_id);
                        this.getAllLabLocation(stateRes.data.city_id)
                    }else{
                        this.toastr.error(`Enter a valid pincode`);
                    }
                }, (err: any) => {
                    if (err.message) {
                        this.toastr.error(`${err.message}`);
                    } else {
                        this.toastr.error(`not Found`);
                    }
                }
            );
        }
    }
    getTestByLab(id)
    {
      
        
        this.getTestProvider(this.form.value.customer_id,this.form.value.provider_id, this.form.value.patient_city,id);   
       
        
    }
    getAllLabLocation(city_id: number) {
        if (this.form.value.customer_id) {
            this.labLocationlistForDropdownByCity = [];
            this.OrderService.GetAllLabLocation(this.form.value.customer_id, city_id,this.providerId).subscribe((data) => {
                this.labLocationlistForDropdownByCity = data.data;
               

                if (data.data.length == 0) {
                  
                    this.orderInfo.lab_location_id.markAllAsTouched();
                    this.orderInfo.customer_id.markAllAsTouched();
                    this.toastr.error(`No lab location found in this city`);
                } else if (data.data.length == 1) {
                   
                    this.form.get('lab_location_id').setValue(data.data[0].id);
                    this.getTestProvider(this.form.value.customer_id,this.form.value.provider_id, this.form.value.patient_city,data.data[0].id);   

                } else {
                  
                    this.orderInfo.lab_location_id.markAsUntouched();
                    this.orderInfo.customer_id.markAsUntouched();
                }
                this.getAllSlot();
               

            }, (err: any) => {
                if (err.message) {
                    this.toastr.error(`${err.message}`);
                } else {
                    this.toastr.error(`not Found`);
                }
            });
        } else {
            this.orderInfo.lab_location_id.markAllAsTouched();
            this.orderInfo.customer_id.markAllAsTouched();
            this.toastr.error(`please Select customer first`);
        }
    
    }

    getProviderByCust(id)
    {
        this.form.get('patient_pincode').setValue('');      
        this.providerId=id;

    }
    getAllLabLocationByCust(myvalue) {
        this.form.get('customer_id').setValue(myvalue.id);

        if (this.form.value.patient_city) {
            this.getAllLabLocation(this.form.value.patient_city)
        } 

        this.customerorderService.getSingleCustomer(myvalue.id).subscribe((data) => {

            if(data.data.is_aggregator=="true")
            {
               
                this.isAggregator =true;
            }else{
                
                this.isAggregator =false;

            }
        });

        this.customerService.getAggregatorProvider(myvalue.id).subscribe((data) => {
            this.providerListForDropdown = data.data;
        });

    }

    getAllLabLocationForCust(myvalue:any) {
       

        this.customerorderService.getSingleCustomer(myvalue).subscribe((data) => {

            if(data.data.is_aggregator=="true")
            {
               
                this.isAggregator =true;
            }else{
                
                this.isAggregator =false;

            }
        });

        this.customerService.getAggregatorProvider(myvalue).subscribe((data) => {
            this.providerListForDropdown = data.data;
        });

    }


    getAllSlot() {
       
        if( this.userType==1){
        this.form.value.customer_id=  this.login_customer_id;
        }
        if (this.form.value.date != '') {

            if (typeof (this.form.value.date) == 'object') {
                this.form.value.date = this.datepipe.transform(this.form.value.date, 'YYYY-MM-d HH:MM:ss')
            }

            console.log("padamkoli0",this.form.value);
            this.slotlistForDropdown = [];
            this.OrderService.getallSlot(this.form.value).subscribe((data) => {
                this.slotlistForDropdown = data.data;
                if (data.message) {
                    this.toastr.success(`${data.message}`);
                } else {
                    this.toastr.success(`Slot Found`);
                }

            }, (err) => {
                if (err.message) {
                    this.toastr.error(`${err.message}`);
                } else {
                    this.toastr.error(`not Found`);
                }
            });
        }
    }

    refreshPatients() {
        if (this.orderId) {
            this.isLoadedTable = true;
            if (this.visit_id) {
                this.OrderService.getPatientForTableByVisit(this.orderId, this.visit_id).subscribe(
                    (data) => {
                        this.allPatientList = data.data.patient_data;
                        this.Visit1Test = data.data.patient_data;
                        this.PatientCount = data.data.patient_data.length;

                        let thisData = [];
                        this.selectorMultiple.removeAll();
                        thisData.push(
                            { value: 0, text: `All` }
                        )
                        data.data.patient_data.forEach(item => {
                            thisData.push(
                                { value: item.id, text: `${item.first_name}  ${item.last_name ? item.last_name : ''}` }
                            )
                        });
                        this.selectorMultiple.add(thisData);
                        this.GetTotalAmount();
                        this.priceInfo.payment_type.markAsUntouched();
                    }, (err) => {
                        console.log(err);
                    },
                    () => {
                        this.isLoadedTable = false;
                    }
                );
            } else {
                this.OrderService.getPatientForTableByOrder(this.orderId).subscribe(
                    (res) => {
                        if (res.data.visit2) {
                            this.allPatientList = [...res.data.visit1, ...res.data.visit2];
                            this.Visit1Test = res.data.visit1;
                            this.Visit2Test = res.data.visit2;
                            this.PatientCount = res.data.visit1.length + res.data.visit2.length;
                        } else {
                            this.Visit1Test = res.data.visit1;
                            this.allPatientList = res.data.visit1;
                            this.PatientCount = res.data.visit1.length;
                        }
                        this.selectorMultiple.removeAll();
                        let thisData = [];
                        thisData.push(
                            { value: 0, text: `All` }
                        )
                        this.allPatientList.forEach(item => {
                            thisData.push(
                                { value: item.id, text: `${item.first_name}  ${item.last_name ? item.last_name : ''}` }
                            )
                        });
                        this.selectorMultiple.add(thisData);
                        this.GetTotalAmount();
                    },
                    (err) => {
                        console.log(err);
                    },
                    () => {
                        this.isLoadedTable = false;
                    }
                );
            }
        }
    }

    openSpec(data) {
        data.displaytype = 'dosview';

        const modalRef = this.modalService.open(TableModalComponent, {
            centered: true,
            size: 'md',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Specimen List`,
            subTitle: ``,
            type: 'showSpecimen',
            page: 1,
            data: data,
        };
    }

    omit_special_char(event) {
        var k;
        k = event.charCode;  //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }
    onAddPatient(): void {
        this.patientform.markAllAsTouched();
        if (this.PatientCount < 7) {
            if (this.patientform.valid) {
                if (this.isPatientEdit) {
                    this.isBtnLoaded = true
                    this.patientform.value.id = this.PatientEditId;
                    this.OrderService.updateOrder(this.patientform.value, this.PatientEditId, 'update_order_patient').subscribe(
                        (res: any) => {
                            this.isBtnLoaded = false;
                            this.resetPatientForm();
                            this.refreshPatients();
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.toastr.error(`${err}`);
                            console.log(err);
                            this.isBtnLoaded = false;
                        },
                        () => {
                            this.isBtnLoaded = false;
                            this.isPatientEdit = false;
                            this.PatientEditId = undefined;
                        }
                    );
                } else {
                    if (this.visit_id) {
                        this.isBtnLoaded = true
                        this.patientform.get('visit_id').setValue(this.visit_id);
                        this.patientform.get('order_id').setValue(this.orderId);
                        this.OrderService.postOrder(this.patientform.value, 'save_order_patient_by_visit').subscribe(
                            (res: any) => {
                                this.resetPatientForm();
                                this.refreshPatients();
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.isBtnLoaded = false;

                                this.toastr.error(`${err}`);
                                console.log(err);
                                this.isBtnLoaded = false;
                            },
                            () => {
                                this.isBtnLoaded = false;
                            }
                        );
                    } else {
                        if (this.orderId && this.visit_id) {
                            this.OrderService.postOrder(this.patientform.value, 'save_order_patient').subscribe(
                                (res: any) => {
                                    this.isBtnLoaded = false;

                                    this.resetPatientForm();
                                    this.refreshPatients();
                                    this.isBtnLoaded = false;
                                },
                                (err: any) => {
                                    this.isBtnLoaded = false;

                                    this.toastr.error(`${err}`);

                                    console.log(err);
                                    this.isBtnLoaded = false;
                                }
                            );
                        } else {
                            this.createOrder();
                            
                        }
                    }
                }
            }
        } else {
            
            this.resetPatientForm();
            this.isBtnLoaded = false;
            this.toastr.error(`You can add maximum 7 patients`);
        }
    }

    resetPatientForm() {
        this.patientform.reset();
        this.patientform.get('gender').setValue('');
        this.patientform.get('order_id').setValue(this.orderId);
    }

    resetTestForm() {
        this.testform.get('order_id').setValue('');
        this.testform.get('patient').setValue('');
        this.testform.get('test').setValue('');
        this.selectorMultiple.reset();
        this.testform.markAsUntouched();
        this.selectedItems=null;

    }

    editPatient(data: any) {
        this.isPatientEdit = true;
        this.PatientEditId = data.id;
        this.patientform.patchValue(data);
    }

    deletePatient(data: any) {
        const modalRef = this.modalService.open(deleteModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Delete this Patient?`,
            subTitle: ``,
            type: 'order_patient_delete_srl',
            page: 1,
            data: data,
            screenName: 'order_patient_delete_srl'
        };

        modalRef.componentInstance.passEntry.subscribe((res: any) => {
            this.refreshPatients();
            this.resetTestForm();

        });
    }

   
    createOrder(): void {

       
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        if (this.form.valid) {
            let fromData = this.form.getRawValue();
            if (typeof (fromData.date) == 'object') {
                fromData.date = this.datepipe.transform(fromData.date, 'YYYY-MM-d HH:MM:ss')
            } else {
                fromData.date = this.displayNotEditedDateTime;
            }
            if(this.userType==1)
            {
                fromData.customer_id = this.login_customer_id
            }
            fromData.phlebo_id =  this.phleboId;

            if (this.orderId) {
                fromData.visit_id = this.visit_id;
                this.OrderService.updateOrder(fromData, this.orderId, 'update_order_master').subscribe(
                    (res: any) => {
                        this.orderId = res.data.id;
                        this.patientform.get('order_id').setValue(res.data.id);
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        console.log("amit",err);
                        this.isBtnLoaded = false;

                        this.toastr.error(`${err}`);

                    }
                );
            } else {
                this.isBtnLoaded = true
                this.OrderService.postOrder(fromData, 'save_order_master').subscribe(
                    (res: any) => {
                        this.patientform.get('order_id').setValue(res.data.id);
                        this.orderId = res.data.id

                        this.OrderService.postOrder(this.patientform.value, 'save_order_patient_form_srl').subscribe(
                            (res: any) => {
                                this.visit_id = res.data.visit_id;
                                this.resetPatientForm();
                                this.refreshPatients();
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                console.log("vinod",err);
                                this.isBtnLoaded = false;

                                this.toastr.error(`${err}`);
                            },
                            () => {
                                this.isBtnLoaded = false
                            }
                        );

                    },
                    (err: any) => {
                        this.isBtnLoaded = false;

                        this.toastr.error(`${err}`);
                        console.log(err);
                    },
                    () => {
                        this.firstTimeOrderCreate = true;
                        this.isBtnLoaded = false;
                    }
                );
            }
        }
    }
    
/*
    getTest(customer_id, patient_city,labid) {

        this.OrderService.getallTest_code(customer_id, patient_city,labid).subscribe((data) => {

            this.TestlistForDropdown = data.data;

            var thisData = [];
            this.selectrSingle.removeAll();
            data.data.forEach(item => {
                thisData.push(
                    { value: item.id, text: `${item.test_name} - ${item.test_code ? item.test_code : ''}` }
                )
            });
            this.selectrSingle.add(thisData);
            this.GetTotalAmount();
            this.priceInfo.payment_type.markAsUntouched();

        });
    }

    */
    getTestProvider(customer_id,provider_id, patient_city,labid) {

        this.OrderService.getallTest_codeByProvider(customer_id,provider_id, patient_city,labid).subscribe((data) => {

            this.test_dropdownList=data.data;
            this.GetTotalAmount();
            this.priceInfo.payment_type.markAsUntouched();

        });
    }

    onAddTest(): void {
        this.testform.markAllAsTouched();
        if (this.testform.invalid) {
            return;
        }
        if (this.testform.valid) {
            this.isTestBtnLoaded = true
            this.testform.get('order_id').setValue(this.orderId);
            if (this.isEditOrder) {
                this.testform.get('visit_id').setValue(this.visit_id);
                this.OrderService.postOrder(this.testform.value, 'save_order_patient_test_visit_new').subscribe(
                    (res: any) => {
                        this.resetTestForm();
                        this.refreshPatients();
                    },
                    (err: any) => {
                        this.toastr.error(`${err}`);
                        console.log(err);
                    },
                    () => {
                        this.isTestBtnLoaded = false;
                    }
                );
            }
            else {
                this.OrderService.postOrder(this.testform.value, 'save_order_patient_test_new_srl').subscribe(
                    (res: any) => {
                        this.resetTestForm();
                        this.refreshPatients();
                    },
                    (err: any) => {
                        this.toastr.error(`${err}`);
                        console.log(err);
                    },
                    () => {
                        this.isTestBtnLoaded = false;
                    }
                );
            }
        }

    }

    deleteTest(data: any) {
        const modalRef = this.modalService.open(deleteModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Delete this Test?`,
            subTitle: ``,
            type: 'order_test_delete_srl',
            page: 1,
            data: data,
            screenName: 'order_test_delete_srl'
        };

        modalRef.componentInstance.passEntry.subscribe((res: any) => {
            this.refreshPatients();
            this.resetTestForm();

        });
    }


    onAddPrice(isDiscount = 'null'): void {
        this.pricingform.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        if (this.pricingform.valid) {
            let fromData = this.pricingform.getRawValue();
            if (isDiscount == 'null') {
                this.isPriceBtnLoaded = true
            }

            if (this.orderId) {
                this.createOrder();

                this.OrderService.updateOrderSrl(fromData, this.orderId, 'update_order_amount').subscribe(
                    (res: any) => {
                        if (res.success) {

                            // this.router.navigate(['orders']);
                            if (isDiscount == 'null') {
                                this.router.navigateByUrl(`order-public-view/${res.data.sha_visit_id}`, {
                                    state: {
                                        form: 'view'
                                    }
                                });
                            }
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`not Updated`);
                            }
                        }
                    },
                    (err: any) => {
                        this.toastr.error(`${err}`);
                        console.log(err);
                    },
                    () => {
                        this.isPriceBtnLoaded = false;
                    }
                );
            } else {
                this.createOrder();
                this.OrderService.postOrder(this.pricingform.value, 'update_order_amount').subscribe(
                    (res: any) => {
                        if (res.success) {
                            this.orderId = res.data.id
                            console.log("mypadam---",res.data);
                            if (isDiscount == 'null') {
                                this.router.navigateByUrl(`order-public-view/${res.data.sha_visit_id}`, {
                                    state: {
                                        form: 'view'
                                    }
                                });
                            }
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`not added`);
                            }
                        }
                    },
                    (err: any) => {
                        this.toastr.error(`${err}`);
                        console.log(err);
                    },
                    () => {
                        this.isPriceBtnLoaded = false;
                    }
                );
            }
        }
    }

    removeDiscount(id) {
        console.log(id);

        let sendData = {
            "discount_type": null,
            "discount_value": null,
            "final_price": id.test_price,
        };

        this.OrderService.edit_patient_test(id.id, sendData).subscribe(
            (res: any) => {
                this.refreshPatients();
                this.toastr.success(`Discount Removed`);
            },
            (err: any) => {
                this.toastr.error(`${err}`);
                console.log(err);
            }
        );
    }

    setDiscount(data: any) {
        const modalRef = this.modalService.open(AddEditDiscountComponent, {
            centered: true,
            size: 'sm',
        });
        modalRef.componentInstance.props = {
            title: 'Set Discount',
            type: 'addedit',
            page: `1`,
            data: data
        }
        modalRef.componentInstance.passEntry.subscribe((res: any) => {
            this.refreshPatients()
        })
    }

    GetTotalAmount() {
        this.pricingform.controls['total_amount'].disable();
        if (this.visit_id) {
            if (this.visit_number != 'second') {
                this.OrderService.getTotalAmountNewSrl(this.visit_id).subscribe(
                    (data) => {
                        if(data.data.total_amount==null)
                        {
                            console.log(data.data.total_amount);
                            this.pricingform.get('total_amount').setValue(0);
                            this.totalTestOrderAmount =0;
                            //if (!this.isEditOrder) {
                                this.pricingform.get('total_adjusted_amount').setValue(0);
                           // }
                            this.calculatePayment(0, 'total')
                        }else{
                        this.redeem_amount=data.data.redeem_amount;
                        this.available_point=data.data.redeem_point;
                        this.additional_charges_msg=data.data.additional_charges_msg;

                        
                        this.pricingform.get('home_collection_charges').setValue(data.data.additional_charges);

                        this.pricingform.get('total_amount').setValue(data.data.total_amount.toFixed(2));
                        this.totalTestOrderAmount = data.data.total_amount.toFixed(2);
                        this.pricingform.get('total_adjusted_amount').setValue(data.data.total_amount.toFixed(2));
                        this.calculatePayment(data.data.total_amount.toFixed(2), 'total')
                        }
                    }
                );
            }
        }
    }

    calculatePayment(data, type) {
        let totalAdjustedAmount: number
        let advancePaid: number
        let homeCharge: number
        let grandTotal: number

        if (type == 'home') {

            homeCharge = data

            if (this.pricingform.value.advance_paid == undefined) {
                advancePaid = 0;
            } else {
                advancePaid = this.pricingform.value.advance_paid;
            }
            if (this.pricingform.value.total_adjusted_amount == undefined) {
                totalAdjustedAmount = 0;
            } else {
                totalAdjustedAmount = this.pricingform.value.total_adjusted_amount;
            }
        } else if (type == 'advance') {
            if (this.pricingform.value.home_collection_charges == undefined) {
                homeCharge = 0;
            } else {
                homeCharge = this.pricingform.value.home_collection_charges;
            }

            advancePaid = data;

            if (this.pricingform.value.total_adjusted_amount == undefined) {
                totalAdjustedAmount = 0;
            } else {
                totalAdjustedAmount = this.pricingform.value.total_adjusted_amount;
            }
        } else if (type == 'total') {

            if (this.pricingform.value.home_collection_charges == undefined) {
                homeCharge = 0;
            } else {
                homeCharge = this.pricingform.value.home_collection_charges;
            }

            if (this.pricingform.value.advance_paid == undefined) {
                advancePaid = 0;
            } else {
                advancePaid = this.pricingform.value.advance_paid;
            }

            totalAdjustedAmount = data;
        }else if (type == 'order') {

            if (this.pricingform.value.home_collection_charges == undefined) {
                homeCharge = 0;
            } else {
                homeCharge = this.pricingform.value.home_collection_charges;
            }

            if (this.pricingform.value.advance_paid == undefined) {
                advancePaid = 0;
            } else {
                advancePaid = this.pricingform.value.advance_paid;
            }

            if (this.pricingform.value.total_adjusted_amount == undefined) {
                totalAdjustedAmount = 0;
            } else {
                totalAdjustedAmount = this.pricingform.value.total_adjusted_amount;
            }
        }

        grandTotal = Number(totalAdjustedAmount) + Number(homeCharge);

        if (grandTotal >= 0) {
            //if (this.Visit1Test.length > 0) {
                this.pricingform.get('total_order_value').setValue(grandTotal.toFixed(2));

                var amount_to_be_collect = Number(grandTotal) - Number(advancePaid);

                if (this.pricingform.value.payment_type != 'Prepaid') {
                    if (amount_to_be_collect >= 0) {
                        this.pricingform.get('amount_to_be_collect').setValue(amount_to_be_collect.toFixed(2));
                    } else {
                        this.toastr.error(`Enter Valid Number`);
                    }
                }
            //} else {
                // this.pricingform.get('amount_to_be_collect').setValue('');
                // this.pricingform.get('total_order_value').setValue('');
                // this.pricingform.get('home_collection_charges').setValue('');
                // this.pricingform.get('advance_paid').setValue('');
                // this.pricingform.get('total_adjusted_amount').setValue('');
                // this.pricingform.get('payment_type').setValue('');
            //}
        } else {
            this.toastr.error(`Enter Valid Number`);
        }
    }

    changePaymentType(paymentType) {
        this.GetTotalAmount();
        if (paymentType == 'Prepaid') {
            this.pricingform.controls['advance_paid'].disable();
            this.pricingform.controls['amount_to_be_collect'].disable();
            this.pricingform.get('advance_paid').setValue('');
            this.pricingform.get('amount_to_be_collect').setValue('');
        } else {
            this.pricingform.controls['advance_paid'].enable();
            this.pricingform.get('amount_to_be_collect').setValue(this.totalTestOrderAmount);
            this.pricingform.get('total_adjusted_amount').setValue(this.totalTestOrderAmount);
        }
    }

    calculate(total: number, advance: number, discountType: any = null, discountAmount: number = 0) {
        if (discountType == 'Flat') {
            return total - discountAmount - advance;
        } else if (discountType == 'Percentage') {
            return (total - ((discountAmount / 100) * total)) - advance;
        } else {
            return total - advance;
        }

    }

    CalculateDiscountpayment(test, total) {
        let type = (<HTMLInputElement>document.getElementById('GetDiscountType' +test.visit_patient_id +'-'+ test.id)).value;
        let value = (<HTMLInputElement>document.getElementById('GetDiscountValue' +test.visit_patient_id +'-'+ test.id)).value;
       
        if (total) {
            if (type != 'null' && type != undefined) {
               
                if (value != '' && value != undefined) {
                    
                    var finalAmount = this.calculate(Number(total), 0, type, Number(value))

                    if (finalAmount >= 0) {
                        this.isLoadedTable = true;
                        this.thisTestID = test.id;
                        let sendData = {
                            "discount_type": type,
                            "discount_value": value,
                            "final_price": finalAmount.toFixed(2),
                        };
                        this.OrderService.edit_patient_test(this.thisTestID, sendData).subscribe(
                            (res: any) => {
                                this.onAddPrice('notNull')
                                this.refreshPatients();
                            },
                            (err: any) => {
                                this.toastr.error(`${err}`);
                                console.log(err);
                                this.isLoadedTable = false;
                            }
                        );

                    } else {
                        this.toastr.error(`Enter Valid Number`);
                    }
                } 

                if (value == '') {
                    var finalAmount = this.calculate(Number(total), 0, type, Number(0));

                    if (finalAmount >= 0) {
                        this.isLoadedTable = true;
                        this.thisTestID = test.id;
                        let sendData = {
                            "discount_type": type,
                            "discount_value": 0,
                            "final_price": finalAmount.toFixed(2),
                        };
                        this.OrderService.edit_patient_test(this.thisTestID, sendData).subscribe(
                            (res: any) => {
                                this.onAddPrice('notNull')
                                this.refreshPatients();
                            },
                            (err: any) => {
                                this.toastr.error(`${err}`);
                                console.log(err);
                                this.isLoadedTable = false;
                            }
                        );

                    } else {
                        this.toastr.error(`Enter Valid Number`);
                    }
                }

            } else {
                this.toastr.error(`Select Discount Type`);
            }
        } else {
            this.toastr.error(`Enter Valid Number`);
        }
    }

    
    checkRedeem() {
        if(this.check_redeem=="" || this.check_redeem==null)
        {
            this.toastr.error(`Please input points.`);
            return false;

        }
        if(this.visit_id ==undefined)
        {
            this.toastr.error(`Please add master info first.`);
          return false;
        }
        if(this.check_redeem >this.redeem_amount)
        {
            this.toastr.error('You can redeem maximum '+this.redeem_amount+' points');
          return false;
        }
        this.isCheckRedeemBtnLoaded=true;
        this.OrderService.checkRedeem(this.visit_id,this.check_redeem).subscribe(
            (res: any) => {
                if(res.success)
                {
                    this.isCheckRedeemBtnLoaded=false;
                this.toastr.success(`Confirmed. Otp sent to your mobile no.`);
                }
            },
            (err: any) => {
                this.toastr.error(`${err}`);
                console.log(err);
            }
        );
    }
    
    confirmRedeem() {
      
        if(this.visit_id ==undefined)
        {
            this.toastr.error(`Please add master info first.`);
          return false;
        }
        this.isConfirmRedeem=true;
        var patient_firstname=this.patientform.value.first_name ;
        var pSMSCode=this.confirm_otp;
        var pNetAmount=this.pricingform.value.total_amount ;
        
        console.log(this.searchMobilefilter+"---------"+patient_firstname,pSMSCode+"------------"+pNetAmount);

        this.OrderService.confirmRedeem(this.visit_id,this.searchMobilefilter,patient_firstname,pSMSCode,pNetAmount,this.redeem_amount).subscribe(
            (res: any) => {
                if(res.success)
                {
                    this.isConfirmRedeem=false;
                    
                   var advance_paid_data= this.pricingform.value.advance_paid
                   if(advance_paid_data=="")
                   {
                    advance_paid_data=0;
                   }
                   if(advance_paid_data==undefined)
                   {
                    advance_paid_data=0;
                   }
                    advance_paid_data=   advance_paid_data+Number(res.descount_amount)
                   
                    //data.data.total_amount.toFixed(2)
                    this.pricingform.get('advance_paid').setValue(advance_paid_data);

                this.toastr.success(`Discount is added to your Amount.`);
                }
            },
            (err: any) => {
                this.toastr.error(`${err}`);
                console.log(err);
            }
        );
    }
    resendOtp() {
        this.isResendBtnLoaded=true;
        console.log(this.searchMobilefilter);

        this.OrderService.resendOtp(this.searchMobilefilter).subscribe(
            (res: any) => {
                if(res.success)
                {
                    this.isResendBtnLoaded=false;
                this.toastr.success(`Otp sent to mobile no.`);
                }
            },
            (err: any) => {
                this.toastr.error(`${err}`);
                console.log(err);
            }
        );
    }
    confirmOtp() {
        if(this.confirm_otp=="")
        {
            this.toastr.error(`Please input otp.`);
            return false;

        }
        if(this.visit_id ==undefined)
        {
            this.toastr.error(`Please add master info first.`);
          return false;
        }
        this.isConfirmBtnLoaded=true;
       console.log(this.confirm_otp,this.searchMobilefilter);
       if(this.searchMobilefilter ==undefined)
       {
        this.searchMobilefilter=this.form.value.patient_phone ;
       }

        this.OrderService.confirmOtp(this.visit_id,this.confirm_otp,this.searchMobilefilter,this.redeem_amount).subscribe(
            (res: any) => {
                if(res.success)
                {
                    this.isConfirmBtnLoaded=false;
                   // this.pricingform.get('advance_paid').setValue(this.check_redeem);

                    var advance_paid_data= this.pricingform.value.advance_paid
                    if(advance_paid_data=="")
                    {
                     advance_paid_data=0;
                    }
                    if(advance_paid_data==undefined)
                    {
                     advance_paid_data=0;
                    }
                     advance_paid_data=   advance_paid_data+Number(this.check_redeem)
                    
                     //data.data.total_amount.toFixed(2)
                     this.pricingform.get('advance_paid').setValue(advance_paid_data);
 
                    this.GetTotalAmount();
                this.toastr.success(`Otp is Confirmed`);
                }else{
                    this.toastr.error(`OTP is not confirmed.`);
                    this.isConfirmBtnLoaded=false;
    
                }
            },
            (err: any) => {
                this.toastr.error(`OTP is not confirmed.`);
                this.isConfirmBtnLoaded=false;

            }
        );
    }
    
    validatePromocode() {
        if(this.promo_code=="")
        {
            this.toastr.error(`Please input promocode`);
          return false;
        }
        if(this.visit_id ==undefined)
        {
            this.toastr.error(`Please add master info first.`);
          return false;
        }
        this.isPromoBtnLoaded=true;
        
        let p_promocode=this.promo_code;
       
        this.OrderService.validatePromocode(p_promocode,this.visit_id).subscribe(
            (res: any) => {
                if(res.success)
                {
                    this.isPromoBtnLoaded=false;
                   
                this.toastr.success(`Promocode is valid`);
                }else{
                    this.toastr.error(`${res.error}`);
                    this.isPromoBtnLoaded=false;
    
                }
            },
            (err: any) => {
                this.toastr.error(`${err}`);
                console.log(err);
            }
        );
    }
    confirmPromocode() {
      
        if(this.promo_code=="")
        {
            this.toastr.error(`Please input promocode`);
          return false;
        }
        if(this.visit_id ==undefined)
        {
            this.toastr.error(`Please add master info first.`);
          return false;
        }
        this.confirmPromoBtnLoaded=true;

        this.OrderService.confirmPromocode(this.promo_code,this.visit_id).subscribe(
            (res: any) => {
                if(res.success)
                {
                    this.discount_per=res.data.DiscPercentage;

                    this.descount_amount=res.data.DiscPercentageAmount;

                    var advance_paid_data= this.pricingform.value.advance_paid
                    if(advance_paid_data=="")
                    {
                     advance_paid_data=0;
                    }
                    if(advance_paid_data==undefined)
                    {
                     advance_paid_data=0;
                    }
                     advance_paid_data=   advance_paid_data+Number(this.descount_amount)
                    
                     //data.data.total_amount.toFixed(2)
                     this.pricingform.get('advance_paid').setValue(advance_paid_data);
                     this.GetTotalAmount();
                     this.confirmPromoBtnLoaded=false;

                this.toastr.success(`Promocode discount is add to the amount.`);
                }else{
                    this.toastr.error(`${res.error}`);
                    this.confirmPromoBtnLoaded=false;
    
                }
            },
            (err: any) => {
                this.toastr.error(`Promocode is not Valid`);
                console.log(err);
                this.confirmPromoBtnLoaded=false;

            }
        );
    }
    cancelPromocode()
    {
        this.isPromoBtnLoaded=false;

        this.promo_code="";
        this.OrderService.cancelPromocode(this.visit_id).subscribe(
            (res: any) => {
                if(res.success)
                {

                    var descount_amount=res.data.promocode_amount;

                    var advance_paid_data= this.pricingform.value.advance_paid
                    if(advance_paid_data=="")
                    {
                     advance_paid_data=0;
                    }
                    if(advance_paid_data==undefined)
                    {
                     advance_paid_data=0;
                    }
                    if(advance_paid_data>0)
                    {
                     advance_paid_data=   advance_paid_data-Number(this.descount_amount)
                    }
                     this.pricingform.get('advance_paid').setValue(advance_paid_data);
                     this.GetTotalAmount();
                this.toastr.success(`Promocode discount is removed from the amount.`);
                }else{
                    this.toastr.error(`Promocode discount was not added.`);

                }
            },
            (err: any) => {
                this.toastr.error(`${err}`);
                console.log(err);
            }
        );

    }
    
}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { OrderService } from "src/app/services/order/order.service";
@Component({
    selector: "app-upsell-order",
    templateUrl: "./upsell-order.component.html",
    styleUrls: ["./upsell-order.component.scss"]
})
export class UpsellOrderComponent implements OnInit {


    public orderId: any;
    public viewOrderData: any;

    public fullpageLoad = true;



    constructor(
        private sanitizer: DomSanitizer,
        private OrderService: OrderService,
        private route: ActivatedRoute,
        private router: Router,
        

    ) { }
    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.orderId = params.get('id');
        });
        if (this.orderId) {
            this.OrderService.upSellOrder(this.orderId).subscribe(
                      
                (res: any) => {
                    if (res && res.success) {
                        console.log(res);
                        this.router.navigateByUrl('upsell-edit/'+res.data.order_id+'/'+res.data.visit_id);

                      
                    }
                }
            );
        }
    }
}

  
export const environment = {
  //staging
  production: false,
  BASE_URL: '',
  baseUrl: 'http://localhost:8000',
  apiendpoint: 'https://ms1k6xksge.execute-api.ap-south-1.amazonaws.com',
  SettingsApiendpoint: 'https://bkep2h6zg1.execute-api.ap-south-1.amazonaws.com',
  DosApiendpoint:'https://mee41rkjwh.execute-api.ap-south-1.amazonaws.com',
  CustomerApiendpoint:'https://mee41rkjwh.execute-api.ap-south-1.amazonaws.com',
  UserApiendpoint: 'https://ms1k6xksge.execute-api.ap-south-1.amazonaws.com',
  OrderApiendpoint:'https://okx75jxshg.execute-api.ap-south-1.amazonaws.com', 
  PlanApiendpoint:'https://22q9qm3lml.execute-api.ap-south-1.amazonaws.com/api',
  leaveApiendpoint:'https://mtqn0qowxc.execute-api.us-east-1.amazonaws.com', 
  collectionApiendpoint:'https://9z65zqaeu7.execute-api.us-east-1.amazonaws.com',
   phleboAppEndpoint:'https://h7hwz7bp4i.execute-api.ap-south-1.amazonaws.com',
   phleboAppEndpointUpload:'https://pathkind.sathiapis.gosamplify.com',
   labUserEndpoint : 'https://okx75jxshg.execute-api.ap-south-1.amazonaws.com',
  };

  // export const environment = {
  //   //live url
  //   production: false,
  //   BASE_URL: '',
  //   baseUrl: 'http://localhost:8000',
  //   apiendpoint: 'https://l180e0vhc1.execute-api.ap-south-1.amazonaws.com',
  //   OrderApiendpoint: 'https://v93wndp6if.execute-api.ap-south-1.amazonaws.com',
  //   CustomerApiendpoint: 'https://h1ll922xck.execute-api.ap-south-1.amazonaws.com',
  //   DosApiendpoint: 'https://h1ll922xck.execute-api.ap-south-1.amazonaws.com',
  //   UserApiendpoint: 'https://l180e0vhc1.execute-api.ap-south-1.amazonaws.com',
  //   SettingsApiendpoint: 'https://5ws1d7noc5.execute-api.ap-south-1.amazonaws.com',
  //   PlanApiendpoint:'https://jlbb1h5hvh.execute-api.ap-south-1.amazonaws.com/api',
  //  leaveApiendpoint:'https://mtqn0qowxc.execute-api.us-east-1.amazonaws.com',  
  //  collectionApiendpoint:'https://9z65zqaeu7.execute-api.us-east-1.amazonaws.com',
  //  phleboAppEndpoint:'https://daj4o1kuf0.execute-api.ap-south-1.amazonaws.com',
  //  phleboAppEndpointUpload:'https://pathkind.sathiapis.gosamplify.com',
  //  labUserEndpoint : 'https://v93wndp6if.execute-api.ap-south-1.amazonaws.com',
  // };
  


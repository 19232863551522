import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class DesignationService {

  constructor(
    private httpService:HttpService
  ) { }

  getAllDesignation(search:any){
    const url = `${environment.SettingsApiendpoint}/designation-get-all?${search}`;

    return this.httpService.get(url,this.httpService.headers);

  }

  addDesignation(data:any){
    const url = `${environment.SettingsApiendpoint}/designation-store`;

    return this.httpService.post(url,data,this.httpService.headers);
  }

  editDesignation(desigId:any){
    const url = `${environment.SettingsApiendpoint}/designation-edit/${desigId}`;

    return this.httpService.get(url,this.httpService.headers).pipe(catchError(this.ErrorHandling));
  }

  updateDesignation(data:any,DesigId:number){
    const url = `${environment.SettingsApiendpoint}/designation-update/${DesigId}`;

    return this.httpService.patch(url,data,this.httpService.headers).pipe(catchError(this.ErrorHandling));
  }

  deleteDesignation(DesigId:number){
    const url = `${environment.SettingsApiendpoint}/designation-delete/${DesigId}`;

    return this.httpService.delete(url,this.httpService.headers).pipe(catchError(this.ErrorHandling));
  }

  ErrorHandling(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
        console.error(err.error.message);
    } else {
        console.error(`Backend returned code ${err.status}`);
    }
    return throwError('Please try again later.');
}

}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocationStrategy } from '@angular/common';
import { OrderService } from "src/app/services/order/order.service";
import { TableModalComponent } from "src/app/components/TableModal/TableModal.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2'
import { deleteModalComponent } from "../components/deleteModal/deleteModal.component";


@Component({
    selector: "app-order-public-view",
    templateUrl: "./order-public-view.component.html",
    styleUrls: ["./order-public-view.component.scss"]
})
export class OrderPublicViewComponent implements OnInit {


    public orderId: any;
    public viewOrderData: any;

    ordertimeline: any;
    patient_list_visit2: any;
    patient_list_visit1: any;
    public patient_list: any;

    public fullpageLoad1 = true;
    public fullpageLoad2 = true;
    public fullpageLoad3 = true;
    public isBtnLoaded: boolean = false;
    visit_id: any;
    visit_number: any;
    visit_task_id: any;
    upsell: any;
    checksubmit: boolean = false;
    independent_phlebo_id: any;
    submit_to_ops: any;
    payment_link: any;



    constructor(
        private OrderService: OrderService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private ngbModal: NgbActiveModal,
        private location: LocationStrategy


    ) {
        
        history.pushState(null, null, window.location.href);  
       this.location.onPopState(() => {
         history.pushState(null, null, window.location.href);
       }); 
    
    }
    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.orderId = params.get('id');
        });
        if (this.orderId) {
            this.OrderService.getSingleOrderPublicData(this.orderId).subscribe((data) => {
                this.viewOrderData = data.data;
                this.visit_id = data.data.visit_id;
              

                setTimeout(() => {
                    this.fullpageLoad1 = false;
                }, 200);
            });


            this.OrderService.getPatientForTableByOrderPublic(this.orderId).subscribe((data) => {
                console.log("padam11",data.data);

                this.patient_list_visit1 = data.data.visit1;
                this.patient_list_visit2 = data.data.visit2;
                this.patient_list = data.data;
                this.visit_number = data.data.visit_number;
                this.visit_task_id = data.data.task_id;
                this.upsell = data.data.upsell;
                this.independent_phlebo_id = data.data.independent_phlebo_id;
                this.submit_to_ops = data.data.submit_to_ops;

                console.log("padam-=-=-",this.submit_to_ops);
                this.payment_link = data.data.payment_link;


                setTimeout(() => {
                    this.fullpageLoad2 = false;
                }, 200);
            });
        }
    }
 
    CreateOrderViewPDF() {
        this.OrderService.CreateOrderViewPDFPUBLIC(this.visit_id).subscribe(
            (data) => {
                window.open(data.data, "_blank");

            }
        );

    }
    openSpec(data) {
        data.displaytype = 'orderview';

        const modalRef = this.modalService.open(TableModalComponent, {
            centered: true,
            size: 'md',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Specimen List`,
            subTitle: ``,
            type: 'showSpecimen',
            page: 1,
            data: data,
            visit_number :this.visit_number

        };
    }
    delete() {
        const modalRef = this.modalService.open(deleteModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Delete this Visit?`,
            subTitle: ``,
            type: 'orderDelete',
            data: {
                id:this.visit_id,

              },
            screenName: 'delete_order'
        };
        modalRef.componentInstance.passEntry.subscribe((res: any) => {
            this.router.navigateByUrl(`order-public-view-delete/${this.visit_id}`, {
                state: {
                    form: 'view'
                }
            });
        });
    }

    submitToOPS(){
        this.OrderService.updateAmountParent(this.visit_id).subscribe(
            (data) => {            

            }
        );
    this.OrderService.submitToOps(this.visit_id).subscribe(
        (res: any) => {
            if (res && res.success) {
                this.checksubmit=res.success;
                this.router.navigateByUrl(`order-public-view-submit/${this.visit_id}`, {
                    state: {
                        form: 'view'
                    }
                });
              //  Swal.fire('Task submitted to OPS')

            } else {
                if (res.message) {
                    this.toastr.error(`${res.message}`);
                } else {
                    this.toastr.error(`Status change failed`);
                }
            }
            this.isBtnLoaded = false;
        },
        (err: any) => {
            this.toastr.error(`${err.statusText}`);
            console.log(err);
        }
    );
}

}

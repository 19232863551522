import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { deleteModalComponent } from "./deleteModal/deleteModal.component";
import { uploadModalComponent } from "./uploadModal/uploadModal.component";
import { exportModalComponent } from "./exportModal/exportModal.component";
import { enterModalComponent } from "./enterModal/enterModal.component";

import { LoaderComponent } from "./loader/loader.component";
import { VectorMapComponent1 } from "./vector-map/vector-map.component";

import { RouterModule } from "@angular/router";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { DxVectorMapModule, DxPieChartModule } from 'devextreme-angular';
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TableModalComponent } from "./TableModal/TableModal.component";
import { pageLoaderComponent } from "./pageLoader/pageLoader.component";
import { blockModalComponent } from "./blockModal/blockModal.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LeaveModalComponent } from "./leaveModal/leaveModal.component";
import { SelectDropDownModule } from 'ngx-select-dropdown';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CollapseModule.forRoot(),
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        DxVectorMapModule,
        DxPieChartModule,
        BsDatepickerModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        SelectDropDownModule
        

    ],
    declarations: [
        FooterComponent,
        VectorMapComponent1,
        NavbarComponent,
        SidebarComponent,
        deleteModalComponent,
        uploadModalComponent,
        TableModalComponent,
        exportModalComponent,
        LoaderComponent,
        pageLoaderComponent,
        blockModalComponent,
        LeaveModalComponent,
        enterModalComponent
    ],
    exports: [
        FooterComponent,
        VectorMapComponent1,
        NavbarComponent,
        LoaderComponent,
        pageLoaderComponent,
        deleteModalComponent,
        uploadModalComponent,
        TableModalComponent,
        exportModalComponent,
        SidebarComponent,
        blockModalComponent,
        enterModalComponent,
        LeaveModalComponent

    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class ComponentsModule { }

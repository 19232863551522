import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from "./app.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from './services/interceptor/token.interceptor';
import { RouterModule } from "@angular/router";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ToastrModule } from "ngx-toastr";
import { TagInputModule } from "ngx-chips";
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { BrowserModule } from '@angular/platform-browser';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

import { OrderPublicViewComponent } from './order-public-view/order-public-view.component';
import { InterceptorService } from "./services/loader/interceptor.service";
import { QRPaymentComponent } from "./qr-ayment/qr-payment.component";
import { CreateUpsellOrderComponent } from './create-upsell-order/create-upsell-order.component';

import { UpsellOrderComponent } from "./upsell-order/upsell-order.component";

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { publicComponent } from "./layouts/public/public.component";
import { secureComponent } from "./layouts/secure/secure.component";
import { authComponent } from "./layouts/auth/auth.component";
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
// import { GoogleChartsModule } from 'angular-google-charts';
import { OrderPublicViewSubmitComponent } from './order-public-view-submit/order-public-view-submit.component';
import { OrderPublicViewDeleteComponent } from './order-public-view-delete/order-public-view-delete.component';
import { IndependentUpsellOrderComponent } from "./independent-upsell-order/independent-upsell-order.component";
import { CreateSrlUpsellComponent } from './create-srl-upsell/create-srl-upsell.component';
import { UpsellOrderSrlComponent } from './upsell-order-srl/upsell-order-srl.component';
import { CashfreePageComponent } from './cashfree-page/cashfree-page.component';
import { TrackLinkComponent } from "./track-link/track-link/track-link.component";
import { ComponentsModule } from "./components/components.module";
import { FeedbackComponent } from './feedback/feedback.component';


@NgModule({
    declarations: [
        AppComponent,
        secureComponent,
        publicComponent,
        authComponent,
        QRPaymentComponent,
        OrderPublicViewComponent,
        CreateUpsellOrderComponent,
        UpsellOrderComponent,
        ForgotpasswordComponent,
        ResetpasswordComponent,
        OrderPublicViewSubmitComponent,
        OrderPublicViewDeleteComponent,
        IndependentUpsellOrderComponent,
        CreateSrlUpsellComponent,
        UpsellOrderSrlComponent,
        CashfreePageComponent,
        TrackLinkComponent,
        FeedbackComponent,
        
    ],
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        ComponentsModule,
        ToastrModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        TagInputModule,
        BrowserModule,
        AppRoutingModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgbModule
        
        


    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
        DatePipe,
        NgbActiveModal,

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpErrorResponse, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpService } from '../http/http.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class CustomerdosService {

    constructor(private httpService: HttpService, private http: HttpClient) { }
    
    getallLabDos(data: any) {

        const url = `${environment.CustomerApiendpoint}/get-all-labs-dos?${data}`;
        console.log(url);
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getallDos(data: any) {

        const url = `${environment.CustomerApiendpoint}/get-all-customer-dos?${data}`;
        console.log(url);
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getNotImportedDosPriceList() {
        const url = `${environment.CustomerApiendpoint}/get-not-imported-dos-lab-price`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getNotImportedOrderList() {
        const url = `${environment.CustomerApiendpoint}/get-not-imported-dos`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
     
    getexportFileList() {
        const url = `${environment.CustomerApiendpoint}/get-export-filelist`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    getallDosFilterData(data: any) {

        const url = `${environment.CustomerApiendpoint}/get-all-customer-dos?${data}`;
        console.log(url);
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    getallcity() {

        const url = `${environment.CustomerApiendpoint}/get-citys`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    getallCustomer() {

        const url = `${environment.CustomerApiendpoint}/get-customers`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getSingleCustomer(data) {

        const url = `${environment.CustomerApiendpoint}/get-single-customers/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    editCustomerDos(data: any, id:any): Observable<any> {
        const url = `${environment.CustomerApiendpoint}/customers/${id}?t=dos`;
        return this.httpService.patch(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    getSingleDosData(data: any) {
        const url = `${environment.CustomerApiendpoint}/dos-view/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    deleteDos(id: any) {
        const url = `${environment.CustomerApiendpoint}/dos-delete/${id}`;
        console.log(url);
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    postCustomerDos(data: any) {

        const url = `${environment.CustomerApiendpoint}/add-dos?t=dos`
        return this.httpService.post(url, data, this.httpService.headers)
    }
    updateStatus(id: any, status: any): Observable<any> {
        const url = `${environment.CustomerApiendpoint}/dos-status/${id}/${status}`
        return this.httpService.put(url, {}, this.httpService.headers)
    }
    downloadDos(data: any) {
        const url = `${environment.CustomerApiendpoint}/export-dos`;
       
        return this.httpService.post(url, data, this.httpService.headers);

    }
    uploadFile(data:any, imagefile: File) {
        const url = `${environment.CustomerApiendpoint}/import-dos`;
        const formData = new FormData();

        formData.append('file', imagefile);
          

        return this.httpService
            .post(url, formData, this.httpService.headers)
            .pipe(catchError(this.Errorhandling));
    }
    uploadFileDosPrice(data:any, imagefile: File) {
        const url = `${environment.CustomerApiendpoint}/import-dos-price`;
        const formData = new FormData();

        formData.append('file', imagefile);
          

        return this.httpService
            .post(url, formData, this.httpService.headers)
            .pipe(catchError(this.Errorhandling));
    }
    Errorhandling(err: HttpErrorResponse) {
        if (err.error instanceof ErrorEvent) {
            console.error(err.error.message);
        } else {
            console.error(`Backend returned code ${err.status}`);
        }
        return throwError('Please try again later.');
    }
}

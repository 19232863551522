import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-order-public-view-submit',
  templateUrl: './order-public-view-submit.component.html',
  styleUrls: ['./order-public-view-submit.component.scss']
})
export class OrderPublicViewSubmitComponent implements OnInit {

  constructor(
    private location: LocationStrategy

  ) { 
     history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    
  }

}

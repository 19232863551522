import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustomerdosService } from "src/app/services/customerdos/customerdos.service";
import { SettingService } from 'src/app/services/setting/setting.service';
import { AuthenticationService } from "src/app/services/auth/authentication.service";
import { FormControl, FormGroup, Validators, FormBuilder, FormArray, RequiredValidator } from '@angular/forms';
import { Router } from "@angular/router";
import { CustomersService } from 'src/app/services/customer/customer.service';
import { cityService } from 'src/app/services/city/city.service';
import { DatePipe } from '@angular/common';
import Selectr from "mobius1-selectr";
import { OrderService } from 'src/app/services/order/order.service';

@Component({
    selector: "app-blockModal",
    templateUrl: "./blockModal.component.html",
    styleUrls: ["./blockModal.component.scss"]
})
export class blockModalComponent implements OnInit {
    @Input() props: any;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    public title: string;
    public subTitle: string;

    public type: string;
    public data: any;
    public page: any = 1;

    public isBtnLoaded: boolean = false;
    public blockTouchSubmit = false;
    public blockForm: FormGroup;
    public partnerForm: FormGroup;
    public optionsMultipleCity = {
        multiple: true,
        placeholder: 'City',
        data: []
    };
    public optionsMultipleareacode = {
        multiple: true,
        placeholder: 'Areacode',
        data: []
    };

    
    customer_id: any
    showError: boolean;
    userType: any;
    login_customer_id: any;
    customerDropDown: any;
    citylistForDropdown: any;
    selectorMultiple: Selectr;

    constructor(
        private ngbModal: NgbActiveModal,
        private toastr: ToastrService,
        private customerdosService: CustomerdosService,
        private settingService: SettingService,
        private authService: AuthenticationService,
        private formBuilder: FormBuilder,
        private router: Router,
        private cityService: cityService,
        private customerService: CustomersService,
        public datepipe: DatePipe,
        private orderService: OrderService,

        


    ) { 
        this.userType= this.authService.user.roles.user_type_id;
        this.login_customer_id= this.authService.user.customer_id;

    }

    ngOnInit(): void {
       
        this.setDialogProps(this.props);


        this.customerService.getallCustomers().subscribe((data) => {
            this.customerDropDown = data.data;
           
        });
       
     
        this.blockForm = this.formBuilder.group({
            customer_id: ['',Validators.required],
            all_cities: [''],
            all_areacode: [''],
            block_date: ['',Validators.required],
        });
        
        this.partnerForm = this.formBuilder.group({
            partner_code: ['',Validators.required],
            partner_name: ['',Validators.required],
            id: [''],
        });
       
        if(this.type =="addBlockCustomer" ){
        this.cityService.getallcityandId().subscribe((data) => {
            var selectrmultiple: any = document.getElementById("selectr-multiple-city");
            this.selectorMultiple = new Selectr(selectrmultiple, this.optionsMultipleCity);

         //   this.customerlistForDropdown = data.data;
            let thisData = [];
            this.selectorMultiple.removeAll();
            thisData.push(
                { value: 0, text: `All` }
            )
            data.data.forEach(item => {
                thisData.push(
                    { value: item.id, text: `${item.city_name}` }
                )
            });
            this.selectorMultiple.add(thisData);

        });
        this.orderService.getallAreaCode(0).subscribe((data) => {
            var selectrmultiple: any = document.getElementById("selectr-multiple-areacode");
            this.selectorMultiple = new Selectr(selectrmultiple, this.optionsMultipleareacode);

         //   this.customerlistForDropdown = data.data;
            let thisData = [];
            this.selectorMultiple.removeAll();
            thisData.push(
                { value: 0, text: `All` }
            )
            data.data.forEach(item => {
                thisData.push(
                    { value: item.id, text: `${item.areacode_name}` }
                )
            });
            this.selectorMultiple.add(thisData);

        });
    }
      // 
       
//this.getAllCity()

       // }
         if(this.type =="editPartner")
         {

            this.partnerForm.get('partner_code').setValue( this.data.provider_code );
            this.partnerForm.get('partner_name').setValue( this.data.provider_name );
            this.partnerForm.get('id').setValue( this.data.id );


         }
    }
    
    get blockInfo() {
        return this.blockForm.controls;
    }
    get partnerInfo() {
        return this.partnerForm.controls;
    }
    setDialogProps(dialogdata: any) {
        this.title = dialogdata.title;
        this.subTitle = dialogdata.subTitle;
        this.type = dialogdata.type;
        this.data = dialogdata.data;
        this.page = dialogdata.page;
    }

    onClose() {
        this.passEntry.next(this.page);

        this.ngbModal.close();

    }
    onCloseNext() {
        this.passEntry.next(this.page);

        this.ngbModal.close();


    }
    
    getAllCity()
    {
        
  this.cityService.getallcityandId().subscribe((data) => {

 
  
 //   this.customerlistForDropdown = data.data;
    let thisData = [];
    this.selectorMultiple.removeAll();
    thisData.push(
        { value: 0, text: `All` }
    )
    data.data.forEach(item => {
        thisData.push(
            { value: item.id, text: `${item.city_name}` }
        )
    });
    this.selectorMultiple.add(thisData);

  });

    }
    onUpdatePartner() {
        switch (this.type) {
            case 'editPartner':
               

                if (this.partnerForm.valid) {


                    this.showError = false;
                    this.isBtnLoaded = true;
                    

                        this.settingService.editPartner(this.partnerForm.value).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                    this.toastr.success(`${res.message}`);
                                  //  this.router.navigateByUrl('customerblock');
                              this.onClose();

                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`submit failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                   
                } else {
                    this.partnerForm.markAllAsTouched();

                }
                break;
            
        }
    }
    onSavePartner() {
           switch (this.type) {
               case 'addPartner':
                  
   
                   if (this.partnerForm.valid) {
   
   
                       this.showError = false;
                       this.isBtnLoaded = true;
                       
   
                           this.settingService.savePartner(this.partnerForm.value).subscribe(
                               (res: any) => {
                                   if (res && res.success) {
                                       this.toastr.success(`${res.message}`);
                                     //  this.router.navigateByUrl('customerblock');
                                 this.onClose();
   
                                   } else {
                                       if (res.message) {
                                           this.toastr.error(`${res.message}`);
                                       } else {
                                           this.toastr.error(`submit failed`);
                                       }
                                   }
                                   this.isBtnLoaded = false;
                               },
                               (err: any) => {
                                   this.onClose();
                                   this.toastr.error(`${err.statusText}`);
                                   console.log(err);
                               }
                           );
   
                      
                   } else {
                       this.partnerForm.markAllAsTouched();
   
                   }
                   break;
               
           }
       }
    onSaveBlock() {

        switch (this.type) {
            case 'addBlockCustomer':
               

                if (this.blockForm.valid) {

                 //   this.blockForm.get('visit_id').setValue(this.data.id);

                    this.showError = false;
                    this.isBtnLoaded = true;

                        this.settingService.saveBlock(this.blockForm.value).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                    this.toastr.success(`${res.message}`);
                                  //  this.router.navigateByUrl('customerblock');
                              this.onClose();

                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`submit failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                   
                } else {
                    this.blockForm.markAllAsTouched();

                }
                break;
            
        }
    }
}

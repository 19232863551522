import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustomerdosService } from "src/app/services/customerdos/customerdos.service";
import { SettingService } from 'src/app/services/setting/setting.service';
import { OrderService } from "src/app/services/order/order.service";
import { AuthenticationService } from "src/app/services/auth/authentication.service";
import { CustomersService } from 'src/app/services/customer/customer.service';

@Component({
    selector: "app-uploadModal",
    templateUrl: "./uploadModal.component.html",
    styleUrls: ["./uploadModal.component.scss"]
})
export class uploadModalComponent implements OnInit {
    @Input() props: any;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    public title: string;
    public subTitle: string;

    public type: string;
    public data: any;
    public isCustomerDropdown: any;
    public page: any = 1;

    public isBtnLoaded: boolean = false;
    public file: any;
    public fileName: any;
    public fileerrmsg: boolean;
    public fromData: any;
    public customerlistForDropdown: any;
    public isRejectedUpload: any;
    customer_id: any
    showError: boolean;
    SampleFileUrl: any;
    userType: any;
    login_customer_id: any;
    providerListForDropdown: any;
    isAggregator: boolean=false;
    provider_id: any=0;

    constructor(
        private ngbModal: NgbActiveModal,
        private toastr: ToastrService,
        private customerdosService: CustomerdosService,
        private settingService: SettingService,
        private orderService: OrderService,
        private authService: AuthenticationService,
        private customerService: CustomersService,

    ) { 
        this.userType= this.authService.user.roles.user_type_id;
        this.login_customer_id= this.authService.user.customer_id;
        
    }

    ngOnInit(): void {
        this.setDialogProps(this.props);
        console.log(this.data);
        this.customerdosService.getallCustomer().subscribe((data) => {
            this.customerlistForDropdown = data.data;
        });
      

        
        if(this.userType==1 && this.type=='uploadCustomerDos')
        {
           // this.fromData = { customer_id: ,provider_id:0 }
          //  this.getProvidersData(this.login_customer_id);
            this.fromData = { customer_id:this.login_customer_id ,provider_id:0 }

        }

    }

  
    getProvidersData(myvalue){
        this.customerService.getAggregatorProvider(myvalue).subscribe((data) => {
            this.providerListForDropdown = data.data;
        });

        this.customerdosService.getSingleCustomer(myvalue).subscribe((data) => {
            if(data.data.is_aggregator=="true")
            {
                this.isAggregator =true;
            }else{
                this.isAggregator =false;

            }
        });

       
    }
    setDialogProps(dialogdata: any) {
        this.title = dialogdata.title;
        this.subTitle = dialogdata.subTitle;
        this.type = dialogdata.type;
        this.data = dialogdata.data;
        this.page = dialogdata.page;
        this.customer_id = dialogdata.customer_id
        this.isCustomerDropdown = dialogdata.isCustomerDropdown;
        this.SampleFileUrl = dialogdata.SampleFileUrl;
    }

    onClose() {
        this.passEntry.next(this.isRejectedUpload);
        this.ngbModal.close();
    }

    onFileSelect(event) {
        if (event.target.files.length > 0) {
            const image = event.target.files[0];
            this.fileName = image.name;
            this.file = image;
            this.fileerrmsg = false;
        }
    }

    selectOption(id: any) {
        this.customer_id=id;
        this.fromData = { customer_id: this.customer_id,provider_id:this.provider_id }
        this.getProvidersData(id);

    }
    selectProviderOption(id: any) {
       
        if(this.userType==1)
        {
            this.fromData = { customer_id:this.login_customer_id ,provider_id:id }

        }else{
            this.fromData = { customer_id: this.customer_id,provider_id:id }
       

        }
    }
    
    onFileUpload() {

        switch (this.type) {
            case 'uploadLabs':
                this.isBtnLoaded = true;
                if (this.file) {
                    this.orderService.uploadLabs(this.file).subscribe(
                        (res: any) => {
                            this.isRejectedUpload=res;
                            if (res && res.success) {
                                this.toastr.success(`${res.message}`);
                                this.onClose();
                            } else {
                                if (res.message) {
                                    this.toastr.error(`${res.message}`);
                                } else {
                                    this.toastr.error(`Upload failed`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        },
                        () => {
                            this.onClose();
                            this.isBtnLoaded = false;
                        }
                    );
                } else {
                    this.fileerrmsg = true;
                    console.log("File not selected")
                }
                break;
                case 'uploadCC':
                    this.isBtnLoaded = true;
                    if (this.file) {
                        this.orderService.uploadCC(this.file).subscribe(
                            (res: any) => {
                                this.isRejectedUpload=res;
                                if (res && res.success) {
                                    this.toastr.success(`${res.message}`);
                                    this.onClose();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`Upload failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            },
                            () => {
                                this.onClose();
                                this.isBtnLoaded = false;
                            }
                        );
                    } else {
                        this.fileerrmsg = true;
                        console.log("File not selected")
                    }
                    break;
                    
            case 'uploadLabDosPrice':
              this.fromData = { customer_id: 1,provider_id:0 }

                if (this.fromData) {
                  
                   
                    this.showError = false;
                    this.isBtnLoaded = true;
                    console.log(this.fromData);
                    if (this.file) {
                        console.log(this.file);
                        this.customerdosService.uploadFileDosPrice(this.fromData, this.file).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                    this.toastr.success(`${res.message}`);
                                    this.onClose();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`Upload failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                    } else {
                        this.fileerrmsg = true;
                        console.log("File not selected")
                    }
                } else {
                    this.showError = true;
                }
                break;
            case 'uploadCustomerDos_2':
               if (this.fromData==undefined) {
                this.fromData = { customer_id: this.customer_id,provider_id:this.provider_id }
            }
                    this.showError = false;
                    this.isBtnLoaded = true;
                    if (this.file) {
                        this.customerdosService.uploadFile(this.fromData, this.file).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                    this.toastr.success(`${res.message}`);
                                    this.onClose();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`Upload failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                    } else {
                        this.fileerrmsg = true;
                        console.log("File not selected")
                    }
                // } else {
                //     this.showError = true;
                // }
                break;
            case 'uploadOrders':
                this.isBtnLoaded = true;
                if (this.file) {
                    this.orderService.uploadOrders(this.file).subscribe(
                        (res: any) => {
                            this.isRejectedUpload=res;
                            if (res && res.success) {
                                this.toastr.success(`${res.message}`);
                                this.onClose();
                            } else {
                                if (res.message) {
                                    this.toastr.error(`${res.message}`);
                                } else {
                                    this.toastr.error(`Upload failed`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        },
                        () => {
                            this.onClose();
                            this.isBtnLoaded = false;
                        }
                    );
                } else {
                    this.fileerrmsg = true;
                    console.log("File not selected")
                }
                break;
                case 'uploadCustomerDos':
                if (this.fromData) {
                    this.showError = false;
                    this.isBtnLoaded = true;
                    if (this.file) {
                        this.customerdosService.uploadFile(this.fromData, this.file).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                    this.toastr.success(`${res.message}`);
                                    this.onClose();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`Upload failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                    } else {
                        this.fileerrmsg = true;
                        console.log("File not selected")
                    }
                } else {
                    this.showError = true;
                }
                break;
                case 'uploadCityMapping':
                    this.isBtnLoaded = true;
                    if (this.file) {
                        this.settingService.uploadCityCode(this.file).subscribe(
                            (res: any) => {
                                this.isRejectedUpload=res;
                                if (res && res.success) {
                                    this.toastr.success(`${res.message}`);
                                    this.onClose();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`Upload failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            },
                            () => {
                                this.onClose();
                                this.isBtnLoaded = false;
                            }
                        );
                    } else {
                        this.fileerrmsg = true;
                        console.log("File not selected")
                    }
                    break;
        }
    }
}

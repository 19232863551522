import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { OrderService } from "src/app/services/order/order.service";
@Component({
    selector: "app-qr-payment",
    templateUrl: "./qr-payment.component.html",
    styleUrls: ["./qr-payment.component.scss"]
})
export class QRPaymentComponent implements OnInit {


    public orderId: any;
    public viewOrderData: any;

    public fullpageLoad = true;



    constructor(
        private sanitizer: DomSanitizer,
        private OrderService: OrderService,
        private route: ActivatedRoute,

    ) { }
    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.orderId = params.get('id');
        });
        if (this.orderId) {
            this.OrderService.paymentqr(this.orderId).subscribe(
                (data) => {
                    if(data.data){
                        if(data.data.methodType=="razorpay"){
                        this.viewOrderData = data.data.image;  
                    }else
                        {
                           
                            this.viewOrderData = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.data.image}`);

                        }
                    }else{
                        this.viewOrderData = '';
                    }
                },
                (err) => {
                    console.log(err);
                    
                },
                () => {
                    setTimeout(() => {
                        this.fullpageLoad = false;
                    }, 200);
                },
            );
        }
    }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttendenceService {

  constructor(
    private httpService:HttpService
  ) { }

  getAttendence(data:any){
    const url =`${environment.SettingsApiendpoint}/attendence-get-all?${data}`;

    return this.httpService.get(url,this.httpService.headers);
  }

  exportAttendence(data:any){
    const url =`${environment.OrderApiendpoint}/export-attendence?${data}`;

    return this.httpService.get(url,this.httpService.headers);
  }
  getexportFileList() {
    const url = `${environment.SettingsApiendpoint}/get-export-filelist`;
    return this.httpService.get(url, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
}
  
  markEmpAttendence(formData:any,EmpIds:any,date:any){
    const url = `${environment.SettingsApiendpoint}/mark-employee-attendence/${EmpIds}/${date}`;

    return this.httpService.post(url,formData,this.httpService.headers);
  }
  markMultiEmpAttendence(formData:any,EmpIds:any){
    const url = `${environment.SettingsApiendpoint}/mark-multi-employee-attendence`;

    return this.httpService.post(url,{"form_data":formData,"employee":EmpIds},this.httpService.headers);
  }
  
  checkWeekOffEmployee(attendence,date,emp) {
    const url = `${environment.SettingsApiendpoint}/check-week-off`;

   return this.httpService.post(url,{"attendence":attendence,"date":date,"employee":emp},this.httpService.headers);
  }
  checkPresentEmployee(date1,date2,emp) {
    const url = `${environment.SettingsApiendpoint}/check-present-employee`;

   return this.httpService.post(url,{"date1":date1,"date2":date2,"employee":emp},this.httpService.headers);
  }

  
  markMultiEmpLeave(formData:any,EmpIds:any){
    const url = `${environment.SettingsApiendpoint}/mark-multi-employee-leave`;

    return this.httpService.post(url,{"form_data":formData,"employee":EmpIds},this.httpService.headers);
  }
  
  Errorhandling(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      
        console.error(err.error.message);
    } else {
      
        console.error(`Backend returned code ${err.status}`);
    }
    return throwError('Please try again later.');
}
}

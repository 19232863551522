import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { OrderService } from 'src/app/services/order/order.service';
declare const google: any;
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-track-link',
  templateUrl: './track-link.component.html',
  styleUrls: ['./track-link.component.scss']
})
export class TrackLinkComponent implements OnInit {

  checkExisit: boolean;
  task_id: any;
  public viewTaskDetail: any=[];
  public directionsRenderer: any;
  public directionsService: any;
  map: any;
  task_number: any;
  runner: any;
  last_name: any;
  first_name: any;
  runner_id: string;
  gpsData: any;
  public start_lat: any;
  public start_long: any;
  
  public end_lat: any;
  public end_long: any;
  orderId: string;
  viewOrderData: any;
  viewOrderDataSpeciman: any;
  slotTime:any;
  formattedDateTime:any;
     public isRouteDataNull: boolean = false;
     public Checking: boolean = false;

  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private OrderService: OrderService,
    public datepipe: DatePipe,


  ) { 
  
  
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.orderId = params.get('id');
    });
   

    console.log("this is order id :", this.orderId);
    if (this.orderId) {
      try {
        const visitData = await this.OrderService.getSingleVisitData(this.orderId).toPromise();
        this.viewOrderData = visitData.data;
        this.viewOrderDataSpeciman = visitData.data.visitBarcodeData;
        this.slotTime = this.viewOrderData.slot_date;
        console.log("padam koli-------------", this.viewOrderData);

        const gpsData = await this.OrderService.getPhlebogps(this.orderId).toPromise();
        let routeData = gpsData?.data;
        console.log("ropute data :", routeData)
        if (routeData) {
          for (const point of routeData) {
              if (!point.lat || !point.long) {
                  this.isRouteDataNull = true;
                  break;
              }
          }
      }

      console.log("Is Route Data Null:", this.isRouteDataNull);

        const [slotDate, slotTime] = this.extractDateAndTime(this.viewOrderData.slot_date, this.viewOrderData.slot_time);

        const formattedDate = this.datepipe.transform(slotDate, 'EEE MMM dd yyyy');
       this.formattedDateTime = formattedDate + ' ' + slotTime;
    
    
    
        
        // Default latitude and longitude values
        const defaultLat = 19.0435207;
        const defaultLng = 72.8488199;

        
        // Parse the latitude and longitude or use default values if they are empty or NaN
        const centerLat = routeData && routeData[0] && routeData[0]?.lat ? parseInt(routeData[0]?.lat) : defaultLat;
        
        const centerLng = routeData && routeData[0] && routeData[0]?.long ? parseInt(routeData[0]?.long) : defaultLng;
        
        // Create the map
        const map = new google.maps.Map(
          document.getElementById("map-custom") as HTMLElement,
          {
            zoom: 9,
            center: { lat: centerLat, lng: centerLng },
          }
        );

        
        this.drawRoute(routeData, map);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error if necessary
      }
    }
  }

  extractDateAndTime(slotDate: string, slotTime: string): [Date, string] {
    const dateParts = slotDate.split(" ")[0]; 
    const timeParts = slotTime.split("-")[0]; 
    const extractedDate = new Date(dateParts);
    return [extractedDate, timeParts];
}

isSlotActive(): { isActive: boolean, timeToShowMap: Date, timeDifference: number } {
  const slotTime = new Date(this.formattedDateTime); 
  const currentTime = new Date();
  const timeDifference = slotTime.getTime() - currentTime.getTime();
  const minutesDifference = timeDifference / (1000 * 60);
  const isActive = minutesDifference <= 30; 
  const timeToShowMap = new Date(currentTime.getTime() + (isActive ? timeDifference : 0));


  return { isActive, timeToShowMap, timeDifference };
}




  
  
    

  drawRoute(cordinates:any=[],map:any)
  {
    console.log("amm--",cordinates);

  const directionsService = new google.maps.DirectionsService();
  const directionsRenderer = new google.maps.DirectionsRenderer( {
    suppressMarkers : true
    });
 
  directionsRenderer.setMap(map);

 // const waypts: google.maps.DirectionsWaypoint[] = [];
  let waypts:any=[];

  let start_lat='';
  let start_long='';
  let end_lat='';
  let end_long='';
let i=0;
var marker;
var infowindow = new google.maps.InfoWindow();
let finalcords=[];
finalcords=cordinates;


finalcords.forEach(function (value) {
  console.log("amm--11",cordinates);

  let iconData="";

  if(i==0)
  {
    iconData="assets/icons/bhome.png";
    start_lat=value.lat;
    start_long=value.long;
  }
  if(i==(finalcords.length-1))
  {
    iconData="assets/icons/bflag.png";
    end_lat=value.lat;
    end_long=value.long;
  }

 
const geocoder = new google.maps.Geocoder();


       waypts.push({
         location: new google.maps.LatLng(value.lat,value.long),
         stopover:false
     }) 
     //infowindow.open(map, marker);
    // infowindow.setContent("Runner reached");
   
   

    marker = new google.maps.Marker({
      position: new google.maps.LatLng(value.lat, value.long),
      map: map,
      icon:iconData,
    
    
    });
   
     i++;
   })   
   directionsService
     .route({
       origin: new google.maps.LatLng(start_lat,start_long),
       destination: new google.maps.LatLng(end_lat,end_long),
       waypoints: waypts,
       optimizeWaypoints: true,
       travelMode: google.maps.TravelMode.DRIVING,
     })
     .then((response) => {
       directionsRenderer.setDirections(response);
 
 
       
     })
     .catch((e) => console.log("Directions request failed due to " + status));

//var infowindow = new google.maps.InfoWindow();




    }




}


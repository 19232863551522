import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { OrderPublicViewComponent } from "./order-public-view/order-public-view.component";
import { QRPaymentComponent } from "./qr-ayment/qr-payment.component";
import { UpsellOrderComponent } from "./upsell-order/upsell-order.component";
import { CreateUpsellOrderComponent } from "./create-upsell-order/create-upsell-order.component";

import { publicComponent } from "./layouts/public/public.component";
import { secureComponent } from "./layouts/secure/secure.component";
import { authComponent } from "./layouts/auth/auth.component";
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { OrderPublicViewSubmitComponent } from "./order-public-view-submit/order-public-view-submit.component";
import { OrderPublicViewDeleteComponent } from "./order-public-view-delete/order-public-view-delete.component";
import { IndependentUpsellOrderComponent } from "./independent-upsell-order/independent-upsell-order.component";
import { CreateSrlUpsellComponent } from "./create-srl-upsell/create-srl-upsell.component";
import { UpsellOrderSrlComponent } from "./upsell-order-srl/upsell-order-srl.component";
import { CashfreePageComponent } from "./cashfree-page/cashfree-page.component";
import { TrackLinkComponent } from "./track-link/track-link/track-link.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { AutoLoginComponent } from "./pages/auto-login/auto-login.component";


const routes: Routes = [
  // otherwise redirect to Login
  {
    path: '',
    component: authComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'forgot-password',
        component: ForgotpasswordComponent,
      },
      {
        path: 'reset-password/:id/:password_token',
        component: ResetpasswordComponent,
      },
      {
        path: 'a/orders/:phone_no/:lab_id',
        loadChildren: () =>
          import('./pages/auto-login/auto-login.module').then((m) => m.AutoLoginModule),
      },
    ],
  },
  // App routes goes here here
  {
    path: '',
    component: secureComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
          
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./pages/orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./pages/customers/customers.module').then(
            (m) => m.CustomersModule
          ),
      },
      {
        path: 'roles',
        loadChildren: () =>
          import('./pages/roles/roles.module').then((m) => m.RolesModule),
      },
      {
        path: 'phlebos',
        loadChildren: () =>
          import('./pages/phlebos/phlebos.module').then((m) => m.PhlebosModule),
      },
      {
        path: 'plans',
        loadChildren: () =>
          import('./pages/plan/plan.module').then((m) => m.PlanModule),
      },
      {
        path: 'leaves',
        loadChildren: () =>
          import('./pages/leaves/leaves.module').then((m) => m.LeavesModule),
      },
      {
        path: 'customerdos',
        loadChildren: () =>
          import('./pages/customerdos/customerdos.module').then(
            (m) => m.CustomerdosModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/setting/setting.module').then((m) => m.SettingModule),
      },
      {
        path: 'avslots',
        loadChildren: () =>
          import('./pages/avslots/avslots.module').then((m) => m.AvslotsModule),
      },
      {
        path: 'patients',
        loadChildren: () =>
          import('./pages/patients/patients.module').then((m) => m.PatientsModule),
      },

      {
        path: 'myaccount',
        loadChildren: () =>
          import('./pages/myaccount/myaccount.module').then(
            (m) => m.MyaccountModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/paymentcheck/paymentcheck.module').then((m) => m.PaymentcheckModule),
      },
     
      {
        path: 'invoice-payment',
        loadChildren: () =>
          import('./pages/invoice/invoice.module').then((m) => m.InvoiceModule),
      },
      {
        path: 'bankdeposit',
        loadChildren: () =>
          import('./pages/bankdeposit/bankdeposit.module').then((m) => m.BankdepositModule),
      },
      {
        path: 'camp',
        loadChildren: () =>
          import('./pages/camp/camp.module').then((m) => m.CampModule),
      },
      {
        path: 'doctor',
        loadChildren: () =>
          import('./pages/organization-doctor/organization-doctor.module').then((m) => m.OrganizationDoctorModule),
      },
      {
        path: 'attendance',
        loadChildren: () =>
          import('./pages/attendence/attendence.module').then((m) => m.AttendenceModule),
      },
      
    ],
  },
  //Public routes goes here
  {
    path: '',
    component: publicComponent,
    children: [
      {
        path: 'trl/:id',
        pathMatch: 'full',
        component: TrackLinkComponent,
      },
      {
        path: 'order-public-view/:id',
        pathMatch: 'full',
        component: OrderPublicViewComponent,
      },
      {
        path: 'order-public-view-submit/:id',
        pathMatch: 'full',
        component: OrderPublicViewSubmitComponent,
      },
      {
        path: 'order-public-view-delete/:id',
        pathMatch: 'full',
        component: OrderPublicViewDeleteComponent,
      },
      {
        path: 'qr-payment/:id',
        pathMatch: 'full',
        component: QRPaymentComponent,
      },
      {
        path: 'upsell-order/:id',
        pathMatch: 'full',
        component: UpsellOrderComponent,
      },
      {
        path: 'upsell-edit/:id/:visit_id',
        pathMatch: 'full',
        component: CreateUpsellOrderComponent,
      },
    
      {
        path: 'upsell-order-srl/:phleboid',
        pathMatch: 'full',
        component: CreateSrlUpsellComponent,
      },
      {
        path: 'order-srl/:sha_id',//order-srl/01fa0187d0416cc64046229abdadc811e68b1a89
        pathMatch: 'full',
        component: CreateSrlUpsellComponent,
      },
      {
        path: 'independent-upsell-order/:phleboid',
        pathMatch: 'full',
        component: IndependentUpsellOrderComponent,
      },
      {
        path: 'cashfreepage/:id',
        pathMatch: 'full',
        component: CashfreePageComponent,
      },
	  {
        path: 'feedback/:id',
        pathMatch: 'full',
        component: FeedbackComponent,
      },
    ],
  },
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }

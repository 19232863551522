import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth/authentication.service";

var misc: any = {
    sidebar_mini_active: true
};

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    isCollapsed?: boolean;
    isCollapsing?: any;
    children?: ChildrenItems[];
    gardName?: string;
}

export interface ChildrenItems {
    path: string;
    title: string;
    type?: string;
    collapse?: string;
    isCollapsing?: any;
    children?: ChildrenItems2[];
    isCollapsed?: boolean;
}

export interface ChildrenItems2 {
    path: string;
    title: string;
    type?: string;
    collapse?: string;
    isCollapsing?: any;
    children?: ChildrenItems2[];
    isCollapsed?: boolean;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: "/dashboard",
        title: "Dashboard",
        type: "link",
        icontype: "ni-shop  text-warning",
        gardName: "dashboard-view"
    },
    {
        path: "/orders",
        title: "Orders",
        type: "link",
        icontype: "ni-briefcase-24 text-warning",
        gardName: "orders-view"
    },
    //    { 
    //         path: "",
    //         title: "Orders",
    //         type: "sub",
    //         icontype: "ni-briefcase-24 text-warning",
    //         collapse: "entity",
    //         isCollapsed: true,
    //         gardName: "orders-view",
    //         children: [
    //             { path: "orders", title: "Orders", type: "link" ,collapse: "orders-view"},
    //             { path: "plans", title: "Plan", type: "link",collapse: "phlebos-view"},
    //             { path: "phleboview", title: "Payment Reconciliation", type: "link",collapse: "reconcile-view"},
    //             { path: "bankdeposit", title: "Bank Deposit", type: "link", collapse: "reconcile-view"},
    //             { path: "invoice-payment", title: "Invoice and Payment", type: "link", collapse: "invoice-and-payment-view"},

    //         ],


    //     },
    {
        path: "/phlebos",
        title: "Phlebos",
        type: "link",
        icontype: "ni-briefcase-24 text-warning",
        gardName: "phlebos-view"
    },
    {
        path: "/users",
        title: "Users",
        type: "link",
        icontype: "ni-briefcase-24 text-warning",
        gardName: "setting-view"
    },
   /* {
        path: "",
        title: "People Management",
        type: "sub",
        icontype: "ni-chart-pie-35 text-warning",
        collapse: "entity",
        isCollapsed: true,
        gardName: "phlebos-view",
        children: [
            { path: "phlebos", title: "Phlebos", type: "link", collapse: "phlebos-view" },
            // {
            //     path: "", title: "Attendance", type: "sub", collapse: "attendance-view", isCollapsed: true,
            //     children: [
            //         { path: "attendance/employee", title: 'Employees', type: 'link', collapse: "attendance-view" },
            //         { path: "attendance/attendance-management", title: 'Attendance', type: 'link', collapse: "attendance-view" },
            //         { path: "attendance/client-management", title: 'Clients', type: 'link', collapse: "attendance-view" },
            //         { path: "attendance/designations-management", title: 'Designations', type: 'link', collapse: "attendance-view" },

            //     ]
            // },
            { path: "users", title: "Users", type: "link", collapse: "users-view" },

        ],

    },
    */
    // {
    //     path: "",
    //     title: "Customer Management",
    //     type: "sub",
    //     icontype: "ni-archive-2 text-warning",
    //     collapse: "transactions",
    //     isCollapsed: true,
    //     gardName: "customer-dos-view",
    //     children: [
    //         { path: "customers", title: "Customers", type: "link", collapse: "customers-view" },
    //         { path: "customerdos", title: "Customer DOS", type: "link", collapse: "customer-dos-view" },
    //         { path: "avslots", title: "Available slots", type: "link", collapse: "slots-view" },

    //     ],

    // },

   /* {
        path: "settings/distance-reports",
        title: "Distance Report",
        type: "link",
        icontype: "ni-user-run text-warning",
        gardName: "setting-view"
    },*/
    // {
    //     path: "camp",
    //     title: "Camps",
    //     type: "link",
    //     icontype: "ni-money-coins  text-warning",
    //     gardName: "reconcile-view"
    // },


    // {
    //     path: "/patients",
    //     title: "Patients",
    //     type: "link",
    //     icontype: "ni-archive-2  text-warning ",
    //     gardName: "patients-view"
    // },
    {
        path: "/myaccount/viewprofile",
        title: "My Account",
        type: "link",
        icontype: "ni-satisfied  text-warning",
        gardName: "my-account-view"

    },
    {
        path: "orders/feedback",
        title: "Patient Feedback",
        type: "link",
        icontype: "ni-briefcase-24 text-warning",
        gardName: "setting-view"
    },
    {
        path: "/settings",
        title: "Setting",
        type: "sub",
        icontype: "ni-settings text-warning",
        collapse: "setting",
        isCollapsed: true,
        gardName: "setting-view",
        children: [
            { path: "lab-locations", title: "Labs and Collection Centers", type: "link", collapse: "setting-view" },
           // { path: "seviceable-pincodes", title: "Serviceable Pincodes", type: "link", collapse: "setting-view" },
            { path: "dos", title: "Dos Master", type: "link", collapse: "setting-view" },
            { path: "dos_lab", title: "Lab Dos Mapping", type: "link", collapse: "setting-view" },

            // { path: "masterdos", title: "Master Dos", type: "link", collapse: "setting-view" },
            { path: "cities", title: "City", type: "link", collapse: "setting-view" },
           // { path: "areacode", title: "Areacode", type: "link", collapse: "setting-view" },
            { path: "pincode", title: "Pincode", type: "link", collapse: "setting-view" },
            // { path: "customerblock", title: "Block Order", type: "link", collapse: "setting-view" },
            // { path: "partners", title: "Aggregator Partner Labs", type: "link", collapse: "setting-view" },
            // { path: "city-client-master", title: "City Client Master", type: "link", collapse: "setting-view" },
            { path: "labUser", title: "Lab User Mapping", type: "link", collapse: "setting-view" },
        ]
    },


];

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public isCollapsed = true;

    constructor(private authService: AuthenticationService, private router: Router) { }

    ngOnInit() {
        let Filteritem = [];

        let subFilterItem = [];
        ROUTES.filter(item => {

            let child = [];
            if (item.children) {
                item.children.map(perdata => {
                    this.authService.permissions.map(per => {

                        if (per == perdata.collapse) {

                            child.push(perdata);
                        }
                    });

                }
                );

            }

            item.children = child;

            subFilterItem.push(item);
        });

        subFilterItem.filter(item => {

            this.authService.permissions.map(per => {

                if (per == item.gardName) {


                    Filteritem.push(item);
                }
            });
        });
        if (this.authService.user.customer_id == 42) {
            let data = {
                path: "doctor",
                title: "Doctor & Organization",
                type: "link",
                icontype: "ni-money-coins  text-warning",
                gardName: "customers-view"
            }
            Filteritem.push(data);
        }
        this.menuItems = Filteritem;

        this.router.events.subscribe(event => {
            this.isCollapsed = true;
        });
    }
    onMouseEnterSidenav() {
        if (!document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.add("g-sidenav-show");
        }
    }
    onMouseLeaveSidenav() {
        if (!document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
        }
    }
    minimizeSidebar() {
        const sidenavToggler = document.getElementsByClassName(
            "sidenav-toggler"
        )[0];
        const body = document.getElementsByTagName("body")[0];
        if (body.classList.contains("g-sidenav-pinned")) {
            misc.sidebar_mini_active = true;
        } else {
            misc.sidebar_mini_active = false;
        }
        if (misc.sidebar_mini_active === true) {
            body.classList.remove("g-sidenav-pinned");
            body.classList.add("g-sidenav-hidden");
            sidenavToggler.classList.remove("active");
            misc.sidebar_mini_active = false;
        } else {
            body.classList.add("g-sidenav-pinned");
            body.classList.remove("g-sidenav-hidden");
            sidenavToggler.classList.add("active");
            misc.sidebar_mini_active = true;
        }
    }
}

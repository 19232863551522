import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustomerdosService } from "src/app/services/customerdos/customerdos.service";
import { SettingService } from 'src/app/services/setting/setting.service';
import { OrderService } from "src/app/services/order/order.service";
import { AuthenticationService } from "src/app/services/auth/authentication.service";
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { InvoiceService } from 'src/app/services/invoice/invoice.service';
import { PhleboService } from 'src/app/services/phlebo/phlebo.service';
import { PlanService } from 'src/app/services/plan/plan.service';
import { AttendenceService } from 'src/app/services/attendence/attendence.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import * as moment from 'moment';
import { deleteModalComponent } from '../deleteModal/deleteModal.component';


@Component({
    selector: "app-enterModal",
    templateUrl: "./enterModal.component.html",
    styleUrls: ["./enterModal.component.scss"]
})
export class enterModalComponent implements OnInit {
    @Input() props: any;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    public title: string;
    public subTitle: string;
    public photoUrl:any ='';

    public type: string;
    public data: any;
    public page: any = 1;
    public minDate: Date;

    public isBtnLoaded: boolean = false;
    public isBtnLoded: boolean = false;

    orderData: any;
    gfg1 = {hour: 15, minute: 58};
    todaydate: Date;
    showAcceptActions: boolean = false;
    availablePincode: any;
    islab: any=0;

    public orderCancel = this.authService.permissions.includes('order-cancel');
    public orderReschedule = this.authService.permissions.includes('order-reschedule');
    public phleboAssign = this.authService.permissions.includes('phlebo-assign');

    phleboDropDown: any;
    submittoopsForm: FormGroup;


    customer_id: any
    showError: boolean;
    userType: any;
    login_customer_id: any;
    public declineForm: FormGroup;
    cancellForm: FormGroup;
    reasonDropDown: any;
    reconsileForm: FormGroup;
    rescheduledReasonForm: FormGroup;
    formatteDate: any;

    inputtype: string;
    markCollectedForm: FormGroup;
    markSampleDeliveredForm: FormGroup;
    public collectedDate :any;
    public collectedTime :any;
    mydate: string;
    checkdate: number;
    exportFileList: any;
    Form: FormGroup;
    cashDepositTo: FormGroup;
    changeLocationForm!:FormGroup;
    editPhleboPlanForm:FormGroup;
    visitAddressForm:FormGroup;
    public slotlistForDropdown = [];

    
    rosterTouchSubmit: boolean=false;
    phleboTouchSubmit :boolean = false;
    public nextSaveBtnLoding:boolean = false;
    rosterForm: FormGroup;
    rosterFormRunner: FormGroup;
    lastWorkingDayForm:FormGroup;
    lastWorkingFormTouch:boolean = false
    markAttendenceForm:FormGroup;
    markAttendenceTouch:boolean = false;
    reasonHidden:boolean = false;
    dateData:any;
    kmdisplay: boolean= false;checkweekof: boolean;
    weekofData: any;
    checkPresent: boolean = true;
    date: any;
    checkPresentData: any=0;
    today: any;

    updateLastworkingDay:boolean = false;
    nextSaveBtnLodingPopup: boolean= false;
    sample_barcode: any=null;
    testPrice: any=null;


    constructor(
        private ngbModal: NgbActiveModal,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private customerdosService: CustomerdosService,
        private settingService: SettingService,
        private orderService: OrderService,
        private authService: AuthenticationService,
        private formBuilder: FormBuilder,
        private router: Router,
        public datepipe: DatePipe,
        public invoiceService : InvoiceService,
        public phleboService:PhleboService,
        public planService:PlanService,
        private employeeService:EmployeeService,
        private attendenceService:AttendenceService


    ) { 
        this.userType= this.authService.user.roles.user_type_id;
        this.login_customer_id= this.authService.user.customer_id;
        this.today="";
        this.minDate = new Date();

        this.islab= this.authService.user.lab?.lab_category;

    }

    ngOnInit(): void {
        this.setDialogProps(this.props);
        if(this.type=='markAttendence')
        {
            let current_time = moment().format("HH:mm")


            var GivenDate =this.dateData+" "+current_time;
            var CurrentDate = new Date();

          let  GivenDate1 = new Date(GivenDate);
          console.log(GivenDate1+"=================>================"+ CurrentDate);
            if(CurrentDate>= GivenDate1  ){
                this.checkPresent=true;
            }else{
                this.checkPresent=false;
            }
           
        
        }
        console.log(this.type);
    
       
        this.orderData = this.data;
        console.log("new data :", this.orderData);
      

        console.log(this.type);
       if(this.type =="cancelOrder" || this.type =="rescheduleOrder" )
       {

        this.orderService.getallreason().subscribe((data) => {
            console.log("padam",this.type);
            this.reasonDropDown = data.data;
            console.log("amit",data);
           
        });
    }
       if(this.type =="rescheduledReason" )
       {

        this.orderService.getallreason().subscribe((data) => {
            console.log("Anmol",this.type);
            this.reasonDropDown = data.data;
            console.log("Anmol",data);
           
        });


       }
       this.submittoopsForm = this.formBuilder.group({
        phlebo: ['', Validators.required],
        phlebotype: ['without_phlebo'],


    });

       if(this.type !="cancelOrder" &&  this.type != "showPhoto")
       {
       this.orderService.getAllPhleboList(this.data).subscribe(
        (res: any) => {
            this.phleboDropDown = res.data;
            
                    },
        (err: any) => {
            this.onClose();
            this.toastr.error(`${err.statusText}`);
            console.log(err);
        },
        () => {
            // this.isLoadedTable = false;
        }
    );
       }
       if(this.type == "showPhoto"){
        this.photoUrl = this.data;
    }

// for rescheduledReason 
this.rescheduledReasonForm = this.formBuilder.group({
    reason:  ['',Validators.required],
    visit_id: [''],
    date: ['', Validators.required],
    slot:['', Validators.required]

})
        this.declineForm = this.formBuilder.group({
            comment: [''],
            visit_id: [''],
        });
        this.cashDepositTo = this.formBuilder.group({
            deposittype: ['',Validators.required],
            
        });
        
      
        this.cancellForm = this.formBuilder.group({
            reason:  ['',Validators.required],
            visit_id: [''],
        });
        this.reconsileForm = this.formBuilder.group({
            reconcile_comment:  ['',Validators.required],
            reconcile_amount:  [''],
            visitId: [''],
        });

        this.Form = this.formBuilder.group({
            id: [''],
            discount : ['']
        });

        this.changeLocationForm = this.formBuilder.group({
            start_point:['',Validators.required],
            start_lat:['',Validators.required],
            start_long:['',Validators.required],
            end_point:['',Validators.required],
            end_lat:['',Validators.required],
            end_long:['',Validators.required],
        })
        
        this.editPhleboPlanForm = this.formBuilder.group({
            starting_point:['',Validators.required],
            start_lat:['',Validators.required],
            start_long:['',Validators.required],
            end_point:['',Validators.required],
            end_lat:['',Validators.required],
            end_long:['',Validators.required],
            start_time:['',Validators.required],
            end_time:['',Validators.required],
            plan_id:['',Validators.required],
            phlebo_id:['',Validators.required],

        })
        this.visitAddressForm = this.formBuilder.group({
            patient_address:['',Validators.required],          
            lat:['',Validators.required],
            lng:['',Validators.required],
             id:['',Validators.required],

        })
        this.rosterForm = this.formBuilder.group({
            customer_id:['1'],
            phlebo_id:[''],
            check_mon: [''],
            start_mon:[''],
            end_mon:[''],
            check_tue: [''],
            start_tue:[''],
            end_tue:[''],
            check_wed: [''],
            start_wed:[''],
            end_wed:[''],
            check_thu: [''],
            start_thu:[''],
            end_thu:[''],
            check_fri: [''],
            start_fri:[''],
            end_fri:[''],
            check_sat: [''],
            start_sat:[''],
            end_sat:[''],
            check_sun: [''],
            start_sun:[''],
            end_sun:[''],
  
        });
        
        this.editPhleboPlanForm = this.formBuilder.group({
            starting_point:['',Validators.required],
            start_lat:['',Validators.required],
            start_long:['',Validators.required],
            end_point:['',Validators.required],
            end_lat:['',Validators.required],
            end_long:['',Validators.required],
            start_time:['',Validators.required],
            end_time:['',Validators.required],
            plan_id:['',Validators.required],
            phlebo_id:['',Validators.required],

        })
        this.lastWorkingDayForm = this.formBuilder.group({
            reason:['',[Validators.required]],
            last_working_day:['',[Validators.required]]
        })

        this.markAttendenceForm = this.formBuilder.group({
            attendence:[''],
            kms:[''],
            from_date: [''], 
            to_date : ['']     

        });
        if(this.data.rosters)
        {
          
        if(this.data.rosters.length>0)
        {
            for (let index = 0; index <this.data.rosters.length; index++) {
  
  
            if(this.data.rosters[index].day=="Mon")
            {
            this.rosterForm.get('check_mon').setValue(true);
            this.rosterForm.get('start_mon').setValue(this.data.rosters[index].start_time);
            this.rosterForm.get('end_mon').setValue(this.data.rosters[index].end_time);
            }
            if(this.data.rosters[index].day=="Tue")
            {
            this.rosterForm.get('check_tue').setValue(true);
            this.rosterForm.get('start_tue').setValue(this.data.rosters[index].start_time);
            this.rosterForm.get('end_tue').setValue(this.data.rosters[index].end_time);
            }
            if(this.data.rosters[index].day=="Wed")
            {
            this.rosterForm.get('check_wed').setValue(true);
            this.rosterForm.get('start_wed').setValue(this.data.rosters[index].start_time);
            this.rosterForm.get('end_wed').setValue(this.data.rosters[index].end_time);
            }
            if(this.data.rosters[index].day=="Thu")
            {
            this.rosterForm.get('check_thu').setValue(true);
            this.rosterForm.get('start_thu').setValue(this.data.rosters[index].start_time);
            this.rosterForm.get('end_thu').setValue(this.data.rosters[index].end_time);
            }
            if(this.data.rosters[index].day=="Fri")
            {
            this.rosterForm.get('check_fri').setValue(true);
            this.rosterForm.get('start_fri').setValue(this.data.rosters[index].start_time);
            this.rosterForm.get('end_fri').setValue(this.data.rosters[index].end_time);
            }
            if(this.data.rosters[index].day=="Sat")
            {
            this.rosterForm.get('check_sat').setValue(true);
            this.rosterForm.get('start_sat').setValue(this.data.rosters[index].start_time);
            this.rosterForm.get('end_sat').setValue(this.data.rosters[index].end_time);
            }
            if(this.data.rosters[index].day=="Sun")
            {
            this.rosterForm.get('check_sun').setValue(true);
            this.rosterForm.get('start_sun').setValue(this.data.rosters[index].start_time);
            this.rosterForm.get('end_sun').setValue(this.data.rosters[index].end_time);
            }
        }
        }
    }
  
  //-------------------runner------------------------
  
  
    this.rosterFormRunner = this.formBuilder.group({
      client_id:['1'],
      runner_id:[''],
      check_mon: [''],
      start_mon:[''],
      end_mon:[''],
      check_tue: [''],
      start_tue:[''],
      end_tue:[''],
      check_wed: [''],
      start_wed:[''],
      end_wed:[''],
      check_thu: [''],
      start_thu:[''],
      end_thu:[''],
      check_fri: [''],
      start_fri:[''],
      end_fri:[''],
      check_sat: [''],
      start_sat:[''],
      end_sat:[''],
      check_sun: [''],
      start_sun:[''],
      end_sun:[''],
  
  });
  if(this.type == 'lastWorkingDay'){
    this.employeeService.obtainEmployee(this.data).subscribe((res: any) => {
        if(res.data.last_working_day && res.data.reason){
            this.lastWorkingDayForm = res.data.last_working_day;
            this.updateLastworkingDay = true;
            if (res.data.last_working_day) {
              res.data.last_working_day = this.datepipe.transform(res.data.last_working_day, 'd-MM-YYYY')
            } else {
              res.data.last_working_day = ''
            }
            this.lastWorkingDayForm = this.formBuilder.group({
                last_working_day :[res.data.last_working_day],
                reason : [res.data.reason]
            });
         
        }  
    });   
}

  if(this.data.rosters)
  {
  if(this.data.rosters.length>0)
  {
      for (let index = 0; index<this.data.rosters.length; index++) {
  
  
      if(this.data.rosters[index].day=="mon")
      {
      this.rosterFormRunner.get('check_mon').setValue(true);
      this.rosterFormRunner.get('start_mon').setValue(this.data.rosters[index].start_time);
      this.rosterFormRunner.get('end_mon').setValue(this.data.rosters[index].end_time);
      }
      if(this.data.rosters[index].day=="tue")
      {
      this.rosterFormRunner.get('check_tue').setValue(true);
      this.rosterFormRunner.get('start_tue').setValue(this.data.rosters[index].start_time);
      this.rosterFormRunner.get('end_tue').setValue(this.data.rosters[index].end_time);
      }
      if(this.data.rosters[index].day=="wed")
      {
      this.rosterFormRunner.get('check_wed').setValue(true);
      this.rosterFormRunner.get('start_wed').setValue(this.data.rosters[index].start_time);
      this.rosterFormRunner.get('end_wed').setValue(this.data.rosters[index].end_time);
      }
      if(this.data.rosters[index].day=="thu")
      {
      this.rosterFormRunner.get('check_thu').setValue(true);
      this.rosterFormRunner.get('start_thu').setValue(this.data.rosters[index].start_time);
      this.rosterFormRunner.get('end_thu').setValue(this.data.rosters[index].end_time);
      }
      if(this.data.rosters[index].day=="fri")
      {
      this.rosterFormRunner.get('check_fri').setValue(true);
      this.rosterFormRunner.get('start_fri').setValue(this.data.rosters[index].start_time);
      this.rosterFormRunner.get('end_fri').setValue(this.data.rosters[index].end_time);
      }
      if(this.data.rosters[index].day=="sat")
      {
      this.rosterFormRunner.get('check_sat').setValue(true);
      this.rosterFormRunner.get('start_sat').setValue(this.data.rosters[index].start_time);
      this.rosterFormRunner.get('endatabypatientidd_sat').setValue(this.data.rosters[index].end_time);
      }
      if(this.data.rosters[index].day=="sun")
      {
      this.rosterFormRunner.get('check_sun').setValue(true);
      this.rosterFormRunner.get('start_sun').setValue(this.data.rosters[index].start_time);
      this.rosterFormRunner.get('end_sun').setValue(this.data.rosters[index].end_time);
      }
  }
  }
  }
  
  
  
        
        if(this.type == "changelocation"){
            console.log(this.data.start_point+"Chandan");
            this.changeLocationForm.patchValue(this.data);
        }
        if(this.type == "editPhleboPlan"){
            this.editPhleboPlanForm.patchValue(this.data.phlebo);
            this.editPhleboPlanForm.patchValue(this.data);
        }
        if(this.type == "editvisitLatLong"){
            this.visitAddressForm.patchValue(this.data);
        }

        if(this.data.reconcile_comment=="Full amount collected")
        {
            this.inputtype="0";
            this.reconsileForm.get('reconcile_comment').setValue(this.data.reconcile_comment);

        }else if(this.data.reconcile_comment=="Partially collected (test/patient cancelled)")
        {
            this.inputtype="1";
            this.reconsileForm.get('reconcile_comment').setValue(this.data.reconcile_comment);

        }else if(this.data.reconcile_comment=="Paid online/Static QR")
        {
            this.inputtype="1";
            this.reconsileForm.get('reconcile_comment').setValue(this.data.reconcile_comment);

        }else if(this.data.reconcile_comment=="Partially collected (other reasons)")
        {
            this.inputtype="1";
            this.reconsileForm.get('reconcile_comment').setValue(this.data.reconcile_comment);

        }else if(this.data.reconcile_comment=="Paid directly to client")
        {
            this.inputtype="0";
            this.reconsileForm.get('reconcile_comment').setValue(this.data.reconcile_comment);

        }else{
            this.inputtype="0";
            this.reconsileForm.get('reconcile_comment').setValue("");


        }
        
        this.reconsileForm.get('reconcile_amount').setValue(this.data.reconcile_amount);
   
    }
    
    get phleboplanInfo() {
        return this.editPhleboPlanForm.controls;
    }
    get declineInfo() {
        return this.declineForm.controls;
    }
    get depositInfo() {
        return this.cashDepositTo.controls;
    }
    get reasonInfo() {
        return this.cancellForm.controls;
    }
    get reconsileInfo() {
        return this.reconsileForm.controls;
    }
    get collectedInfo() {
        return this.markCollectedForm.controls;
    }
    setDialogProps(dialogdata: any) {
        this.title = dialogdata.title;
        this.subTitle = dialogdata.subTitle;
        this.type = dialogdata.type;
        this.data = dialogdata.data;
        this.page = dialogdata.page;
        if(dialogdata.date){
            this.dateData = dialogdata.date;
        }
    }

    onClose() {
        this.type = '';
        this.passEntry.next(this.page);
        this.ngbModal.close();
    }
    getModalContentStyles() {
        if (this.type === 'showPhoto') {
          return {
           
          };
        }
        return {};
      }
    getAllSlot(data: any) {
        console.log("get all -", data.pincode.pincode, "city - ", data.city.id);
    
        // Check if the date is not empty
        if (this.rescheduledReasonForm.value.date != '') {
            let requestData = {
                date: '',
                customer_id: '',
                patient_pincode: '',
                patient_city: ''
            };
    
            // Format the date if it's an object
            if (typeof (this.rescheduledReasonForm.value.date) == 'object') {
                requestData.date = this.datepipe.transform(this.rescheduledReasonForm.value.date, 'YYYY-MM-d HH:MM:ss');
            } else {
                requestData.date = this.rescheduledReasonForm.value.date;
            }
    
            // Assign other values
            requestData.customer_id = data.customer_id;
            requestData.patient_pincode = data.pincode.pincode;
            requestData.patient_city = data.city.id;
    
            // Call the API with the constructed requestData
            this.orderService.getallSlot(requestData).subscribe((data) => {
                this.slotlistForDropdown = data.data;
                if (data.message) {
                    this.toastr.success(`${data.message}`);
                } else {
                    this.toastr.success(`Slot Found`);
                }
            }, (err) => {
                if (err.message) {
                    this.toastr.error(`${err.message}`);
                } else {
                    this.toastr.error(`not Found`);
                }
            });
        }
    }
    

       

    onCloseNext() {
      //  this.router.navigateByUrl('orders');
       // this.router.navigate([currentUrl]);
        // window.location.reload();
        this.passEntry.next(this.page);
        this.ngbModal.close();
    }
    
    onAcceptNext() {
        this.isBtnLoded = true;

        this.orderService.acceptRejectOrderByCC('accepted',this.orderData.id).subscribe(
            (res: any) => {
                if (res && res.success) {
                    this.orderData.is_accept_bycc = 'accepted';
                
                    this.isBtnLoded = false;

                   } else {
                       if (res.message) {
                           this.toastr.error(`${res.message}`);
                       } else {
                           this.toastr.error(`submit failed`);
                       }
                   }
                   this.isBtnLoded = true;
                   this.showAcceptActions = true;

            },
            (err: any) => {
                this.onClose();
                this.toastr.error(`${err.statusText}`);
                console.log(err);
            }
        );
     }

     checkSubmitOps() {
        if (this.orderData.status == 'Declined' || this.orderData.status == 'Collected'||this.orderData.status == 'Enroute To Lab'||this.orderData.status == 'Dropped In Lab'||this.orderData.status == 'Cancelled') {
            return false;
        } else {
            return true;
        }
    }

     onRejectNext() {
       
        this.orderService.acceptRejectOrderByCC('rejected',this.data.id).subscribe(
            (res: any) => {
                if (res && res.success) {
                    this.router.navigateByUrl('orders');

                       this.toastr.success(`${res.message}`);
                       this.onCloseNext();
                   } else {
                       if (res.message) {
                           this.toastr.error(`${res.message}`);
                       } else {
                           this.toastr.error(`submit failed`);
                       }
                   }
                   this.isBtnLoaded = false;
                   this.showAcceptActions = false;

            },
            (err: any) => {
                this.onClose();
                this.toastr.error(`${err.statusText}`);
                console.log(err);
            }
        );
     }
    onCloseNextPopup() {
    
         this.passEntry.next(this.page);
       //  this.ngbModal.close();
     }
    
    UserTypeCheck(mytype){
        console.log(mytype);
        this.inputtype="0";
        if(mytype=="Partially collected (test/patient cancelled)")
        {
            this.inputtype="1";
        }else if(mytype=="Paid online/Static QR")
        {
            this.inputtype="1";
        }else if(mytype=="Partially collected (other reasons)")
        {
            this.inputtype="1";
        }else{
            this.inputtype="0";
        }
    }
    get f()  {
        return this.submittoopsForm.controls;
    }

    get phleboInfo() {
        return this.changeLocationForm.controls;
    }
    public checkweekOff()
    {
       if(this.markAttendenceForm.value.attendence==1)
       {
        let value = (<HTMLInputElement>document.getElementById('myattendencetext')).value;

        this.attendenceService.checkWeekOffEmployee(this.markAttendenceForm.value.attendence,value ,this.data).subscribe((res: any) => {
        if(res.data.length!=0){
            this.weekofData=res.data;
          
           this.checkweekof=true;  
   
        } else
        {
          this.checkweekof=false;  
        } 
    });
    }else{
        this.checkweekof=false;  

    }


    }

    checkReschedule() {
        let curr_date =this.datepipe.transform(this.todaydate, 'yyyy-MM-dd');
        let slot_date =this.datepipe.transform(this.orderData.slot_date, 'yyyy-MM-dd');
       
        let curr_time =this.datepipe.transform(this.todaydate, 'yyyy-MM-dd H:mm');
        var  myhr=this.orderData.slot_time.split(":")[0];
        var valid=myhr-2;
        var slottime=valid+":00"
        let slot_time =this.datepipe.transform(this.todaydate, 'yyyy-MM-dd '+slottime);

        if(curr_time > slot_time &&  this.userType==1)
        {
            return false;

        }
//&& curr_date==slot_date
        if (this.orderData.status !='Cancelled') {
            return true;
        } else {
            return false;
        }
    }

    checkCancel() {

        let curr_date =this.datepipe.transform(this.todaydate, 'yyyy-MM-dd');
        let slot_date =this.datepipe.transform(this.orderData.slot_date, 'yyyy-MM-dd');
        let curr_time =this.datepipe.transform(this.todaydate, 'yyyy-MM-dd H:mm');
        var  myhr=this.orderData.slot_time.split(":")[0];
        var valid=myhr-1;
        var slottime=valid+":00"
        let slot_time =this.datepipe.transform(this.todaydate, 'yyyy-MM-dd '+slottime);
/*
        if(curr_time > slot_time &&  this.userType==1)
        {
            return false;

        }*/
//curr_date==slot_date

        if (this.orderData.status !='Cancelled') {
            return true;
        } else {
            return false;
        }

    }

    getRescheduledReason(order: any) {
        console.log(order);
        const modalRef = this.modalService.open(enterModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Reschedule Reason`,
            subTitle: ``,
            type: 'rescheduledReason',
            data: order,
        };
        modalRef.componentInstance.passEntry.subscribe((res: any) => {
        });
       
    
            this.onCloseNext();
      
    }
    checkPincodeExist(pincode:any)
    {
        
        if(this.availablePincode.includes(pincode))
        return false;
        else
        return true;

    }

    cancleOrder() {
        const data = this.orderData;
        console.log(data,"canacle data")
        const modalRef = this.modalService.open(enterModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Cancel this Order?`,
            subTitle: ``,
            type: 'cancelOrder',
            data: data,
        };
        modalRef.componentInstance.passEntry.subscribe((res: any) => {
            this.onCloseNext();

        });
    }

    submitedToOPS(data: any) {
        if(this.islab==1 && data.is_accept_bycc !='accepted')
        {
            this.toastr.error(`Please accept order first.`);

            return;
        }
       
        let checkPincode=this.checkPincodeExist(this.orderData.patient_pincode)
        const modalRef = this.modalService.open(deleteModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'alert-popup',
        });
        modalRef.componentInstance.props = {
            title: `Submit this Order to OPS?`,
            subTitle: ``,
            type: 'submitedToOPS',
            data: data,

            checkpincode:checkPincode,
        };
        modalRef.componentInstance.passEntry.subscribe((res: any) => {
        });
    }


    public checkPresentEmployee()
    {
      
        let value1 = (<HTMLInputElement>document.getElementById('myattendencefromDate')).value;
        let value2 = (<HTMLInputElement>document.getElementById('myattendencetoDate')).value;

        this.attendenceService.checkPresentEmployee(value1,value2 ,this.data).subscribe((res: any) => {
        if(res.success){
            this.checkPresentData=res.data;
          
   
        } else
        {
            this.checkPresentData=0;
        } 
    });
    


    }
    public closeWeekof()
    {
        this.checkweekof=false;  

    }
    public closeWeekof1()
    {
        this.checkPresentData=0;  

    }
    
   
    get attendenceControls(){
        return this.markAttendenceForm.controls;
    }
    
    get lastWorkingDayControls(){
        return this.lastWorkingDayForm.controls;
    }
    getKM(data: number) {
        if(data==1)
        {
            this.kmdisplay= true;
        }else
        {
            this.kmdisplay= false;

        }
    }
    
    emptyDate(data:any) {
        if(data==1)
        {
            this.today=new Date();
        }else{
            this.today="";
        }
       this.markAttendenceForm.get('from_date').setValue("");
    }
    onSaveReconsile() {

           switch (this.type) {

            case 'showPhoto':
                  

            this.showError = false;
            this.isBtnLoaded = true;
           
            

           
      
        break;
            case 'cashDepositTo':
                if (this.cashDepositTo.valid) {

                    // this.Form.get('id').setValue(this.data.id);

                    // this.showError = false;
                    this.isBtnLoaded = true;
                    
                        this.orderService.cashDepositTo(this.cashDepositTo.value,this.data).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                    this.router.navigateByUrl('payment/phleboview');
       
                                       this.toastr.success(`${res.message}`);
                                       this.onCloseNext();
                                   } else {
                                       if (res.message) {
                                           this.toastr.error(`${res.message}`);
                                       } else {
                                           this.toastr.error(`submit failed`);
                                       }
                                   }
                                   this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                   
                } else {
                 this.cashDepositTo.markAllAsTouched();

                }
                break;
            
// for rescheduledReason
case 'rescheduledReason':
                  console.log(this.data)
                    this.showError = false;
                    this.isBtnLoaded = true;
                    const formData = this.rescheduledReasonForm.value;
                    formData.visit_id = this.data.id;
                    this.formatteDate = this.datepipe.transform(this.rescheduledReasonForm.value.date, 'YYYY-MM-d HH:MM:ss')
                    
                    formData.date = this.formatteDate;
                    formData.customer_id = this.data.customer_id;
                    formData.confirm_slot = formData.slot;
                    formData.slot_time = this.data.slot_time;

                    formData.lab_location_id = this.data.lab_location_id;
                    formData.patient_address = this.data.patient_address;
                    formData.patient_city = this.data.city.id;
                    formData.patient_phone = this.data.patient_phone;
                    formData.patient_pincode = this.data.patient_pincode;
                    formData.patient_state = this.data.patient_state_id;
                    formData.reschedul_reason= formData.reason;


                    console.log("anmoltyagi", formData, "id", this.data.id)

                        this.orderService.updateOrder(formData, this.data.order_id,'update_order_master').subscribe(
                            (res: any) => {

                                if (res && res.success) {
                                 this.router.navigateByUrl(`orders/view-order/${this.data.order_id}/${this.data.id}`);
    
                                    this.toastr.success(`${res.message}`);
                                    this.onCloseNext();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`submit failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                   
              
                break;

            case 'ResetReconciliationVisit':
                  

                    this.showError = false;
                    this.isBtnLoaded = true;
                    
                        this.orderService.ResetReconcilePhleboStatus(this.data.id).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                 this.router.navigateByUrl('payment/phleboview');
    
                                    this.toastr.success(`${res.message}`);
                                    this.onCloseNext();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`submit failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                   
              
                break;
            case 'reconciliationVisit':
                  
                console.log("padam hello",this.reconsileForm);

                if (this.reconsileForm.valid) {

                    this.reconsileForm.get('visitId').setValue(this.data.id);

                    this.showError = false;
                    this.isBtnLoaded = true;
                    
                        this.orderService.reconcilePhleboStatus(this.reconsileForm.value).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                 this.router.navigateByUrl('payment/phleboview');
    
                                    this.toastr.success(`${res.message}`);
                                    this.onCloseNext();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`submit failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                   
                } else {
                 this.reconsileForm.markAllAsTouched();

                }
                break;


                case 'discount':
                  
    
                    if (this.Form.valid) {
    
                        // this.Form.get('id').setValue(this.data.id);
    
                        // this.showError = false;
                        this.isBtnLoaded = true;
                        
                            this.invoiceService.addDiscount(this.Form.value,this.data.id).subscribe(
                                (res: any) => {
                                    if (res && res.success) {
        
                                        this.toastr.success(`${res.message}`);
                                        this.onCloseNext();
                                    } else {
                                        if (res.message) {
                                            this.toastr.error(`${res.message}`);
                                        } else {
                                            this.toastr.error(`submit failed`);
                                        }
                                    }
                                    this.isBtnLoaded = false;
                                },
                                (err: any) => {
                                    this.onClose();
                                    this.toastr.error(`${err.statusText}`);
                                    console.log(err);
                                }
                            );
    
                       
                    } else {
                     this.reconsileForm.markAllAsTouched();
    
                    }
                    break;
              



                case 'bulkReconciliationVisit':
                  
                    console.log("padam hello",this.reconsileForm);
    
                    if (this.reconsileForm.valid) {
    
                      //  this.reconsileForm.get('visitId').setValue(this.data);
    
                        this.showError = false;
                        this.isBtnLoaded = true;
                        
                            this.orderService.reconcileBulkPhleboStatus(this.reconsileForm.value,this.data).subscribe(
                                (res: any) => {
                                    if (res && res.success) {
                                     this.router.navigateByUrl('payment/phleboview');
        
                                        this.toastr.success(`${res.message}`);
                                        this.onCloseNext();
                                    } else {
                                        if (res.message) {
                                            this.toastr.error(`${res.message}`);
                                        } else {
                                            this.toastr.error(`submit failed`);
                                        }
                                    }
                                    this.isBtnLoaded = false;
                                },
                                (err: any) => {
                                    this.onClose();
                                    this.toastr.error(`${err.statusText}`);
                                    console.log(err);
                                }
                            );
    
                       
                    } else {
                     this.reconsileForm.markAllAsTouched();
    
                    }
                    break;
               case 'rescheduleOrder':
                  
console.log("anmolTyagi")   
                   if (this.cancellForm.valid) {
   
                       this.cancellForm.get('visit_id').setValue(this.data.id);
   
                       this.showError = false;
                       this.isBtnLoaded = true;
                       console.log("amit122",this.cancellForm);
   
                           this.orderService.saveRescheduelReason(this.cancellForm.value).subscribe(
                               (res: any) => {
                                   if (res && res.success) {
                                    this.router.navigateByUrl('orders/edit/'+res.data.order_id+'/'+res.data.visit_id);
       
                                       this.toastr.success(`${res.message}`);
                                       this.onCloseNext();
                                   } else {
                                       if (res.message) {
                                           this.toastr.error(`${res.message}`);
                                       } else {
                                           this.toastr.error(`submit failed`);
                                       }
                                   }
                                   this.isBtnLoaded = false;
                               },
                               (err: any) => {
                                   this.onClose();
                                   this.toastr.error(`${err.statusText}`);
                                   console.log(err);
                               }
                           );
   
                      
                   } else {
                    this.cancellForm.markAllAsTouched();

                   }
                   break;
                   case 'cancelOrder':
                  
                    console.log("amit1211112",this.cancellForm);
    
                    if (this.cancellForm.valid) {
    
                        this.cancellForm.get('visit_id').setValue(this.data.id);
   
                        this.showError = false;
                        this.isBtnLoaded = true;
                        console.log("amit122",this.cancellForm);
    
                            this.orderService.saveCancellationReason(this.cancellForm.value).subscribe(
                                (res: any) => {
                                    if (res && res.success) {
                                        this.toastr.success(`${res.message}`);
                                        this.onCloseNext();
                                    } else {
                                        if (res.message) {
                                            this.toastr.error(`${res.message}`);
                                        } else {
                                            this.toastr.error(`submit failed`);
                                        }
                                    }
                                    this.isBtnLoaded = false;
                                },
                                (err: any) => {
                                    this.onClose();
                                    this.toastr.error(`${err.statusText}`);
                                    console.log(err);
                                }
                            );
    
                       
                    } else {
                        this.cancellForm.markAllAsTouched();

                    }
                    break;
                    
                    case 'lastWorkingDay':
                                                                  
                        if (this.lastWorkingDayForm.valid) {
        
                            this.showError = false;
                            this.isBtnLoaded = true;

                            if (this.lastWorkingDayForm.value.last_working_day) {
                                this.lastWorkingDayForm.value.last_working_day = this.datepipe.transform(this.lastWorkingDayForm.value.last_working_day, 'YYYY-MM-d')
                              } else {
                                this.lastWorkingDayForm.value.last_working_day = ''
                              }
                            
                                this.employeeService.employeeLastWorkingDay(this.lastWorkingDayForm.value,this.data).subscribe(
                                    (res: any) => {
                                        if (res && res.success) {
                                         this.router.navigate(['attendance/employee']);
            
                                            this.toastr.success(`${res.message}`);
                                            this.onCloseNext();
                                        } else {
                                            if (res.message) {
                                                this.toastr.error(`${res.message}`);
                                            } else {
                                                this.toastr.error(`submit failed`);
                                            }
                                        }
                                        this.isBtnLoaded = false;
                                    },
                                    (err: any) => {
                                        this.onClose();
                                        this.toastr.error(`${err.statusText}`);
                                        console.log(err);
                                    }
                                );
        
                           
                        } else {
                         this.lastWorkingDayForm.markAllAsTouched();
                          this.lastWorkingFormTouch = true;
                        }
                        break;
        
                        case 'bulkmarkAttendence':                 
                                                    
                            if (this.markAttendenceForm.valid) {
            
                                this.showError = false;
                                this.isBtnLoaded = true;
                                this.markAttendenceForm.value
                                if(this.markAttendenceForm.value.from_date=="")
                                {
                                    this.markAttendenceForm.value.from_date=this.dateData;    
                                }else
                                {
                                    this.markAttendenceForm.value.from_date= this.datepipe.transform(this.markAttendenceForm.value.from_date, 'YYYY-MM-d')
 
                                }

                                    this.attendenceService.markMultiEmpAttendence(this.markAttendenceForm.value,this.data).subscribe(
                                        (res: any) => {
                                            if (res && res.success) {
                                             this.router.navigate(['attendance/attendance-management']);
                
                                                this.toastr.success(`${res.message}`);
                                                this.onCloseNext();
                                            } else {
                                                if (res.message) {
                                                    this.toastr.error(`${res.message}`);
                                                } else {
                                                    this.toastr.error(`submit failed`);
                                                }
                                            }
                                            this.isBtnLoaded = false;
                                        },
                                        (err: any) => {
                                            this.onClose();
                                            this.toastr.error(`${err.statusText}`);
                                            console.log(err);
                                        }
                                    );
            
                               
                            } else {
                             this.markAttendenceForm.markAllAsTouched();
                              this.markAttendenceTouch = true;
                            }
                            break;
                            case 'bulkmarkLeave':                 
                                                    
                            if (this.markAttendenceForm.valid) {
            
                                this.showError = false;
                                this.isBtnLoaded = true;
                                this.markAttendenceForm.value
                                if(this.markAttendenceForm.value.from_date=="")
                                {
                                    this.markAttendenceForm.value.from_date=this.dateData;    
                                }else
                                {
                                    this.markAttendenceForm.value.from_date= this.datepipe.transform(this.markAttendenceForm.value.from_date, 'YYYY-MM-d')
 
                                }

                                    this.attendenceService.markMultiEmpLeave(this.markAttendenceForm.value,this.data).subscribe(
                                        (res: any) => {
                                            if (res && res.success) {
                                             this.router.navigate(['attendance/attendance-management']);
                
                                                this.toastr.success(`${res.message}`);
                                                this.onCloseNext();
                                            } else {
                                                if (res.message) {
                                                    this.toastr.error(`${res.message}`);
                                                } else {
                                                    this.toastr.error(`submit failed`);
                                                }
                                            }
                                            this.isBtnLoaded = false;
                                        },
                                        (err: any) => {
                                            this.onClose();
                                            this.toastr.error(`${err.statusText}`);
                                            console.log(err);
                                        }
                                    );
            
                               
                            } else {
                             this.markAttendenceForm.markAllAsTouched();
                              this.markAttendenceTouch = true;
                            }
                            break;
                            case 'markAttendence':                        
                                if (this.markAttendenceForm.valid) {
                
                                    this.showError = false;
                                    this.isBtnLoaded = true;
                                    
                                        this.attendenceService.markEmpAttendence(this.markAttendenceForm.value,this.data.id,this.dateData).subscribe(
                                            (res: any) => {
                                                if (res && res.success) {
                                                 this.router.navigate(['attendance/attendance-management']);
                    
                                                    this.toastr.success(`${res.message}`);
                                                    this.onCloseNext();
                                                } else {
                                                    if (res.message) {
                                                        this.toastr.error(`${res.message}`);
                                                    } else {
                                                        this.toastr.error(`submit failed`);
                                                    }
                                                }
                                                this.isBtnLoaded = false;
                                            },
                                            (err: any) => {
                                                this.onClose();
                                                this.toastr.error(`${err.statusText}`);
                                                console.log(err);
                                            }
                                        );
                
                                   
                                } else {
                                 this.markAttendenceForm.markAllAsTouched();
                                  this.markAttendenceTouch = true;
                                }
                                break;
               
           }
       }
       

       onDelete() {
        switch (this.type) {
            case 'acceptRejctOrder':

                this.isBtnLoaded = true;
                console.log("phlele wala - ", this.isBtnLoaded)
                this.orderService.submitToOps(this.data, this.submittoopsForm.value.phlebo).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                                this.isBtnLoaded = false;

                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                        console.log("bad wala - ", this.isBtnLoaded)

                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                
        }
       }

       onSaveCollected() {
              if(this.collectedDate==null)
              {this.checkdate=1}
              else{
                this.checkdate=0;  
                        this.mydate = this.datepipe.transform(this.collectedDate, 'yyyy-MM-dd');
  

                           this.orderService.saveCollected(this.data.id,this.mydate,this.collectedTime,this.sample_barcode ).subscribe(
                               (res: any) => {
                                   if (res && res.success) {
                                       this.toastr.success(`${res.message}`);
                                       this.onCloseNext();
                                   } else {
                                       if (res.message) {
                                           this.toastr.error(`${res.message}`);
                                       } else {
                                           this.toastr.error(`submit failed`);
                                       }
                                   }
                                   this.isBtnLoaded = false;
                               },
                               (err: any) => {
                                   this.onClose();
                                   this.toastr.error(`${err.statusText}`);
                                   console.log(err);
                               }
                           );
                            }
                      
                 
                 
       }
       onSaveSampleDelivered() {
        if(this.collectedDate==null)
        {this.checkdate=1}
        else{
          this.checkdate=0;  
                  this.mydate = this.datepipe.transform(this.collectedDate, 'yyyy-MM-dd');


                     this.orderService.saveSampleDelivered(this.data.id,this.mydate,this.collectedTime ).subscribe(
                         (res: any) => {
                             if (res && res.success) {
                                 this.toastr.success(`${res.message}`);
                                 this.onCloseNext();
                             } else {
                                 if (res.message) {
                                     this.toastr.error(`${res.message}`);
                                 } else {
                                     this.toastr.error(`submit failed`);
                                 }
                             }
                             this.isBtnLoaded = false;
                         },
                         (err: any) => {
                             this.onClose();
                             this.toastr.error(`${err.statusText}`);
                             console.log(err);
                         }
                     );
                      }
                
           
           
 }
  
 onSaveTestPrice() {
    if(this.testPrice==null)
    {this.checkdate=1}
    else{
      this.checkdate=0;  


                 this.orderService.onSaveTestPrice(this.data,this.testPrice).subscribe(
                     (res: any) => {
                         if (res && res.success) {
                             this.toastr.success(`${res.message}`);
                             this.onCloseNext();
                         } else {
                             if (res.message) {
                                 this.toastr.error(`${res.message}`);
                             } else {
                                 this.toastr.error(`submit failed`);
                             }
                         }
                         this.isBtnLoaded = false;
                     },
                     (err: any) => {
                         this.onClose();
                         this.toastr.error(`${err.statusText}`);
                         console.log(err);
                     }
                 );
                  }
            
       
       
}

  getGeocode(data) {
    switch (data) {
        case 1:
            let address=this.changeLocationForm.value.start_point;

          
              this.phleboService.getLatLongLiveAddress(address).subscribe((data) => {

                this.changeLocationForm.get('start_lat').setValue(data.data.lat);
                this.changeLocationForm.get('start_long').setValue(data.data.lng);
            });
          
            break;
            
            case 2:
                let address1=this.changeLocationForm.value.end_point;
                this.phleboService.getLatLongLiveAddress(address1).subscribe((data) => {

                    this.changeLocationForm.get('end_lat').setValue(data.data.lat);
                    this.changeLocationForm.get('end_long').setValue(data.data.lng);
                });
                case 3:
                    let address2=this.editPhleboPlanForm.value.starting_point;
                    this.phleboService.getLatLongLiveAddress(address2).subscribe((data) => {
                      this.editPhleboPlanForm.get('start_lat').setValue(data?.data?.lat);
                       this.editPhleboPlanForm.get('start_long').setValue(data?.data?.lng);
                    });
                    break;

                case 4:
                    let address3=this.editPhleboPlanForm.value.end_point;
                   this.phleboService.getLatLongLiveAddress(address3).subscribe((data) => {
                       this.editPhleboPlanForm.get('end_lat').setValue(data?.data?.lat);
                        this.editPhleboPlanForm.get('end_long').setValue(data?.data?.lng);
                   });
                    break;
                    case 5:
                        let address5=this.visitAddressForm.value.patient_address;
            
                      
                          this.phleboService.getLatLongLiveAddress(address5).subscribe((data) => {
            
                            this.visitAddressForm.get('lat').setValue(data.data.lat);
                            this.visitAddressForm.get('lng').setValue(data.data.lng);
                        });
                      
                        break;
        default:
            break;
    }

}
       onSaveRoster() {
     
        switch (this.type) {
            case 'editvisitLatLong':
                this.nextSaveBtnLodingPopup = true;
                this.phleboTouchSubmit=true;
                    if (this.visitAddressForm.valid) {
                        this.showError = false;
                       
                            this.planService.updateVisitLatLong(this.visitAddressForm.value).subscribe(

                                (res: any) => {
                                    if (res && res.status) {
                                        this.toastr.success(`${res.message}`);
                                        console.log('time taken----1');
                                        this.nextSaveBtnLodingPopup = false;
                                        this.onCloseNextPopup();
                                    } else {
                                        this.nextSaveBtnLodingPopup = false;
                                        console.log('time taken----');
                                        if (res.message) {
                                            this.toastr.error(`${res.message}`);
                                        } else {
                                            this.toastr.error(`submit failed`);
                                        }
                                    }
                                    this.nextSaveBtnLoding = false;
                                },
                                (err: any) => {
                                    this.onClose();
                                    this.toastr.error(`${err.statusText}`);

                                    this.phleboTouchSubmit=false;
                                }
                            );


                    } else {
                        this.showError = true;
                        this.rosterTouchSubmit=false;
                    }
                    break;
            case 'editPhleboPlan':
                this.phleboTouchSubmit=true;
                    if (this.editPhleboPlanForm.valid) {
                        this.showError = false;
                        this.nextSaveBtnLoding = true;
                            this.planService.updatePhleboPlan(this.editPhleboPlanForm.value).subscribe(

                                (res: any) => {
                                    if (res && res.status) {

                                        this.toastr.success(`${res.message.charAt(0).toUpperCase() + res.message.slice(1)}`);
                                        this.onCloseNext();
                                    } else {
                                        if (res.message) {
                                            this.toastr.error(`${res.message}`);
                                        } else {
                                            this.toastr.error(`submit failed`);
                                        }
                                    }
                                    this.nextSaveBtnLoding = false;
                                },
                                (err: any) => {
                                    this.onClose();
                                    this.toastr.error(`${err.statusText}`);

                                    this.phleboTouchSubmit=false;
                                }
                            );


                    } else {
                        this.showError = true;
                        this.rosterTouchSubmit=false;
                    }
                    break;
            case 'changelocation':
                this.phleboTouchSubmit=true;
                    if (this.changeLocationForm.valid) {    
                        this.showError = false;
                        this.nextSaveBtnLoding = true;
                            this.phleboService.changeLocation(this.changeLocationForm.value,this.data.id).subscribe(
                           
                                (res: any) => {
                                    if (res && res.success) {
                                       
                                        this.toastr.success(`${res.message.charAt(0).toUpperCase() + res.message.slice(1)}`);
                                        this.onCloseNext();
                                    } else {
                                        if (res.message) {
                                            this.toastr.error(`${res.message}`);
                                        } else {
                                            this.toastr.error(`submit failed`);
                                        }
                                    }
                                    this.nextSaveBtnLoding = false;
                                },
                                (err: any) => {
                                    this.onClose();
                                    this.toastr.error(`${err.statusText}`);
                                    console.log(err);
                                    this.phleboTouchSubmit=false;
                                }
                            );
    
    
                    } else {
                        this.showError = true;
                        this.rosterTouchSubmit=false;
                    }
                    break;

                    case 'editPhleboPlan':
                        this.phleboTouchSubmit=true;
                            if (this.editPhleboPlanForm.valid) {    
                                this.showError = false;
                                this.nextSaveBtnLoding = true;
                                    this.planService.updatePhleboPlan(this.editPhleboPlanForm.value).subscribe(
                                   
                                        (res: any) => {
                                            if (res && res.status) {
                                               
                                                this.toastr.success(`${res.message.charAt(0).toUpperCase() + res.message.slice(1)}`);
                                                this.onCloseNext();
                                            } else {
                                                if (res.message) {
                                                    this.toastr.error(`${res.message}`);
                                                } else {
                                                    this.toastr.error(`submit failed`);
                                                }
                                            }
                                            this.nextSaveBtnLoding = false;
                                        },
                                        (err: any) => {
                                            this.onClose();
                                            this.toastr.error(`${err.statusText}`);
                                            console.log(err);
                                            this.phleboTouchSubmit=false;
                                        }
                                    );
            
            
                            } else {
                                this.showError = true;
                                this.rosterTouchSubmit=false;
                            }
                            break;
            case 'viewRosterphlebo':
            this.rosterTouchSubmit=true;
                if (this.rosterForm) {
                   this.rosterForm.get('phlebo_id').setValue(this.data.id);
                    this.showError = false;
                    this.isBtnLoaded = true;
                    console.log("aman==========================");
                        this.phleboService.onSaveRoster(this.rosterForm.value,this.data.id).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                    this.toastr.success(`${res.message}`);
                                    this.onCloseNext();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`submit failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                                this.rosterTouchSubmit=false;
                            }
                        );


                } else {
                    this.showError = true;
                    this.rosterTouchSubmit=false;
                }
                break;
              

        }
    }
    onSaveDecline() {

     console.log("amit",this.type);
        switch (this.type) {
            case 'declineVisit':
               
                console.log("amit1211112",this.declineForm);

                if (this.declineForm) {

                    this.declineForm.get('visit_id').setValue(this.data.id);

                    this.showError = false;
                    this.isBtnLoaded = true;
                    console.log("amit122",this.declineForm);

                        this.orderService.saveDeclineComment(this.declineForm.value).subscribe(
                            (res: any) => {
                                if (res && res.success) {
                                    this.toastr.success(`${res.message}`);
                                    this.onCloseNext();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`submit failed`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );

                   
                } else {
                    this.showError = true;
                }
                break;
                
            
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private httpService: HttpService) { }

  getAllClients() {
    const url = `${environment.SettingsApiendpoint}/client-get-all`;
    return this.httpService.get(url, this.httpService.headers);
  }

  updateClient(data: any, client_id: any) {
    const url = `${environment.SettingsApiendpoint}/client-update/${client_id}`;
    return this.httpService.patch(url, data, this.httpService.headers);
  }

  obtainClient(clientId: any) {
    const url = `${environment.SettingsApiendpoint}/client-edit/${clientId}`;
    return this.httpService.get(url, this.httpService.headers);
  }

  obtainClients(search: any) {
    const url = `${environment.SettingsApiendpoint}/client-filtered?${search}`;
    return this.httpService.get(url, this.httpService.headers)
}

  deleteClient(clientId: any) {
    const url = `${environment.SettingsApiendpoint}/client-delete/${clientId}`;
    return this.httpService.delete(url, this.httpService.headers);
  }

  addClient(data: any) {
    const url = `${environment.SettingsApiendpoint}/client-store`;
    return this.httpService.post(url, data, this.httpService.headers);
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private httpService: HttpService) { }

  addEmployee(data: any) {
    const url = `${environment.SettingsApiendpoint}/employee-store`;

    return this.httpService.post(url, data, this.httpService.headers);
  }
  

  getDepartment() {
    const url = `${environment.SettingsApiendpoint}/department-get-all`;

    return this.httpService.get(url, this.httpService.headers);
  }

  getfilteredEmployees(search: any) {
    const url = `${environment.SettingsApiendpoint}/employee-get-all?${search}`;

    return this.httpService.get(url, this.httpService.headers);
  }

  obtainEmployee(employeeId:any){
    const url = `${environment.SettingsApiendpoint}/employee-edit/${employeeId}`;

    return this.httpService.get(url,this.httpService.headers);
  }

  updateEmployee(data:any,employeeId:any){
    const url =`${environment.SettingsApiendpoint}/employee-update/${employeeId}`;

    return this.httpService.patch(url,data,this.httpService.headers);
  }

  deleteEmployee(employeeId:any){
    const url =`${environment.SettingsApiendpoint}/employee-delete/${employeeId}`;

    return this.httpService.delete(url,this.httpService.headers);
  }

  employeeLastWorkingDay(data:any,employeeId:any){
      const url = `${environment.SettingsApiendpoint}/employee-last-working-day/${employeeId}`;

      return this.httpService.patch(url,data,this.httpService.headers);
  }

  updateStatus(id: any, status: any){
    const url = `${environment.SettingsApiendpoint}/employee-status/${id}/${status}`
    return this.httpService.put(url, {}, this.httpService.headers)
}

UndoLastWorkingDay(EmpId: any){
  const url = `${environment.SettingsApiendpoint}/employee-revert-last-working/${EmpId}`
  return this.httpService.patch(url,{}, this.httpService.headers)
}

exportEmployee(){
  const url = `${environment.SettingsApiendpoint}/employee-export`;

  return this.httpService.get(url, this.httpService.headers)
}
  
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustomerdosService } from "src/app/services/customerdos/customerdos.service";
import { AuthenticationService } from "src/app/services/auth/authentication.service";
import { CustomersService } from 'src/app/services/customer/customer.service';

@Component({
    selector: "app-exportModal",
    templateUrl: "./exportModal.component.html",
    styleUrls: ["./exportModal.component.scss"]
})
export class exportModalComponent implements OnInit {
    @Input() props: any;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    public title: string;
    public subTitle: string;
    public fromData: any;

    public type: string;
    public data: any;
    public page: any = 1;
    public isBtnLoaded: boolean = false;
    public customerlistForDropdown: any;
    public isCustomerDropdown: any;
    public showError: any;
    public userType: any;
    public  login_customer_id: any;
    public providerListForDropdown: any;
    public customer_id: any;
    public provider_id: any=0;
    public isAggregator: boolean=false;
    isAggregator1: boolean;

    constructor(
        private ngbModal: NgbActiveModal,
        private toastr: ToastrService,
        private customerdosService: CustomerdosService,
        private authService: AuthenticationService,
        private customerService: CustomersService,

    ) {
        this.userType= this.authService.user.roles.user_type_id;
        this.login_customer_id= this.authService.user.customer_id; }

    ngOnInit(): void {
        this.setDialogProps(this.props);
        this.customerdosService.getallCustomer().subscribe((data) => {
            this.customerlistForDropdown = data.data;
        });
        if(this.userType==1)
        {

         this.getProvidersData(this.login_customer_id);   
        }
        if(this.isCustomerDropdown=="no")
      {
       this.getProvidersData(this.customer_id);   
      }

    }

    setDialogProps(dialogdata: any) {
        this.title = dialogdata.title;
        this.subTitle = dialogdata.subTitle;
        this.type = dialogdata.type;
        this.data = dialogdata.data;
        this.page = dialogdata.page;
        this.isCustomerDropdown = dialogdata.isCustomerDropdown;
        this.customer_id = dialogdata.customer_id;
        
    }
    

    selectOption(id: any) {
        this.customer_id=id;
        this.fromData = { customer_id: this.customer_id,provider_id:this.provider_id }

        this.getProvidersData(id);

    }
    selectProviderOption(id: any) {
        if(this.userType==1)
        {
            this.fromData = { customer_id:this.login_customer_id ,provider_id:id }

        }else{
            this.fromData = { customer_id: this.customer_id,provider_id:id }
      
        }
    }
    getProvidersData(myvalue){
        this.customerService.getAggregatorProvider(myvalue).subscribe((data) => {
            this.providerListForDropdown = data.data;
        });

        this.customerdosService.getSingleCustomer(myvalue).subscribe((data) => {
           
            if(data.data.is_aggregator == "true")
            {
                this.isAggregator1 =true;
            }else{
                this.isAggregator1 =false;

            }

        });
       
    }
    onDownload() {

       
        switch (this.type) {
            case 'downloadDosCustomer':
                if(this.fromData==undefined)
                {            

                    this.fromData = { customer_id:this.customer_id}

                }
              console.log(this.fromData);
                if (this.fromData) {
                   
                    this.showError = false;
                    this.isBtnLoaded = true;
                    this.customerdosService.downloadDos(this.fromData).subscribe(
                        (res: any) => {
                            if (res && res.success) {
                                this.toastr.success(`${res.message}`);
                               // window.open(res.data, "_blank");
                            } else {
                                if (res.message) {
                                    this.toastr.error(`${res.message}`);
                                } else {
                                    this.toastr.error(`Update failed`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        }
                    );
                }else{
                    this.showError = true;
                }
                break;
            case 'downloadDos':
                if(this.fromData==undefined)
                {            

                    this.fromData = { customer_id:this.login_customer_id}

                }
                if (this.fromData) {
                   
                    this.showError = false;
                    this.isBtnLoaded = true;
                    this.customerdosService.downloadDos(this.fromData).subscribe(
                        (res: any) => {
                            if (res && res.success) {
                                this.toastr.success(`${res.message}`);
                               // window.open(res.data, "_blank");
                            } else {
                                if (res.message) {
                                    this.toastr.error(`${res.message}`);
                                } else {
                                    this.toastr.error(`Update failed`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        }
                    );
                }else{
                    this.showError = true;
                }
                break;
        }
    }

    onClose() {
        this.passEntry.next(this.page);
        this.ngbModal.close();
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpService } from '../http/http.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class OrderService {
    constructor(private httpService: HttpService) { }
    
    
    getSeeGates(datData: any,stateNameFilter: any,cityNameFilter: any,): Observable<any> {
        const url = `${environment.OrderApiendpoint}/get-sea-rates`;
        return this.httpService.post(url, {"date":datData,"from":stateNameFilter,"to":cityNameFilter,}, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    // for export csv 
    exportFeedbackReport(data:any): Observable<any> {
        const url = `${environment.phleboAppEndpoint}/api/feedback/exportDataToCsv`;
        return this.httpService.get(url, this.httpService.headers)
        .pipe(catchError(this.Errorhandling))
}

    getallFeedback(data:any) {
        const url = `${environment.OrderApiendpoint}/get-all-feedback?${data}`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
} 
    
    savePatientFeedback(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/add-Patient-feedback`;
        return this.httpService.post(url, data, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    acceptRejectOrderByCC(flage:any,visit_id:any) {
        const url = `${environment.OrderApiendpoint}/accept-reject-order-bycc/${visit_id}/${flage}`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
} 
    addLatLongVisit(visit_id:any) {
        const url = `${environment.OrderApiendpoint}/add-latlong-visit/${visit_id}`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
} 

    getPhlebogps(visit_id:any) {
        const url = `${environment.OrderApiendpoint}/get-phlebogps/${visit_id}`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
} 
    getSingleVisitData(visit_id:any) {
        const url = `${environment.OrderApiendpoint}/get-single-visit-data/${visit_id}`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
} 
    updateLeave(data: any, id: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/updateLeave/${id}`
        return this.httpService.put(url, data, this.httpService.headers)
    }
    
    createLeave(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/addLeaves`;
        return this.httpService.post(url, data, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getallLeave(data: any) {
        const url = `${environment.OrderApiendpoint}/leaveList?${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    deleteLeave(id: any) {
        const url = `${environment.OrderApiendpoint}/deleteList/${id}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    updateStatus(id: any, status: any) {
        const url = `${environment.OrderApiendpoint}/changeStatus/${id}`;
        return this.httpService.put(url, {"status":status}, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
    }
    
    
    validatePromocode(promocode:any,visit_id:any) {
        const url = `${environment.OrderApiendpoint}/validate-promocode-srl/${promocode}/${visit_id}`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
} 
getCashfreeUrl(transaction_id:any) {
    const url = `${environment.OrderApiendpoint}/get-cashfreeurl/${transaction_id}`;
    return this.httpService.get(url, this.httpService.headers).pipe(
        catchError(this.Errorhandling)
    );
} 
confirmPromocode(promocode:any,visit_id:any) {
    const url = `${environment.OrderApiendpoint}/confirm-promocode-srl/${promocode}/${visit_id}`;
    return this.httpService.get(url, this.httpService.headers).pipe(
        catchError(this.Errorhandling)
    );
} 
    confirmRedeem(visit_id:any,mobile:any,patient_firstname:any,pSMSCode:any,pNetAmount:any,redeem_amount:any) {
        const url = `${environment.OrderApiendpoint}/confirm-redeem-srl/${visit_id}/${mobile}/${patient_firstname}/${pSMSCode}/${pNetAmount}/${redeem_amount}`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
} 
    resendOtp(mobile:any) {
        const url = `${environment.OrderApiendpoint}/resend-otp-srl/${mobile}`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
} 
    confirmOtp(visit_id:any,otp:any,mobile:any,redeem_amount:any) {
        const url = `${environment.OrderApiendpoint}/confirm-otp-srl/${visit_id}/${otp}/${mobile}/${redeem_amount}`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
}

checkRedeem(visit_id:any,check_point:any) {
    const url = `${environment.OrderApiendpoint}/check-point-redeem/${visit_id}/${check_point}`;
    return this.httpService.get(url, this.httpService.headers).pipe(
        catchError(this.Errorhandling)
    );
}

cancelPromocode(visit_id:any) {
    const url = `${environment.OrderApiendpoint}/cancel-promocode/${visit_id}`;
    return this.httpService.get(url, this.httpService.headers).pipe(
        catchError(this.Errorhandling)
    );
}
    getSrlCustomer() {
        const url = `${environment.OrderApiendpoint}/get-srl-detail`;
        return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
}
    deleteDoctorData(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/doctor/${data}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    deleteOrgData(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/organization/${data}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    
    checkLogin(data: any) {
        const url = `${environment.OrderApiendpoint}/check-loging/${data}`;
        console.log(url);
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    getDoctorForTable(data: any) {
        const url = `${environment.OrderApiendpoint}/doctor?${data}`;
        console.log(url);
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getOrganizationForTable(data: any) {
        const url = `${environment.OrderApiendpoint}/organization?${data}`;
        console.log(url);
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    syncDoctor(data: any) {
        const url = `${environment.OrderApiendpoint}/sync-doctor/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    syncOrganization(data: any) {
        const url = `${environment.OrderApiendpoint}/sync-organization/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }


    

    add_ordertype(data: any,id:any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/add-ordertype`;
        return this.httpService.post(url,{"visit_id":id,"difficult_phlebotomy":data.difficult_phlebotomy,"vip_order":data.vip_order}, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getallCustomerOrganization(cusid: any,value:any) {
        const url = `${environment.OrderApiendpoint}/get-organization/${cusid}/${value}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
    getallCustomerDoctor(cusid: any,value:any) {
        const url = `${environment.OrderApiendpoint}/get-doctor/${cusid}/${value}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    getOrderType(id: any) {
        const url = `${environment.OrderApiendpoint}/get-ordertype/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
    getViewNotes(id: any) {
        const url = `${environment.OrderApiendpoint}/get-notes/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    addNotes(id: any,data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/add-notes/`
        return this.httpService.post(url, {"visit_id":id,"notes":data}, this.httpService.headers)
    }
    addBarcodes(id: any,data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/update-barcode/`
        return this.httpService.post(url, {"id":id,"barcodes":data}, this.httpService.headers)
    }

    downloadCamps(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/export-camp?${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    deleteCamp(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/samp-camp/${data}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    editCamp(data: any,id:any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/samp-camp/${id}`;
        return this.httpService.put(url, data, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    onAddNewPatientsSRL(data: any,order_id: any,visit_id: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/save-srl-patients/`
        return this.httpService.post(url, {"patients":data,"order_id":order_id,"visit_id":visit_id}, this.httpService.headers)
    }
    saveSrlPatients(ids:any,order_id:any,visit_id:any,) {
        const url = `${environment.OrderApiendpoint}/save-srl-patients`;
      
        
        return this.httpService
            .post(url, {"order_id":order_id,"visit_id":visit_id,"ids":ids,}, this.httpService.headers)
            .pipe(catchError(this.Errorhandling));
    }
    searchPatientByPhone(data: any) {
        const url = `${environment.OrderApiendpoint}/search-mobile-srl/${data}`;
		 return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
    }
    getSpeciman(id: any,visit_id: any,patient_id:any) {
        const url = `${environment.OrderApiendpoint}/get-speciman/${id}/${visit_id}/${patient_id}`;
		 return this.httpService.get(url, this.httpService.headers).pipe(
            catchError(this.Errorhandling)
        );
    }
    
	savespeciman(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/save-speciman`;
		
		  return this.httpService
            .post(url, data,  this.httpService.headers)
            .pipe(catchError(this.Errorhandling));

    }
    updatespeciman(data, value,visit_id,patient_id,test_id): Observable<any> {
        const url = `${environment.OrderApiendpoint}/update-speciman`;
		
		  return this.httpService
            .post(url, {"speciman":data,"value":value,"visit_id":visit_id,"patient_id":patient_id,"test_id":test_id},  this.httpService.headers)
            .pipe(catchError(this.Errorhandling));

    }
    
    addCamp(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/samp-camp`;
		
		  return this.httpService
            .post(url, data,  this.httpService.headers)
            .pipe(catchError(this.Errorhandling));

    }
    getCampForTable(data: any) {
        const url = `${environment.OrderApiendpoint}/samp-camp?${data}`;
        console.log(url);
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    getCamp(id: any) {
        const url = `${environment.OrderApiendpoint}/samp-camp/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
	 exportBankDeposits(data: any) {
        const url = `${environment.OrderApiendpoint}/banckdeposit-export/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getTotalAmountBankDeposit(visitids:any) {
        const url = `${environment.OrderApiendpoint}/get-total-amount-banckdeposit`;
        const formData = new FormData();

        formData.append('visit_ids', visitids);

        
        return this.httpService
            .post(url, formData, this.httpService.headers)
            .pipe(catchError(this.Errorhandling));
    }
    getBankDepositDataList(data: any) {
        const url = `${environment.OrderApiendpoint}/get-bank-deposit-data?${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    deleteBankDeposit(id: any) {
        const url = `${environment.OrderApiendpoint}/banckdeposit/${id}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    } 
    addBankDeposit(data:any, imagefile: File,visitids:any) {
        const url = `${environment.OrderApiendpoint}/banckdeposit`;
        const formData = new FormData();

        formData.append('file', imagefile);


        // formData.append('data', data);
        formData.append('data', JSON.stringify(data));
        formData.append('visit_ids', visitids);

        
        return this.httpService
            .post(url, formData, this.httpService.headers)
            .pipe(catchError(this.Errorhandling));
    }
    editBankDeposit(data:any, imagefile: File,id:any,) {
        const url = `${environment.OrderApiendpoint}/banckdeposit-edit`;
        const formData = new FormData();

        formData.append('file', imagefile);


        // formData.append('data', data);
        formData.append('data', JSON.stringify(data));
        formData.append('id', id);

        return this.httpService
            .post(url, formData, this.httpService.headers)
            .pipe(catchError(this.Errorhandling));
    }
    getBankDeposits(data: any) {
        const url = `${environment.OrderApiendpoint}/banckdeposit?${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    updateModeCash(data) {
        const url = `${environment.OrderApiendpoint}/update-mode-cash/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    updateModeOnline(data) {
        const url = `${environment.OrderApiendpoint}/update-mode-online/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    
    updateAmountParent(data) {
        const url = `${environment.OrderApiendpoint}/update-upsell-amount/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getPatientbyVisitId(data) {
        const url = `${environment.OrderApiendpoint}/get-patientby-visit-id/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getPatientbyId(data) {
        const url = `${environment.OrderApiendpoint}/get-patientby-id/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    getNotImportedOrderList() {
        const url = `${environment.OrderApiendpoint}/get-not-imported-order`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
     
    getexportFileList() {
        const url = `${environment.OrderApiendpoint}/get-export-filelist`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getexportFileLabList() {
        const url = `${environment.labUserEndpoint}/get-export-userlabs`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getexportFileListReconcilation() {
        const url = `${environment.OrderApiendpoint}/get-export-filelist-reconcilation`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    
    getAllPhleboList(data: any) {
        const url = `${environment.OrderApiendpoint}/get-all-phlebos/${data.lab_location_id}/${data.id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    saveSampleDelivered(data: any,mydate:any,mytime:any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/sample-delivered/${data}`
        return this.httpService.patch(url,{
            "date":mydate,
            "time":mytime
        }, this.httpService.headers)
    }
    onSaveTestPrice(data: any,price:any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/save-test-lab-price/${data.test_code}/${data.lab_code}`
        return this.httpService.patch(url,{
            "price":price,
        }, this.httpService.headers)
    }
    saveCollected(data: any,mydate:any,mytime:any,sample_barcode:any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/order-colleted/${data}`
        return this.httpService.patch(url,{
            "date":mydate,
            "time":mytime,
            "sample_barcode":sample_barcode
        }, this.httpService.headers)
    }
   
    exportReconcilation(data: any) {
        const url = `${environment.OrderApiendpoint}/reconcile_export?${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    ResetReconcilePhleboStatus(data: any) {
        const url = `${environment.OrderApiendpoint}/reset-reconcile/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    
    reconcilePhleboStatus(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/reconcile-phlebo-status/`
        return this.httpService.patch(url, data, this.httpService.headers)
    }

    reconcileBulkPhleboStatus(datavalue: any,data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/reconcile-status-update-bulk/`
        return this.httpService.post(url, {"reconcile_comment":datavalue.reconcile_comment,"reconcile_amount":datavalue.reconcile_amount,"visitids":data}, this.httpService.headers)
    }

    cashDepositTo(datavalue: any,data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/cash-deposit-to/`
        return this.httpService.post(url, {"cash_deposited_to":datavalue.deposittype,"visitids":data}, this.httpService.headers)
    }
    getallphlebo(data: any) {
        const url = `${environment.OrderApiendpoint}/all-phlebos/${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    get_phlebos() {
        const url = `${environment.OrderApiendpoint}/get-phlebos`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
  
    getPhleboView(data: any) {
        const url = `${environment.OrderApiendpoint}/reconcile?${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
              
                catchError(this.Errorhandling)
            );
    }
    getDateView(data: any) {
        const url = `${environment.OrderApiendpoint}/reconcile-date?${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    getallreason() {
        const url = `${environment.OrderApiendpoint}/cancellation-reason`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    saveRescheduelReason(data: any): Observable<any> {
        console.log("amit1244",data);

        const url = `${environment.OrderApiendpoint}/reshedule-visit`;
        return this.httpService.post(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    
    saveCancellationReason(data: any): Observable<any> {
        console.log("amit1244",data);

        const url = `${environment.OrderApiendpoint}/cancel-visit`;
        return this.httpService.post(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }


    saveDeclineComment(data: any): Observable<any> {
        console.log("amit1244",data);

        const url = `${environment.OrderApiendpoint}/decline-order`;
        return this.httpService.post(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    submitToOps(data: any,phlebo:any=0): Observable<any> {
        const url = `${environment.OrderApiendpoint}/create-batch`;
        return this.httpService.post(url, { "visitId": data.id,"phleboId":phlebo }, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
 
    submitToLabCC(data: any,labcc:any=0): Observable<any> {
        const url = `${environment.OrderApiendpoint}/assign-lab-cc`;
        return this.httpService.post(url, { "visitId": data,"labccId":labcc }, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    //  for submit to sales user

    submitTosalesPerson(data: any,userId:any=0): Observable<any> {
        const url = `${environment.OrderApiendpoint}/update-sales-userid`;
        return this.httpService.post(url, { "visit_id": data,"sales_user_id":userId }, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
    submitToLabCCDos(data: any,labcc:any=0): Observable<any> {
        const url = `${environment.OrderApiendpoint}/assign-lab-cc-dos`;
        return this.httpService.post(url, { "dosId": data,"labccId":labcc }, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
   
    removeLabFromDosData(test_code:any,lab_code: any)
    {

        const url = `${environment.OrderApiendpoint}/remove-lab-from-dos-data/${test_code}/${lab_code}`;

        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );  
    }
    removeLabFromDos(data: any)
    {

        const url = `${environment.OrderApiendpoint}/remove-lab-from-dos/${data}`;

        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );  
    }
    getAssignLab(data: any)
    {

        const url = `${environment.OrderApiendpoint}/get-all-assign-lab-dos/${data}`;

        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );  
    }
    submitToOpsGroup(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/submit_ops_group`;
        return this.httpService.post(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    
    createInvoices(data: any): Observable<any> {
        let formData = {
            visit_ids:data
        }
        const url = `${environment.OrderApiendpoint}/genrate-invoice`;
        return this.httpService.post(url, formData, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
    getallAreaCode(data: any)
    {

        const url = `${environment.OrderApiendpoint}/getAreacodeByCity/${data}`;

        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );  
    }
    
    getAllSlotForOrder()
    {

        const url = `${environment.OrderApiendpoint}/get-customers-slot/1`;

        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );  
    }
    getAllSlot(data: any)
    {

        const url = `${environment.OrderApiendpoint}/customers-area-slot/1/${data}`;

        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );  
    }

 //order
 getOrdersForTableStat(data: any) {
    const url = `${environment.OrderApiendpoint}/orders?t=order-stat${data}`;
    return this.httpService.get(url, this.httpService.headers)
        .pipe(
            catchError(this.Errorhandling)
        );
}
    //order
    getOrdersForTable(data: any,flag:any) {
        const url = `${environment.OrderApiendpoint}/orders?t=${flag}${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    postOrder(data: any, screenName: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/orders?t=${screenName}`;
        return this.httpService.post(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
    getSingleOrderData(id: any) {
        const url = `${environment.OrderApiendpoint}/view-master-order/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getSingleOrderDataView(id: any, visitId: any) {
        const url = `${environment.OrderApiendpoint}/view-master-order-with-visit/${id}/${visitId}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    

    getSingleOrderPublicData(id: any) {
        const url = `${environment.OrderApiendpoint}/view-master-order-public/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    paymentqr(id: any) {
        const url = `${environment.OrderApiendpoint}/create-paymentqr/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    orderQuickStat() {
        const url = `${environment.OrderApiendpoint}/order-status-list`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }


    getSingleOrderData_by_visit_id(id: any,visit_id:any) {
        const url = `${environment.OrderApiendpoint}/view-master-order-visit/${id}/${visit_id}/`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    checkSlotExit(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/check-slot-exist`;
        return this.httpService.post(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    updateOrder(data: any, id: any,screenName: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/orders/${id}/?t=${screenName}`;
        return this.httpService.put(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
    updateOrderSrl(data: any, id: any,screenName: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/orders-srl/${id}/?t=${screenName}`;
        return this.httpService.put(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    orderDelete(id: any, screenName: any) {
        const url = `${environment.OrderApiendpoint}/orders/${id}?t=${screenName}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    order_patient_delete_srl(id: any) {
        const url = `${environment.OrderApiendpoint}/order-patient-delete-srl/${id}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    order_test_delete_srl(id: any) {
        const url = `${environment.OrderApiendpoint}/order-test-delete-srl/${id}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    orderDeleteLocas(id: any) {
        const url = `${environment.OrderApiendpoint}/delete-task/${id}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
   
    exportOrder(data: any) {
        const url = `${environment.OrderApiendpoint}/orders?t=orders_export${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    exportLabUser(data: any) {
        const url = `${environment.labUserEndpoint}/user-labs-export`;
        return this.httpService.post(url,data, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }

    uploadLabs(imagefile: File) {
        const url = `${environment.OrderApiendpoint}/labs-import`;
        const formData = new FormData();
        formData.append('file', imagefile);

        return this.httpService.post(url, formData, this.httpService.headers)
            .pipe(catchError(this.Errorhandling));
    }
    uploadCC(imagefile: File) {
        const url = `${environment.OrderApiendpoint}/cc-import`;
        const formData = new FormData();
        formData.append('file', imagefile);

        return this.httpService.post(url, formData, this.httpService.headers)
            .pipe(catchError(this.Errorhandling));
    }
    uploadOrders(imagefile: File) {
        const url = `${environment.OrderApiendpoint}/orders-import`;
        const formData = new FormData();
        formData.append('file', imagefile);

        return this.httpService.post(url, formData, this.httpService.headers)
            .pipe(catchError(this.Errorhandling));
    }
    createPatient(id: any) {
        const url = `${environment.OrderApiendpoint}/api/v1/order/${id}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    updatePatient(data: any, id: any) {
        const url = `${environment.OrderApiendpoint}/api/v1/order/${id}`;
        return this.httpService.delete(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    GetAllLabLocation(customer_id: any, cityId: any, provider_id: any) {
        console.log(customer_id, cityId);
        
        const url = `${environment.OrderApiendpoint}/get-lab-location/${customer_id}/${cityId}/${provider_id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getallTest_code(customer_id: any, cityId: any,labid: any) {
        const url = `${environment.OrderApiendpoint}/get-tests/${customer_id}/${cityId}/${labid}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getallTest_codeByProvider(customer_id: any,provider_id: any, cityId: any, labid: any) {
        const url = `${environment.OrderApiendpoint}/get-tests-provider/${customer_id}/${provider_id}/${cityId}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getallTest_codeByProviderOrder(customer_id: any,provider_id: any, labId: any) {
        const url = `${environment.OrderApiendpoint}/get-tests-provider/${customer_id}/${provider_id}/${labId}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
    getPatientForTable(id: any) {
        const url = `${environment.OrderApiendpoint}/get-patient-list/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    getOrderTimeline(visit_id: any) {
        const url = `${environment.OrderApiendpoint}/order-timeline/${visit_id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    CreateOrderViewPDF(visit_id: any) {
        const url = `${environment.OrderApiendpoint}/generate-order-view-pdf/${visit_id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    CreateOrderViewPDFPUBLIC(visit_id: any) {
        const url = `${environment.OrderApiendpoint}/generate-order-view-pdf-public/${visit_id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    downloadTrf(visit_id: any) {
        const url = `${environment.OrderApiendpoint}/generate-order-trf-pdf/${visit_id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    downloadReceipt(visit_id: any) {
        const url = `${environment.OrderApiendpoint}/get-Receipt/${visit_id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }


    getallSlot(data: any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/get-slot`;
        //console.log(data);
        return this.httpService.post(url,{"date":data.date,"customer_id":data.customer_id,"pincode":data.patient_pincode,"city_id":data.patient_city}, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    // getTestsForTable(id:any) {
    //     const url = `${environment.OrderApiendpoint}/get-patient-list/${id}`;
    //     return this.httpService.get(url, this.httpService.headers)
    //         .pipe(catchError(this.Errorhandling))
    // }
    // getTestsForTableByVisit(id:any, visitId:any) {
    //     const url = `${environment.OrderApiendpoint}/get-patient-list/${id}/${visitId}`;
    //     return this.httpService.get(url, this.httpService.headers)
    //         .pipe(catchError(this.Errorhandling))
    // }
    getPatientForTableByVisit(id: any, visitId: any) {
        const url = `${environment.OrderApiendpoint}/get-patient-list-by-order-visit/${id}/${visitId}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    getAvailableSlot(customer_id:any,pincode:any,date:any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/get-available-slot`;
        return this.httpService.post(url, { "customer_id":customer_id,"pincode":pincode,"date":date}, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    edit_patient_test(id:any, data:any): Observable<any> {
        const url = `${environment.OrderApiendpoint}/edit-patient-test/${id}`;
        return this.httpService.post(url, data, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

   
    getTestForTableByVisit(id:any, visitId:any) {
        const url = `${environment.OrderApiendpoint}/get-test-list-by-order-visit/${id}/${visitId}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    getPatientForDropdownVisit(id: any, visitId: any) {
        const url = `${environment.OrderApiendpoint}/get-patient-list-for-dropdown-visit/${id}/${visitId}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    getTotalAmount(id: any) {
        const url = `${environment.OrderApiendpoint}/get-total-amount/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    getTotalAmountNew(id: any) {
        const url = `${environment.OrderApiendpoint}/get-total-amount-new/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    getTotalAmountNewSrl(id: any) {
        const url = `${environment.OrderApiendpoint}/get-total-amount-new-srl/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
    getPatientForDropdown(id: any) {
        const url = `${environment.OrderApiendpoint}/get-patient-list-for-dropdown/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getPatientForTableByOrder(id: any) {
        const url = `${environment.OrderApiendpoint}/get-patient-list-by-order/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    
    getUpSellLog(id: any) {
        const url = `${environment.OrderApiendpoint}/get-upsell-log/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getCancelImage(id: any) {
        const url = `${environment.OrderApiendpoint}/get-cancel-image/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getPatientForTableByOrderPublic(id: any) {
        const url = `${environment.OrderApiendpoint}/get-test-list-by-order-visit-public/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getBarcodeAndDocument(id: any) {
        const url = `${environment.OrderApiendpoint}/visit-checklist/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getBarcodeAndDocumentPhleboApp(id: any) {
        const url = `${environment.OrderApiendpoint}/visit-all-document/${id}`;
        console.log(`url : ${url}`)
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getVisitBarcodeStatus(id: any) {
        const url = `${environment.OrderApiendpoint}/visit-barcode-status/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    getVisitStatusList(id: any) {
        const url = `${environment.OrderApiendpoint}/visit-status/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    cancelOrder(id: any) {
        const url = `${environment.OrderApiendpoint}/cancel-visit/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    rescheduleOrder(id: any) {
        const url = `${environment.OrderApiendpoint}/reshedule-visit/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }

    upSellOrder(id: any) {
        const url = `${environment.OrderApiendpoint}/upsell-visit/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
    // upSellOrder

    // https://mtqn0qowxc.execute-api.us-east-1.amazonaws.com/get-temprature/visit_id

    viewTemperature(id:any) {
        const url = `${environment.OrderApiendpoint}/get-temprature/${id}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }
   
    exportDistanceReports(data:any): Observable<any> {
        const url = `${environment.SettingsApiendpoint}/export-excel-distance?${data}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(catchError(this.Errorhandling))
    }


    
    obtainDistanceReports(search: any) {
     
        const url = `${environment.SettingsApiendpoint}/phlebo-distance?${search}`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    checkPincode() {
        const url = `${environment.OrderApiendpoint}/check-pincode`;
        return this.httpService.get(url, this.httpService.headers)
            .pipe(
                catchError(this.Errorhandling)
            );
    }
    Errorhandling(err: HttpErrorResponse) {
        if (err.error instanceof ErrorEvent) {
          
            console.error(err.error.message);
        } else {
          
            console.error(`Backend returned code ${err.status}`);
        }
        return throwError('Please try again later.');
    }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersService } from 'src/app/services/customer/customer.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user/user.service';
import { CustomerdosService } from "src/app/services/customerdos/customerdos.service";
import { SettingService } from 'src/app/services/setting/setting.service';
import { PhleboService } from 'src/app/services/phlebo/phlebo.service';
import { OrderService } from 'src/app/services/order/order.service';
import { Router } from "@angular/router";
import { AbstractControl, FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { InvoiceService } from 'src/app/services/invoice/invoice.service';
import { ClientService } from 'src/app/services/client/client.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { DesignationService } from 'src/app/services/designation/designation.service';
import { PlanService } from 'src/app/services/plan/plan.service';

@Component({
    selector: "app-deleteModal",
    templateUrl: "./deleteModal.component.html",
    styleUrls: ["./deleteModal.component.scss"]
})
export class deleteModalComponent implements OnInit {
    @Input() props: any;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    public title: string;
    public subTitle: string;

    public type: string;
    public data: any;
    public encryptedId: any;
    public categoryName: string;
    public status: boolean;
    public approved: any;
    public page: any = 1;
    public dosStatus: any;
    public dosId: any;
    public areaStatus: any;
    public areaId: any;
    public isBtnLoaded: boolean = false;
    public screenName: any;
    public phleboId: any;
    public phleboStatus: any;
    public visitId: any;
    isLoadedTable: boolean;
    slotlist: any;
    comment: any;

    phleboList: any;
    submittoopsForm: FormGroup;
    labccForm: FormGroup;
    salesForm: FormGroup;

    phlebotype: any = true;
    phleboDropDown: any;
    form: FormGroup;
    totalSlot: any;
    items: any;
    submitBtnLoader = false;
    ordertypeForm: FormGroup;
    availablePincode: any;
    checkPincode: boolean=true;
    labccDropDown: any;
    usersDropDown: any;
    addPermission: FormGroup;
    verifyPhlebo: FormGroup;
    labcclistForDropdown: any[];
    salesListForDropdown: any[];
    deleteLabUser:any;

    

    constructor(
        private ngbModal: NgbActiveModal,
        private toastr: ToastrService,
        private customerService: CustomersService,
        private userService: UserService,
        private customerdosService: CustomerdosService,
        private OrderService: OrderService,
        private settingService: SettingService,
        private phleboService: PhleboService,
        private router: Router,
        private formBuilder: FormBuilder,
        private invoiceService: InvoiceService,
        private clientService: ClientService,
        private employeeService:EmployeeService,
        private designationService:DesignationService,
        private planService:PlanService,

    ) { }

    ngOnInit(): void {
       
        this.submitBtnLoader = false;
        this.setDialogProps(this.props);
        if (this.type == 'verifyPhlebo') {
            this.verifyPhlebo = this.formBuilder.group({
                comment: [''],
            });
          }


          if (this.type == 'viewComment') {
            this.comment = this.data;
            console.log("comeent - ", this.comment)
          }


        if (this.type == 'submitedToOPS') {

            if(!this.checkPincode)
            {
                this.phlebotype="with_phlebo";
            }


          
              
            this.submittoopsForm = this.formBuilder.group({
                phlebo: ['', Validators.required],
                phlebotype: ['without_phlebo'],


            });
           
       
            this.OrderService.getAllPhleboList(this.data).subscribe(
                (res: any) => {
                    this.phleboDropDown = res.data;
                    console.log(this.phleboList);
                },
                (err: any) => {
                    this.onClose();
                    this.toastr.error(`${err.statusText}`);
                    console.log(err);
                },
                () => {
                    this.isLoadedTable = false;
                }
            );
           
        }
        if (this.type == 'addPermission') {
            this.addPermission = this.formBuilder.group({
                users: ['', Validators.required],
                lab_id: [this.data.lab_id],
                type: [this.data.type],

            });
       
            this.customerService.getAllUsersList(this.data.type).subscribe(
                (res: any) => {
                    this.usersDropDown = res.data;
                },
                (err: any) => {
                    this.onClose();
                    this.toastr.error(`${err.statusText}`);
                    console.log(err);
                },
                () => {
                    this.isLoadedTable = false;
                }
            );
           
        }
        
        if (this.type == 'add_ordertype') {
            this.ordertypeForm = this.formBuilder.group({
               // difficult_phlebotomy: [''],
                vip_order: [''],


            });

            this.OrderService.getOrderType(this.data.id).subscribe(
                (res: any) => {
                    this.ordertypeForm.patchValue(res.data);

                    //   this.phleboDropDown = res.data;
                    //  console.log(this.phleboList);
                },
                (err: any) => {
                    this.onClose();
                    this.toastr.error(`${err.statusText}`);
                    console.log(err);
                },
                () => {
                    this.isLoadedTable = false;
                }
            );
        }
        if (this.type == 'available_slot') {
            this.submitBtnLoader = false;

            this.form = this.formBuilder.group({
                customer_id: [''],
                id: [''],

                city_id: [''],
                state_id: [''],
                areacode_id: [''],
                slot: [''],
                special_slot: [''],

                items: this.formBuilder.array([])



            });
            this.customerService.get_customers_contract_slot(this.data.customer_id, this.data.areacode_id).subscribe((data) => {

                //  this.customerPinCodeLists = data.data.customer_contracted_pin_codes
                this.form.get('id').setValue(data.data.id),
                    this.form.get('customer_id').setValue(data.data.customer_id);
                this.form.get('city_id').setValue(data.data.city_id);
                this.form.get('state_id').setValue(data.data.state_id);
                this.form.get('areacode_id').setValue(data.data.areacode_id);
                this.form.get('slot').setValue(data.data.slot);
                this.form.get('special_slot').setValue(data.data.special_slot);



            })

            this.customerService.getAllAvailableslot(this.data.customer_id, this.data.areacode_id).subscribe(
                (res: any) => {
                    this.slotlist = res.data;
                    this.items = this.form.get('items') as FormArray;
                    this.items.clear();


                    console.log("----", res.data);
                    for (let index = 0; index < res.data.length; index++) {
                        if (res.data[index].slot != "") {
                            this.items.push(this.createItem(res.data[index].id, res.data[index].slot, res.data[index].available_slot));

                        }
                    }


                    this.totalSlot = res.data.length;
                },
                (err: any) => {
                    this.onClose();
                    this.toastr.error(`${err.statusText}`);
                    console.log(err);
                },
                () => {
                    this.isLoadedTable = false;
                }
            );
        }

      

        if (this.type == 'submitedToLabCC' || this.type == 'submitedToLabCCDos' ) {
            this.labccForm = this.formBuilder.group({
                lab_cc: ['', Validators.required],
    
    
            });
            console.log("padam--------");
            this.get_lab_cc_first(this.data.existingData);


            }

            // for sales person 

            if (this.type == 'selectSalesPerson' ) {
                this.salesForm = this.formBuilder.group({
                    salesPersonName: ['', Validators.required],
        
        
                });
                this.getSalesPersonList();
    
    
                }
            if(this.type == 'deleteLabUser'){
                    this.deleteLabUser = {
                        "user_id":"",
                        "lab_id":"",
                        "type":""
                }
            }
    }
    createItem(id: any, slot: any, number: any): FormGroup {
        return this.formBuilder.group({
            slot,
            id,
            number: [number]
        });
    }
    changelabccc(event:any) { 
        if (event && event.value) {
          //let cityarray:string[] = event.value.map((i:any)=> String(i.value))
           
          this.addPermission.get('users').setValue(event.value.id);
        }
  
      }

    setDialogProps(dialogdata: any) {
        this.title = dialogdata.title;
        this.subTitle = dialogdata.subTitle;
        this.type = dialogdata.type;
        this.data = dialogdata.data;
        this.page = dialogdata.page;
        this.dosId = dialogdata.dosId;
        this.areaId = dialogdata.areaId;
        this.areaStatus = dialogdata.areaStatus;
        this.dosStatus = dialogdata.dosStatus;
        this.screenName = dialogdata.screenName;
        this.checkPincode=dialogdata.checkpincode;

    }

    get f(): { [key: string]: AbstractControl } {
        return this.submittoopsForm.controls;
    }
    get g(): { [key: string]: AbstractControl } {
        return this.labccForm.controls;
    }
    get a(): { [key: string]: AbstractControl } {
        return this.salesForm.controls;
    }
    get h(): { [key: string]: AbstractControl } {
        return this.addPermission.controls;
    }
    changelabcc(event)
    {
        console.log("aa"+event.value.length);

            if (event && event.value && event.value.length > 0) {
              let runnerarray:string[] = event.value.map((i:any)=> String(i.value))
              console.log("aa111111111111"+runnerarray);

              this.labccForm.get('runner_id').setValue(runnerarray[0]);
            }
            else { 
                this.labccForm.get('runner_id').setValue("");
            }
       // this.getAllLocation()

    }


      get_lab_cc_first(data) {
       
          this.customerService.allLabsbyCity(data).subscribe((data) => {
    
            let thisData = [];
    
            data.data.forEach(item => {
                thisData.push(
                    { value: item.id, text: `${item.lab_name}- ${item.location_code}`}
                    )
                });
                this.labcclistForDropdown = thisData;
    
          });
        
      }
      
      
      getSalesPersonList() {
       
        this.customerService.getSalesPersonList().subscribe(
            (res: any) => {
                this.salesListForDropdown = res;
                console.log(this.phleboList);
            },
            (err: any) => {
                this.onClose();
                this.toastr.error(`${err.statusText}`);
                console.log(err);
            },
            () => {
                this.isLoadedTable = false;
            }
        );

      
      
    }

   
    submitTypeChange() {
        this.phlebotype = this.f.phlebotype.value
        if (this.phlebotype == 'without_phlebo') {
            this.submittoopsForm.value.phlebo = '';

        }
        console.log("padam", this.phlebotype);
    }

    onCloseNext() {
        this.passEntry.next(this.page);
        this.ngbModal.close();
    }
    addOrderType() {
        this.submitBtnLoader = true;
        this.OrderService.add_ordertype(this.ordertypeForm.value, this.data.id).subscribe(
            (res: any) => {
                if (res && res.success) {
                    this.toastr.success(`${res.message}`);
                    this.onClose();
                } else {
                    if (res.message) {
                        this.toastr.error(`${res.message}`);
                    } else {
                        this.toastr.error(`Status change failed`);
                    }
                }


                this.submitBtnLoader = false;

                this.isBtnLoaded = false;
            },
            (err: any) => {
                this.onClose();
                this.toastr.error(`${err.statusText}`);
                console.log(err);
            }
        );

    }
    onSubmit() {
        this.submitBtnLoader = true;
        this.customerService.submitToSlot(this.form.value).subscribe(
            (res: any) => {
                if (res && res.success) {
                    this.toastr.success(`${res.message}`);
                    this.onClose();
                } else {
                    if (res.message) {
                        this.toastr.error(`${res.message}`);
                    } else {
                        this.toastr.error(`Status change failed`);
                    }
                }


                this.submitBtnLoader = false;

                this.isBtnLoaded = false;
            },
            (err: any) => {
                this.onClose();
                this.toastr.error(`${err.statusText}`);
                console.log(err);
            }
        );

    }

    onDelete() {

        switch (this.type) {
            
            case 'addPermission':
                this.isBtnLoaded = true;
                this.customerService.addUserPermission( this.addPermission.value).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`Data saved successfully`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deleteLeave':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.OrderService.deleteLeave(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`Leave deleted successfully`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
                case 'deletephleboPlan':
                    this.isBtnLoaded = true;
                    this.planService.deletephleboPlan(this.data).subscribe(
                        (res: any) => {
                            if (res && res.status) {
                                this.toastr.success(`${res.message}`);
                                this.onClose();
                            } else {
                                if (res.message) {
                                    this.toastr.error(`${res.message}`);
                                } else {
                                    this.toastr.error(`Phlebo plan deleted successfully`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            
                        }
                    );
                    break;
            case 'deleteCityClient':
                this.isBtnLoaded = true;
                this.settingService.deleteCityClientMaster(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deleteCampData':
                this.isBtnLoaded = true;
                this.OrderService.deleteCamp(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deleteBankDeposit':
                this.isBtnLoaded = true;
                this.OrderService.deleteBankDeposit(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
                case 'submitedToLabCC':

                this.isBtnLoaded = true;
                console.log( this.data.visitId,"anmol");
                this.OrderService.submitToLabCC(this.data.visitId, this.labccForm.value.lab_cc).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
              
            //    for sales person 
            case 'selectSalesPerson':

                this.isBtnLoaded = true;
                console.log( this.data.visitId,"anmol");
                console.log(this.salesForm.value.salesPersonName, "sales id")
                this.OrderService.submitTosalesPerson(this.data.visitId, this.salesForm.value.salesPersonName).subscribe(
                    (res: any) => {
                        if (res && res.message) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                           
                                this.toastr.error(`Status change failed`);
                            
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;

                case 'submitedToLabCCDos':

                this.isBtnLoaded = true;
                console.log( this.labccForm);
                this.OrderService.submitToLabCCDos(this.data, this.labccForm.value.lab_cc).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
           
            case 'submitedToOPS':

                this.isBtnLoaded = true;
                this.OrderService.submitToOps(this.data, this.submittoopsForm.value.phlebo).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                                this.isBtnLoaded = false;

                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'submitedToOPSGroup':
                this.isBtnLoaded = true;
                this.OrderService.submitToOpsGroup(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;

            case 'createdInvoice':
                this.isBtnLoaded = true;
                this.OrderService.createInvoices(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;

            case 'submitedToOPSByView':
                this.isBtnLoaded = true;
                this.OrderService.submitToOps(this.data, this.submittoopsForm.value.phlebo).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'updateStatusDos':
                this.isBtnLoaded = true;
                this.customerdosService.updateStatus(this.dosId, this.dosStatus).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;

            case 'updateStatusPhlebo':
                this.isBtnLoaded = true;
                this.phleboService.updateStatus(this.data.id, this.data.status).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success("Phlebo updated successfully");
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;


            case 'updateStatusArea':
                this.isBtnLoaded = true;
                this.settingService.updateAreaStatus(this.areaId, this.areaStatus).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Status change failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deleteDos':
                this.isBtnLoaded = true;
                this.customerdosService.deleteDos(this.dosId).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Dos deleted successfully`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;     

                case 'deletePlan':
                    this.isBtnLoaded = true;
                    this.planService.deletePlanList(this.data).subscribe(
                        (res: any) => {
                            if (res && res.success) {
                                this.toastr.success(`${res.message}`);
                                this.onClose();
                            } else {
                                if (res.message) {
                                    this.toastr.error(`${res.message}`);
                                } else {
                                    this.toastr.error(`Plan deleted successfully`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        }
                    );
                    break;
                    case 'unbookedPlan':
                        this.isBtnLoaded = true;
                        this.planService.unbookedPlan(this.data).subscribe(
                            (res: any) => {
                                if (res.status) {
                                    this.toastr.success(`${res.message}`);
                                    this.onClose();
                                } else {
                                    if (res.message) {
                                        this.toastr.error(`${res.message}`);
                                    } else {
                                        this.toastr.error(`Visit Un-Assigned successfully`);
                                    }
                                }
                                this.isBtnLoaded = false;
                            },
                            (err: any) => {
                                this.onClose();
                                this.toastr.error(`${err.statusText}`);
                                console.log(err);
                            }
                        );
                        break;
            case 'deleteCustomer':
                this.isBtnLoaded = true;
                this.customerService.deleteLab(this.data.id, 'lab_location').subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;

            case 'deleteArea':
                this.isBtnLoaded = true;
                this.settingService.deleteAreaCode(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;

            case 'deleteCity':
                this.isBtnLoaded = true;
                this.settingService.deleteCity(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deleteLabUser':
                    this.isBtnLoaded = true;
                    console.log(this.data);
                    this.deleteLabUser.user_id=this.data.user_id;
                    this.deleteLabUser.lab_id=this.data.lab_id;
                    this.deleteLabUser.type=this.data.type;
                    this.settingService.deleteLabUser(this.deleteLabUser).subscribe(
                        (res: any) => {
                            if (res && res.success) {
                                this.toastr.success(`${res.message}`);
                                this.onClose();
                            } else {
                                if (res.message) {
                                    this.toastr.error(`${res.message}`);
                                } else {
                                    this.toastr.error(`Delete failed`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        }
                    );
                break;
            case 'deleteLab Location':
                this.isBtnLoaded = true;
                this.customerService.deleteCustomer(this.data.id, 'lab_location').subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deleteArea Code':
                this.isBtnLoaded = true;
                this.customerService.deleteCustomer(this.data.id, 'area_code').subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deletePincode':
                this.isBtnLoaded = true;
                this.customerService.deleteCustomer(this.data.id, 'pincode').subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deleteDOS':
                this.isBtnLoaded = true;
                this.customerService.deleteCustomer(this.data.id, 'dos').subscribe(
                    (res: any) => {
                        console.log(res);
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.success(`${res.message}`);
                                this.onClose();
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deletePrice':
                this.isBtnLoaded = true;
                this.customerService.deleteCustomer(this.data.id, 'price').subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deleteUser':
                this.isBtnLoaded = true;
                this.userService.deleteUser(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deactivateCustomer':
                this.isBtnLoaded = true;
                this.customerService.statusUpdateCustomer(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
                case 'deactivateLab':
                    this.isBtnLoaded = true;
                    this.customerService.statusUpdateLab(this.data).subscribe(
                        (res: any) => {
                            if (res && res.success) {
                                this.toastr.success(`${res.message}`);
                                this.onClose();
                            } else {
                                if (res.message) {
                                    this.toastr.error(`${res.message}`);
                                } else {
                                    this.toastr.error(`Update failed`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        }
                    );
                    break;
              
            case 'deleteMasterdos':
                this.isBtnLoaded = true;
                this.settingService.deleteMasterDos(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deletePincodeData':
                this.isBtnLoaded = true;
                this.settingService.deletePincode(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            case 'deletePhleboData':
                this.isBtnLoaded = true;
                this.phleboService.deletePhlebo(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success("Phlebo deleted successfully");
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
            
            case 'orderDeleteLocas':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.OrderService.orderDeleteLocas(this.data.id).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;
            case 'order_test_delete_srl':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.OrderService.order_test_delete_srl(this.data.id).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;
            case 'order_patient_delete_srl':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.OrderService.order_patient_delete_srl(this.data.id).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;

            case 'orderDelete':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.OrderService.orderDelete(this.data.id, this.screenName).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;
            case 'deleteblockdata':
                this.isBtnLoaded = true;
                console.log("padam1234", this.data);
                this.settingService.customerblockDelete(this.data).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Delete failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;
            case 'cancelOrder':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.OrderService.cancelOrder(this.data.id).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.router.navigateByUrl('orders');

                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;
            case 'rescheduleOrder':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.OrderService.rescheduleOrder(this.data.id).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            console.log(res);
                            this.router.navigateByUrl('orders/edit/' + res.data.order_id + '/' + res.data.visit_id);

                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {

                            if (res.message) {

                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;

            // case 'upSellOrder':
            //     this.isBtnLoaded = true;
            //     console.log(this.data);
            //     this.OrderService.upSellOrder(this.data.id).subscribe(

            //         (res: any) => {
            //             if (res && res.success) {
            //                 console.log(res);
            //                 this.router.navigateByUrl('upsell-edit/'+res.data.order_id+'/'+res.data.visit_id);

            //                 this.toastr.success(`${res.message}`);
            //                 this.onClose();
            //             } else {

            //                 if (res.message) {

            //                     this.toastr.error(`${res.message}`);
            //                 } else {
            //                     this.toastr.error(`Update failed`);
            //                 }
            //             }
            //             this.isBtnLoaded = false;
            //         },
            //         (err: any) => {
            //             this.onClose();
            //             this.toastr.error(`${err.statusText}`);
            //             console.log(err);
            //         },()=>{
            //             this.onClose();

            //         }
            //     );
            //     break;


            case 'deleteInvoice':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.invoiceService.deleteInvoice(this.data.id).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;
            case 'deletePartner':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.settingService.deletePartner(this.data).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;

            case 'publishInvoice':
                this.isBtnLoaded = true;
                console.log(this.data);
                this.invoiceService.publishInvoice(this.data.id).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;
            case 'deleteOrgData':
                this.isBtnLoaded = true;
                this.OrderService.deleteOrgData(this.data).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;
            case 'deleteDoctorData':
                this.isBtnLoaded = true;
                this.OrderService.deleteDoctorData(this.data).subscribe(

                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success(`${res.message}`);
                            this.onClose();
                        } else {
                            if (res.message) {
                                this.toastr.error(`${res.message}`);
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }, () => {
                        this.onClose();

                    }
                );
                break;

               

            case 'deleteClientData':
                this.isBtnLoaded = true;
                this.clientService.deleteClient(this.data).subscribe(
                    (res: any) => {
                        if (res && res.success) {
                            this.toastr.success("Client deleted successfully");
                            this.onClose();
                        } else {
                            if (res.error) {
                                this.toastr.error(`${res.error}`);
                                this.onClose();
                            } else {
                                this.toastr.error(`Update failed`);
                            }
                        }
                        this.isBtnLoaded = false;
                    },
                    (err: any) => {
                        this.onClose();
                        this.toastr.error(`${err.statusText}`);
                        console.log(err);
                    }
                );
                break;
                case 'deleteEmployeeData':
                    this.isBtnLoaded = true;
                    this.employeeService.deleteEmployee(this.data).subscribe(
                        (res: any) => {
                            if (res && res.success) {
                                this.toastr.success("Employee deleted successfully");
                                this.onClose();
                            } else {
                                if (res.message) {
                                    this.toastr.error(`${res.message}`);
                                } else {
                                    this.toastr.error(`Update failed`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        }
                    );
                    break;
                    case 'deleteDesignation':
                    this.isBtnLoaded = true;
                    this.designationService.deleteDesignation(this.data).subscribe(
                        (res: any) => {
                            if (res && res.success) {
                                this.toastr.success("Designation deleted successfully");
                                this.onClose();
                            } else {
                                if (res.error) {
                                    this.toastr.error(`${res.error}`);
                                    this.onClose();
                                } else {
                                    this.toastr.error(`Update failed`);
                                }
                            }
                            this.isBtnLoaded = false;
                        },
                        (err: any) => {
                            this.onClose();
                            this.toastr.error(`${err.statusText}`);
                            console.log(err);
                        }
                    );
                    break;

        }
    }

    onClose() {
        this.passEntry.next(this.page);
        this.ngbModal.close();
    }
   
  verifyPhleboStatus(status: number) {
    this.isBtnLoaded = true;

    if (status === 2 && !this.verifyPhlebo.get('comment').value) {
      // Comment is mandatory for Unapprove
      this.verifyPhlebo.get('comment').setValidators([Validators.required]);
      this.verifyPhlebo.get('comment').updateValueAndValidity();
      this.verifyPhlebo.get('comment').markAsTouched(); // Ensure the field is touched for validation message

      if (this.verifyPhlebo.invalid) {
        this.toastr.error('Comment is required for unapproval.');
        this.isBtnLoaded = false;
        return;
      }
    } else {
      this.verifyPhlebo.get('comment').clearValidators();
      this.verifyPhlebo.get('comment').updateValueAndValidity();
    }

    const payload = {
    
     
      status: status,
      comment: this.verifyPhlebo.get('comment').value
    };

    this.phleboService.verifyPhlebo(this.data.id,this.data.type,payload).subscribe(
      (res: any) => {
        if (res && res.success) {
          this.toastr.success(`${res.message}`);
          this.onClose();
        } else {
          if (res.message) {
            this.toastr.error(`${res.message}`);
          } else {
            this.toastr.error('Update failed');
          }
        }
        this.isBtnLoaded = false;
      },
      (err: any) => {
        this.onClose();
        this.toastr.error(`${err.statusText}`);
        console.log(err);
      }
    );
  }
}
